import type { FileSize } from '../features';

export const RAIFFEISEN_CLIENT_ID = 'partners-api';

export const RECOIL_KEYS = {
  USER_PROFILE_STORE: 'USER_PROFILE_STORE',
  USER_PROFILE_QUERY: 'USER_PROFILE_QUERY',
  USER_BONUS_TYPE_QUERY: 'USER_BONUS_TYPE_QUERY',
  EMPLOYEES_QUERY: 'EMPLOYEES_QUERY',
  EMPLOYEE_PROFILE_QUERY: 'EMPLOYEE_PROFILE_QUERY',
  APPLICATION_ID_STORE: 'APPLICATION_ID_STORE',
  APPLICATION_FILES_QUERY: 'APPLICATION_FILES_QUERY',
  APPLICATION_FILES_STORE: 'APPLICATION_FILES_STORE',
  APPLICATION_STAGE_STORE: 'APPLICATION_STAGE_STORE',
  APPLICATION_PERSONAL_DATA_STORE: 'APPLICATION_PERSONAL_DATA_STORE',
  COUNTRIES_LIST_QUERY: 'COUNTRIES_LIST_QUERY',
  REGIONS_LIST_QUERY: 'REGIONS_LIST_QUERY',
  CONTACTS_FORM_QUERY: 'CONTACTS_FORM_QUERY',
  APPLICATION_OCR_STORE: 'APPLICATION_OCR_STORE',
  APPLICATION_OCR_STORE__DEFAULT: 'APPLICATION_OCR_STORE__DEFAULT',
  APPLICATION_OCR_DATA_STORE: 'APPLICATION_OCR_DATA_STORE',
  APPLICATIONS_SELECTED_TAB_STORE: 'APPLICATIONS_SELECTED_TAB_STORE',
  APPLICATIONS_SEARCH_STORE: 'APPLICATIONS_SEARCH_STORE',
  APPLICATION_INFO_QUERY: 'APPLICATION_INFO_QUERY',
  APPLICATION_SUBJECT_HISTORY_STORE: 'APPLICATION_SUBJECT_HISTORY_STORE',
  APPLICATION_CURRENT_SUBJECT_ID_STORE: 'APPLICATION_CURRENT_SUBJECT_ID_STORE',
  SUBJECT_PASSPORT_TYPE_QUERY: 'SUBJECT_PASSPORT_TYPE_QUERY',
  APPLICATIONS_DRAFT_QUERY: 'APPLICATIONS_DRAFT_QUERY',
  APPLICATIONS_TO_REASSIGN_STORE: 'APPLICATIONS_TO_REASSIGN_STORE',
  APPLICATIONS_PAGE_STORE: 'APPLICATIONS_PAGE_STORE',
  APPLICATION_NEED_REFINEMENT_STORE: 'APPLICATION_NEED_REFINEMENT_STORE',
  APPLICATION_NEED_REFINEMENT_STORE__DEFAULT: 'APPLICATION_NEED_REFINEMENT_STORE__DEFAULT',
  APPLICATION_SORT_STAGE_STORE: 'APPLICATION_SORT_STAGE_STORE',
  SUBJECT_FILES_QUERY: 'SUBJECT_FILES_QUERY',
  START_REASSIGN_APPLICATION_STORE: 'START_REASSIGN_APPLICATION_STORE',
  SELECTED_EMPLOYEE_ID_PROFILE_STORE: 'SELECTED_EMPLOYEE_ID_PROFILE_STORE',
  ACTIVE_EMPLOYEES_QUERY: 'ACTIVE_EMPLOYEES_QUERY',
  SUCCESS_REASSIGN_CALLBACK_STORE: 'SUCCESS_REASSIGN_CALLBACK_STORE',
  IS_PROFILE_DELETED_STORE: 'IS_PROFILE_DELETED_STORE',
  IS_APPLICATION_VALID_QUERY: 'IS_APPLICATION_VALID_QUERY',
  APPLICATION_EMPLOYEE_BOOK_TYPE_STORE: 'APPLICATION_EMPLOYEE_BOOK_TYPE_STORE',
  APPLICATION_INCOME_CERTIFICATE_TYPE_STORE: 'APPLICATION_INCOME_CERTIFICATE_TYPE_STORE',
  ALLOWED_APPLICATION_LIST_ACTIONS_QUERY: 'ALLOWED_APPLICATION_LIST_ACTIONS_QUERY',
  APPLICATIONS_PAGE_STORE__DEFAULT: 'APPLICATIONS_PAGE_STORE__DEFAULT',
} as const;

export const APPLICATION_DOCUMENT_TYPES = {
  RU_PASSPORT: 'RU_PASSPORT',
  F_PASSPORT: 'F_PASSPORT',
  EMPLOYMENT_RECORD_BOOK: 'EMPLOYMENT_RECORD_BOOK',
  INCOME_CERTIFICATE: 'INCOME_CERTIFICATE',
  APPLICATION_FORM: 'APPLICATION_FORM',
  OTHER: 'OTHER',
} as const;

export const SUBJECT_TYPES = {
  BORROWER: 'BORROWER',
  COBORROWER: 'COBORROWER',
} as const;

export const APPLICATION_IDENTIFY_DOCUMENT_TYPES = {
  RUPASSPORT: 'RUPASSPORT',
  FPASSPORT: 'FPASSPORT',
} as const;

export const APPLICATION_STAGES = {
  CHOSE_TYPE: 'CHOSE_TYPE',
  PERSONAL_DATA: 'PERSONAL_DATA',
  DOCUMENTS: 'DOCUMENTS',
  CHOOSE_SUBJECT: 'CHOOSE_SUBJECT',
  SEND_TO_BANK: 'SEND_TO_BANK',
} as const;

export const RAIFFEISEN_REALTY_ADMIN_ROLES = {
  Administrator1: 'Administrator1',
  Administrator2: 'Administrator2',
} as const;

export const RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE = RAIFFEISEN_REALTY_ADMIN_ROLES.Administrator2;

export const RAIFFEISEN_REALTY_USER_ROLES = {
  Employee: 'Employee',
} as const;

export const RAIFFEISEN_REALTY_ALL_ROLES = {
  ...RAIFFEISEN_REALTY_ADMIN_ROLES,
  ...RAIFFEISEN_REALTY_USER_ROLES,
} as const;

export const USER_QUERY_OPTIONS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ON_CONFIRM: 'ON_CONFIRM',
} as const;

export const APPLICATIONS_LIST_TABS = {
  ALL: 'Все заявки',
  NEED_REFINEMENT: 'Нужна доработка',
  DRAFTS: 'Черновики',
} as const;

export const OCR_STATUSES = {
  NOT_STARTED: 'NOT_STARTED',
  NOT_NEEDED: 'NOT_NEEDED',
  NOT_INFORMATION: 'NOT_INFORMATION',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_RESET_PROCESS: 'IN_RESET_PROCESS',
  COMPLETED: 'COMPLETED',
  COMPLETED_WITH_WARNINGS: 'COMPLETED_WITH_WARNINGS',
  COMPLETED_WITH_ERRORS: 'COMPLETED_WITH_ERRORS',
} as const;

export const DOCUMENT_TYPE = {
  PAPER: 'PAPER',
  DIGITAL: 'DIGITAL',
} as const;

export const APPLICATION_MAX_COBORROWERS_COUNT = 3;

export const DADATA_ACCEPT_FIAS_LEVELS = ['7', '8', '9', '65'] as const;

export const ALLOWED_EXTENSIONS = [
  '.doc',
  '.docx',
  '.jpe',
  '.jpeg',
  '.jpg',
  '.pdf',
  '.png',
  '.rar',
  '.rtf',
  '.tif',
  '.tiff',
  '.xls',
  '.xlsx',
  '.zip',
] as const;

export const ALLOWED_EXTENSIONS_OCR = ['.png', '.pdf', '.jpeg', '.jpg', '.jpe', '.tiff', '.tif'] as const;

export const ALLOWED_FILESIZE: FileSize = [20, 'МБ'];

export const ALLOWED_FILENAME_LENGTH = { MIN: 0, MAX: 63 } as const;

export const MODAL_NODE = document.querySelector('#modal') as Element;

export const ALLOWED_ACTIONS_BY_ROLES = {
  [RAIFFEISEN_REALTY_ALL_ROLES.Administrator1]: {
    [RAIFFEISEN_REALTY_ALL_ROLES.Administrator1]: {
      reassign: false,
      edit: false,
      delete: false,
    },
    [RAIFFEISEN_REALTY_ALL_ROLES.Administrator2]: {
      reassign: false,
      edit: true,
      delete: true,
    },
    [RAIFFEISEN_REALTY_ALL_ROLES.Employee]: {
      reassign: false,
      edit: true,
      delete: true,
    },
  },

  [RAIFFEISEN_REALTY_ALL_ROLES.Administrator2]: {
    [RAIFFEISEN_REALTY_ALL_ROLES.Administrator1]: {
      reassign: false,
      edit: false,
      delete: false,
    },
    [RAIFFEISEN_REALTY_ALL_ROLES.Administrator2]: {
      reassign: true,
      edit: false,
      delete: false,
    },
    [RAIFFEISEN_REALTY_ALL_ROLES.Employee]: {
      reassign: true,
      edit: true,
      delete: true,
    },
  },

  [RAIFFEISEN_REALTY_ALL_ROLES.Employee]: {
    [RAIFFEISEN_REALTY_ALL_ROLES.Administrator1]: {
      reassign: false,
      edit: false,
      delete: false,
    },
    [RAIFFEISEN_REALTY_ALL_ROLES.Administrator2]: {
      reassign: false,
      edit: false,
      delete: false,
    },
    [RAIFFEISEN_REALTY_ALL_ROLES.Employee]: {
      reassign: false,
      edit: false,
      delete: false,
    },
  },
} as const;

export const ALLOWED_ACTIONS_FOR_APPLICATIONS_LIST = {
  [RAIFFEISEN_REALTY_ALL_ROLES.Administrator1]: {
    reassign: false,
  },
  [RAIFFEISEN_REALTY_ALL_ROLES.Administrator2]: {
    reassign: true,
  },
  [RAIFFEISEN_REALTY_ALL_ROLES.Employee]: {
    reassign: false,
  },
} as const;

if (!MODAL_NODE) {
  throw new Error('Modals container not found. Place, define it in index.html');
}
