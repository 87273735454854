import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useResetApplication } from '../entities/CreateApplicationEntity/controllers';
import { applicationStageState } from '../entities/CreateApplicationEntity/states';
import { APPLICATION_STAGES } from '../shared/const';
import { ApplicationDocumentsWidget } from '../widgets/ApplicationDocumentsWidget';
import { ApplicationFormWidget } from '../widgets/ApplicationFormWidget';
import { ChooseSubjectWidget } from '../widgets/ChooseSubjectWidget';
import { ChooseTypeWidget } from '../widgets/ChooseTypeWidget';
import { SendToBankWidget } from '../widgets/SendToBankWidget';

export const Application: React.FC = () => {
  const [currentStage, setCurrentStage] = useRecoilState(applicationStageState);
  const resetApplication = useResetApplication();

  useEffect(() => resetApplication, []);

  console.log(currentStage);

  if (currentStage === APPLICATION_STAGES.CHOSE_TYPE) {
    return <ChooseTypeWidget />;
  }

  if (currentStage === APPLICATION_STAGES.PERSONAL_DATA) {
    return <ApplicationFormWidget />;
  }

  if (currentStage === APPLICATION_STAGES.DOCUMENTS) {
    return <ApplicationDocumentsWidget />;
  }

  if (currentStage === APPLICATION_STAGES.CHOOSE_SUBJECT) {
    return <ChooseSubjectWidget />;
  }

  if (currentStage === APPLICATION_STAGES.SEND_TO_BANK) {
    return <SendToBankWidget />;
  }

  return null;
};
