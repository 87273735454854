import { selectorFamily } from 'recoil';
import { ALLOWED_ACTIONS_FOR_APPLICATIONS_LIST, RECOIL_KEYS } from '../../shared/const';
import { EmployeeInfoResponse } from '../../shared/types';
import {
  fetcher,
  formatEmail,
  formatPhoneNumber,
  prettyFullName,
  prettyFullNameWithInitials,
  prettyUserAccess,
  prettyUserStatus,
} from '../../shared/utils';
import { userProfileQuery } from '../ProfileEntity/queries';

export const employeeProfileKeys = {
  FullName: 'ФИО',
  Phone: 'Телефон',
  Email: 'Электронная почта',
  AccessLevel: 'Уровень доступа',
} as const;

export interface EmployeeRows extends Pick<EmployeeInfoResponse, 'id'> {
  FullName: string;
  Phone: string;
  Email: string;
  AccessLevel: ReturnType<typeof prettyUserAccess>;
}

export const employeeInfoQuery = selectorFamily({
  key: RECOIL_KEYS.EMPLOYEE_PROFILE_QUERY,
  get: (employeeID: string) => async () => {
    const response = await fetcher<EmployeeInfoResponse>(`/api/ml-partner-users/${employeeID}`);

    const lastNameWithInitials = prettyFullNameWithInitials(response);
    const state = prettyUserStatus(response.state);

    const employee: EmployeeRows[] = [
      {
        id: response.id,
        FullName: prettyFullName(response),
        Phone: formatPhoneNumber(response.mobilePhone),
        Email: formatEmail(response.email),
        AccessLevel: prettyUserAccess(response.role),
      },
    ];

    const lastUpdate = Date.now();

    console.log(response);

    return { lastNameWithInitials, rawInfo: response, state, employee, lastUpdate };
  },
});

/*

employeeID?: string) =>
  useRecoilCallback(
    ({ snapshot, refresh }) =>
      async () => {
        const { whatICanDoWith } = await snapshot.getPromise(userProfileQuery);
        if (employeeID) {
          refresh(employeeInfoQuery(employeeID));
          const { rawInfo } = await snapshot.getPromise(employeeInfoQuery(employeeID));

          return whatICanDoWith(rawInfo.role);
        }

        return ALLOWED_ACTIONS_FOR_APPLICATIONS_LIST;
      },
    [employeeID],
  );

*/

export const allowedApplicationListActionsQuery = selectorFamily({
  key: RECOIL_KEYS.ALLOWED_APPLICATION_LIST_ACTIONS_QUERY,
  get:
    (employeeID: string) =>
      ({ get }) => {
        const { whatICanDoWith, accountRole } = get(userProfileQuery);

        if (employeeID) {
          const { rawInfo } = get(employeeInfoQuery(employeeID));

          return whatICanDoWith(rawInfo.role);
        }

        return ALLOWED_ACTIONS_FOR_APPLICATIONS_LIST[accountRole];
      },
});
