import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { applicationKeys } from '../entities/ApplicationsEntity/queries';
import { employeeKeys, getEmployeesQuery } from '../entities/EmployeeEntity/queries';
import NotFoundApplicationsBG from '../shared/images/NotFoundApplications-BG.svg';

const ColoredStatusBadge = ({ status }: { status: string }) => {
  if (status === 'Активен') {
    return <span className="*badge-m bg-miami-30">{status}</span>;
  } else if (status === 'На подтверждении') {
    return <span className="*badge-m bg-dubai-30">{status}</span>;
  }

  return <span className="*badge-m bg-seattle-10">{status}</span>;
};

export const EmployeesTableWidget: React.FC = () => {
  // const [tableRowWidth, setTableRowWidth] = useState<string[]>(['413px', '333px', '264px', '262px', '197px']);
  const [tableRowWidth, setTableRowWidth] = useState<string[]>([
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'minmax(250px, 1fr)',
    'minmax(300px, 1fr)',
    'minmax(210px, 1fr)',
    'minmax(200px, 1fr)',
  ]);

  const tableRowTemplateColumns = useMemo(() => tableRowWidth.join(' '), [tableRowWidth]);

  const { employeeRows } = useRecoilValue(getEmployeesQuery);
  const navigate = useNavigate();

  if (employeeRows.length > 0) {
    return (
      <div className="overflow-auto">
        <div
          style={{ gridTemplateColumns: tableRowTemplateColumns }}
          className="grid text-s text-seattle-60 items-center"
        >
          {Object.entries(employeeKeys).map(([key, value]) => (
            <div className="flex items-center p-2 h-10 border-seattle-10 border-b-1" key={key}>
              {value}
            </div>
          ))}
        </div>
        {employeeRows.map(({ id, AccessLevel, Email, Status, Employee }) => (
          <div
            data-cypress="employee-table-row"
            style={{ gridTemplateColumns: tableRowTemplateColumns }}
            className="grid children:(h-20 w-full border-seattle-10 border-b-1 cursor-pointer hover:bg-seattle-10)
"
            onClick={() => navigate(`/employees/profile/${id}`)}
            key={id}
          >
            <div className="flex flex-col justify-center p-2">
              <span className="font-500">{Employee.fullName}</span>
              <span className="text-seattle-100">{Employee.phone}</span>
            </div>
            <div className="flex p-2 items-center">{Email}</div>
            <div className="flex p-2 items-center">{AccessLevel}</div>
            <div className="flex p-2 items-center">
              <ColoredStatusBadge status={Status} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full mt-6 mb-10 items-center justify-center">
      <div
        style={{ background: `url(${NotFoundApplicationsBG}) no-repeat 70px top` }}
        className="text-xxl font-700 pt-[91px]"
      >
        Пользователи не найдены
      </div>
      <span className="*typo-sm text-seattle-100 mt-2">Попробуйте изменить критерии поиска</span>
    </div>
  );
};
