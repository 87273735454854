import { forwardRef } from 'react';
import cx from 'clsx';

interface TextAreaProps {
  prefixElement?: React.ReactNode;
  postfixElement?: React.ReactNode;
  labelText?: string;
  errorText?: string;
  containerClasses?: string;
}

export type TextAreaPropsWithAttributes = TextAreaProps & React.InputHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaPropsWithAttributes>(
  ({ prefixElement, postfixElement, errorText, labelText, containerClasses, disabled, ...attributes }, reference) => (
    <label>
      {labelText && (
        <div className="input-label">
          <span className="input-label-text">{labelText}</span>
        </div>
      )}
      <div
        className={cx('input-container', errorText && 'border-moscow', disabled && 'input-disabled', containerClasses)}
      >
        {prefixElement && <span className="input-prefixElement">{prefixElement}</span>}
        <textarea ref={reference} className="input" disabled={disabled} {...attributes} />
        {postfixElement && <span className="input-suffixElement">{postfixElement}</span>}
      </div>
      {errorText && (
        <div className="input-error">
          <span className="input-error-text">{errorText}</span>
        </div>
      )}
    </label>
  ),
);
