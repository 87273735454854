import { useEffect, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { DocumentsForm } from '..';
import {
  useDeleteSubjectDocument,
  useUploadSubjectDocuments,
} from '../../../entities/CreateApplicationEntity/controllers';
import { subjectPassportTypeQuery } from '../../../entities/CreateApplicationEntity/queries';
import { FileLoader } from '../../../features';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_FILENAME_LENGTH,
  ALLOWED_FILESIZE,
  APPLICATION_DOCUMENT_TYPES,
  APPLICATION_IDENTIFY_DOCUMENT_TYPES,
} from '../../../shared/const';
import { ApplicationFileState } from '../../../shared/types';
import { handleFileUploadError } from '../../../shared/utils';

interface PassportProps {
  files: ApplicationFileState[];
}

// FIXME: Fuck :(
const pickFilesByType = (files: ApplicationFileState[], documentType: keyof typeof APPLICATION_DOCUMENT_TYPES) =>
  files
    .filter(file => file.documentType === documentType)
    .map(file => ({
      id: file.id,
      name: file.fileName,
      lastModified: new Date(file.uploadTime).getTime(),
      size: file.size,
    }));

export const Passport: React.FC<PassportProps> = ({ files }) => {
  const uploadDocument = useUploadSubjectDocuments();
  const deleteDocument = useDeleteSubjectDocument();
  const subjectPassportType = useRecoilValue(subjectPassportTypeQuery);

  const documentTypeByPassportType = useMemo(() => {
    if (APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT) {
      return APPLICATION_DOCUMENT_TYPES.RU_PASSPORT;
    }
    if (APPLICATION_IDENTIFY_DOCUMENT_TYPES.FPASSPORT) {
      return APPLICATION_DOCUMENT_TYPES.F_PASSPORT;
    }

    throw new Error('Unknown passport type');
  }, [subjectPassportType]);

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<DocumentsForm>();

  const filesByType = useMemo(
    () => pickFilesByType(files, documentTypeByPassportType),
    [files, documentTypeByPassportType],
  );

  useEffect(() => {
    setValue(
      'PASSPORT',
      filesByType.map(file => file.id),
    );
    clearErrors('PASSPORT');
  }, [files]);

  const error = errors?.PASSPORT as unknown as FieldError;

  return (
    <div className="space-y-4">
      <span className="text-xxl font-700">Паспорт</span>
      <FileLoader
        files={filesByType}
        allowedExtensions={ALLOWED_EXTENSIONS}
        allowedFileNameLength={ALLOWED_FILENAME_LENGTH}
        maxFileSize={ALLOWED_FILESIZE}
        onFileLoaded={file => uploadDocument(file, documentTypeByPassportType)}
        onFileRejected={handleFileUploadError}
        onFileDelete={async file => deleteDocument(file.id)}
        errorMessage={error?.message}
      />
    </div>
  );
};
