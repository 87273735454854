import Keycloak from 'keycloak-js';
/*
 * Файл лежащий тут локально (keycloak.json) в продакшн сборку НЕ ПОПАДАЕТ!! Он магическим образом пишется там прямо по факту окончания деплоя пайпы
 */
export const keycloak = Keycloak(`${window.location.origin}/keycloak.json`);

/*
{
    "exp": 1653035461,
    "iat": 1653035161,
    "auth_time": 1653034704,
    "jti": "1fc13be5-0916-4b5a-a5fd-4d8f353ebe82",
    "iss": "https://ml-keycloak.ml.lb.k8s-dt-ml-02.k8s.raiffeisen.ru/realms/origin",
    "aud": [
        "realm-management",
        "partners-api",
        "account"
    ],
    "sub": "fdf1723a-324d-4f9d-b3e0-ba1b3844603b",
    "typ": "Bearer",
    "azp": "partners",
    "nonce": "36f9eb69-1599-441f-b417-87d0f9ee18b4",
    "session_state": "e9a1f3b4-dee6-4015-b525-5b2618525ddc",

    "preferred_username": "ua1",
    "email": "mail@mail.ru",
    "email_verified": false,
    "acr": "0",
    "allowed-origins": [
        "*"
    ],
    "realm_access": {
        "roles": [
            "offline_access",
            "uma_authorization"
        ]
    },
    "resource_access": {
        "realm-management": {
            "roles": [
                "view-users",
                "query-groups",
                "query-users"
            ]
        },
        "partners-api": {
            "roles": [
                "Administrator1"
            ]
        },
        "account": {
            "roles": [
                "manage-account",
                "view-profile"
            ]
        }
    },
    "scope": "openid email phone profile",
    "sid": "e9a1f3b4-dee6-4015-b525-5b2618525ddc",
    "phone": "+79778212030",
    "groups": [
        "/RDM_401204504/RDM_3097884282"
    ],
    "attributes": {
        "partner_bonus_types": [
            {
                "code": "COMMISSION",
                "name": "Комиссионное вознаграждение"
            },
            {
                "code": "PREFERENCE",
                "name": "Преференция по ставке"
            }
        ],
        "holding_rdm_id": "RDM_401204504",
        "partner_rdm_id": "RDM_3097884282"
    },
    "client_id": "partners",
    "username": "ua1",
    "active": true
}
*/

// FAKE PART -------------------------------------

const fakeKeyCloak = () => {
  const token = 'some non null string';
  const userProfile = {
    username: 'test',
    email: 'test@testdomain.com',
    name: 'Иван Пузиков',
    given_name: 'Иван',
    family_name: 'Пузиков',
    middle_name: 'Семенович',
    phone: '+79998887766',
    attributes: {
      partner_bonus_types: [
        {
          code: 'COMMISSION',
          name: 'Комиссионное вознаграждение',
        },
        {
          code: 'PREFERENCE',
          name: 'Преференция по ставке',
        },
      ],
      holding_rdm_id: 'RDM_401204504',
      partner_rdm_id: 'RDM_3097884282',
    },
  };

  const realmAccess = { roles: ['admin', 'auditor', 'user'] };
  let authenticated = true;
  const authServerUrl = 'authServerUrl';
  const authClient = {
    authenticated,
    hasRealmRole() {
      return true;
    },
    //* FALSE ЧТОБЫ ПЕРЕСТАТЬ БЫТЬ АДМИНОМ
    hasResourceRole() {
      return true;
    },
    idToken: token,
    initialized: true,
    loadUserProfile() {
      return Promise.resolve({ userProfile });
    },
    login() {
      authenticated = true;
    },
    logout() {
      authenticated = false;
    },
    init() {
      return new Promise<void>(resolve => setTimeout(resolve, 800));
    },
    profile: userProfile,
    realm: 'DemoRealm',
    authServerUrl,
    realmAccess,
    refreshToken: token,
    token,
    tokenParsed: userProfile,
    createAccountUrl() {
      return 'some-admin-url';
    },
  };

  return { initialized: true, keycloak: authClient };
};

export const fakeKeyCloakInstants = fakeKeyCloak().keycloak as unknown as Keycloak.KeycloakInstance;
