import { Component, ErrorInfo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorHandler extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.debug(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="pt-6 px-12 flex flex-col h-screen w-full">
          <div className="flex flex-col h-full mt-6 mb-10 bg-white items-center justify-center shadow rounded">
            <span className="*h1 text-seattle-100 mt-2">Все сломалось :(</span>
            <a
              href="/applications"
              onClick={() => this.setState({ hasError: false })}
              className="text-xxl hover:text-primary text-miami-100 mt-2"
            >
              На главную?
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
