import cx from 'clsx';

interface TooltipProps {
  children: React.ReactNode;
  containerClasses?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, containerClasses }) => (
  <div className={cx('absolute left-0 top-0', containerClasses)}>
    <div className="top-[50%] left-0 mt-[-5px] border-[5px 5px 5px 0]" />
    <div className="">{children}</div>
  </div>
);
