import { Navigate, Route, Routes } from 'react-router-dom';
import { Application } from '../pages/Application';
import { ApplicationInfo } from '../pages/ApplicationInfo';
import { ApplicationList } from '../pages/ApplicationList';
import { EditEmployee } from '../pages/EditEmployee';
import { EmployeeList } from '../pages/EmployeeList';
import { EmployeeProfile } from '../pages/EmployeeProfile';
import { Login } from '../pages/Login';
import { Logout } from '../pages/Logout';
import { Materials } from '../pages/Materials';
import { Profile } from '../pages/Profile';
import { LayoutController } from './LayoutController';

export const RouteController: React.FC = () => {
  const kek = '';

  //TODO: Make "Layout" feature, means you need to create a "Layout" component, and then use it in "Routes" component, or use "Nested Routes" feature
  return (
    <Routes>
      <Route element={<LayoutController />}>
        <Route path="/applications" element={<ApplicationList />} />
        <Route path="/applications/info/:id" element={<ApplicationInfo />} />
        <Route path="/applications/create" element={<Application />} />
        <Route path="/employees" element={<EmployeeList />} />
        <Route path="/employees/profile/:id" element={<EmployeeProfile />} />
        <Route path="/employees/edit/:id" element={<EditEmployee />} />
        <Route path="/materials" element={<Materials />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Route>
    </Routes>
  );
};
