import { atom } from 'recoil';
import { RECOIL_KEYS } from '../../shared/const';

export const applicationsToReassignStore = atom<string[]>({
  key: RECOIL_KEYS.APPLICATIONS_TO_REASSIGN_STORE,
  default: [],
});

export const startReassignApplicationStore = atom<boolean>({
  key: RECOIL_KEYS.START_REASSIGN_APPLICATION_STORE,
  default: false,
});

export const successReassignCallbackStore = atom<() => Promise<void>>({
  key: RECOIL_KEYS.SUCCESS_REASSIGN_CALLBACK_STORE,
  default: async () => {},
});
