import { selector } from 'recoil';
import { APPLICATIONS_LIST_TABS, RECOIL_KEYS } from '../../shared/const';
import { ApplicationInfoResponse, ApplicationInfoResponseWithPagination } from '../../shared/types';
import { URL_LIST } from '../../shared/URLs';
import { fetcher, formatDateWithPointToTime, formatPhoneNumber, prettyFullNameWithInitials } from '../../shared/utils';
import { selectedEmployeeIDProfileStore } from '../EmployeeProfileEntity/states';
import {
  applicationSortState,
  applicationsPageState,
  applicationsSearchState,
  applicationsSelectedTabState,
} from './states';

export const applicationKeys = {
  Employee: 'Клиент',
  Status: 'Статус',
  ResponsibleEmployee: 'Ответственный сотрудник',
  CreationDate: 'Дата создания',
  StatusChangedDate: 'Статус изменился',
} as const;

export interface ApplicationRows extends Pick<ApplicationInfoResponse, 'id'> {
  Employee: {
    fullName: string;
    phone: string;
  };
  Status:
  | 'На рассмотрении'
  | 'Черновик'
  | 'Нужна доработка'
  | 'Отправлена'
  | 'Отказано'
  | 'Отменена'
  | 'Кредит выдан'
  | 'Заявка одобрена';
  ResponsibleEmployee: string;
  CreationDate: string;
  StatusChangedDate: string;
}

export const getApplicationsBySelectedTabQuery = selector({
  key: RECOIL_KEYS.APPLICATIONS_DRAFT_QUERY,
  get: async ({ get }) => {
    const selectedTab = get(applicationsSelectedTabState);
    const selectedEmployeeID = get(selectedEmployeeIDProfileStore);

    const searchQuery = get(applicationsSearchState(selectedTab));
    const page = get(applicationsPageState(selectedTab));
    const sortedFields = get(applicationSortState(selectedTab));

    const requestURL = new URL(URL_LIST.LOAN_APPLICATIONS_GET_URL);

    if (selectedEmployeeID) {
      requestURL.pathname = `/api/ml-application/partners-portal/loan-applications/assigned/${selectedEmployeeID}`;
    }

    if (selectedTab === APPLICATIONS_LIST_TABS.DRAFTS) {
      requestURL.searchParams.append('status', 'Draft');
    } else if (selectedTab === APPLICATIONS_LIST_TABS.NEED_REFINEMENT) {
      requestURL.searchParams.append('status', 'Elaboration');
    }

    if (searchQuery) {
      requestURL.searchParams.append('search', searchQuery);
    }

    requestURL.searchParams.append('page', (page - 1).toString());
    requestURL.searchParams.append('size', '10');
    requestURL.searchParams.append('sort', Object.entries(sortedFields).join(','));

    const { paging, result: applications } = await fetcher<ApplicationInfoResponseWithPagination>(requestURL.href);

    const applicationRows = applications.map(application => ({
      id: application.id,
      Employee: {
        fullName: prettyFullNameWithInitials(application.borrowerNameRu),
        phone: formatPhoneNumber(application.borrowerMobilePhone),
      },
      Status: application.status.nameRu,
      ResponsibleEmployee: prettyFullNameWithInitials(application.assignedToNameRu),
      CreationDate: formatDateWithPointToTime(application.createdTime),
      StatusChangedDate: formatDateWithPointToTime(application.updatedTime),
    })) as ApplicationRows[];

    const lastUpdate = Date.now();

    return {
      applicationRows,
      paging: { ...paging, pageNumber: paging.pageNumber + 1 },
      lastUpdate,
    };
  },
});
