import { useRecoilCallback } from 'recoil';
import { APPLICATION_DOCUMENT_TYPES } from '../../shared/const';
import { fetcher } from '../../shared/utils';
import { UploadDocumentFileResponse } from '../CreateApplicationEntity/types';
import { applicationFilesState } from './states';

export const useUploadApplicationDocument = (applicationID: string) =>
  useRecoilCallback(({ set }) => async (file: File, documentType: keyof typeof APPLICATION_DOCUMENT_TYPES) => {
    const data = new FormData();
    data.append('file', file);
    data.append('documentType', documentType);
    data.append('appNumber', applicationID);

    const { id, uploadTime } = await fetcher<UploadDocumentFileResponse>(`/api/ml-document/application-file`, {
      method: 'POST',
      body: data,
    });

    set(applicationFilesState(applicationID), previous => [
      ...previous,
      {
        id,
        documentType,
        fileName: file.name,
        size: file.size,
        uploadTime,
      },
    ]);
  });

export const useDeleteApplicationDocument = (applicationID: string) =>
  useRecoilCallback(({ set }) => async (id: string) => {
    if (!applicationID) {
      throw new Error('Не задан номер заявки');
    }

    await fetcher(`/api/ml-document/file/${id}`, {
      method: 'DELETE',
    });

    set(applicationFilesState(applicationID), previous => previous.filter(file => file.id !== id));
  });

export const useSendElaboration = (applicationID: string) =>
  // eslint-disable-next-line unicorn/consistent-function-scoping
  useRecoilCallback(({ set }) => async (comment: string) => {
    await fetcher(`/api/ml-application/partners-portal/loan-applications/${applicationID}/elaboration`, {
      method: 'POST',
      body: JSON.stringify({
        comment,
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });
  });
