import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import CheckmarkSVG from '../icons/checkmark.svg?->TSX';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefixElement?: React.ReactNode;
  postfixElement?: React.ReactNode;
  containerClasses?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, InputProps>(
  ({ postfixElement, prefixElement, containerClasses, ...attributes }, reference) => {
    const [checked, setChecked] = useState(Boolean(attributes.checked));

    return (
      <label className="flex relative items-center cursor-pointer">
        {prefixElement}
        <div
          className={clsx(
            'w-5 h-5 rounded-sm text-primary flex items-center justify-center border hover:border-primary focus-within:border-primary',
            attributes.checked ?? checked ? 'bg-accent border-transparent' : 'bg-white border-seattle-30',
          )}
        >
          <input
            className="visually-hidden not-checked:sibling:hidden"
            ref={reference}
            {...attributes}
            onChange={event => {
              setChecked(event.target.checked);

              if (attributes.onChange) {
                attributes.onChange(event);
              }
            }}
            type="checkbox"
          />
          <CheckmarkSVG height="100%" width="100%" />
        </div>
        {postfixElement}
      </label>
    );
  },
);
