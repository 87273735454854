export const UNKNOWN_ERROR_MESSAGE = 'Проверьте, что интернет работает или обратитесь в поддержку, если не помогло';
export const CLIENT_ERROR_MESSAGE = 'Обновите страницу или обратитесь в поддержку, если не помогло';
export const UNHANDLED_ERROR_MESSAGE = 'Произошла неожиданная ошибка';

export const FETCH_ERROR_MESSAGES = {
  400: CLIENT_ERROR_MESSAGE,
  401: CLIENT_ERROR_MESSAGE,
  403: 'Доступ запрещен',
  404: 'Не найдено',
  407: UNKNOWN_ERROR_MESSAGE,
  408: UNKNOWN_ERROR_MESSAGE,
  413: UNKNOWN_ERROR_MESSAGE,
  414: UNKNOWN_ERROR_MESSAGE,
  500: 'Мы скоро всё починим, попробуйте чуть позже',
} as const;
