import cx from 'clsx';
import React, { forwardRef } from 'react';

export interface RadioElement {
  value: string;
  label: string;
}

interface RadioProps {
  radioElements: RadioElement[];
  value: string;
  uniqueName: string;
  prefixElement?: React.ReactNode;
  postfixElement?: React.ReactNode;
  labelText?: string;
  errorText?: string;
  containerClasses?: string;
  elementClasses?: string;
  activeElementClasses?: string;
}

type RadioPropsWithAttributes = RadioProps & React.InputHTMLAttributes<HTMLInputElement>;

export const Radio = forwardRef<HTMLInputElement, RadioPropsWithAttributes>(
  (
    {
      prefixElement,
      postfixElement,
      errorText,
      labelText,
      containerClasses,
      elementClasses = 'relative py-1.5 cursor-pointer px-4 rounded-xl text-primary bg-wildSand',
      activeElementClasses = 'relative py-1.5 cursor-pointer px-4 rounded-xl text-white bg-primary',
      disabled,
      uniqueName,
      value,
      radioElements,
      ...attributes
    },
    reference,
  ) => (
    <div>
      {labelText && (
        <div className="input-label">
          <span className="input-label-text">{labelText}</span>
        </div>
      )}
      <div
        className={cx(
          'flex items-center',
          errorText && 'border-moscow',
          // disabled && 'input-disabled',
          containerClasses,
        )}
      >
        {prefixElement && <span className="input-prefixElement">{prefixElement}</span>}
        <div
          className={cx(
            'flex items-center text-m font-500 py-1 space-x-2',
            disabled && 'children:(cursor-not-allowed)',
          )}
        >
          {radioElements.map(radioElement => (
            <label
              className={value === radioElement.value ? activeElementClasses : elementClasses}
              key={`${radioElement.value}_${uniqueName}`}
            >
              <input
                ref={reference}
                name={uniqueName}
                type="radio"
                className="visually-hidden"
                disabled={disabled}
                value={radioElement.value}
                {...attributes}
              />
              <span>{radioElement.label}</span>
            </label>
          ))}
        </div>
        {postfixElement && <span className="input-suffixElement">{postfixElement}</span>}
      </div>
      {errorText && (
        <div className="input-error">
          <span data-cypress="inputValidation" className="input-error-text">
            {errorText}
          </span>
        </div>
      )}
    </div>
  ),
);
