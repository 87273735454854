import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { applicationCurrentSubjectIDState } from '../../../entities/CreateApplicationEntity/states';
import { DadataSuggestionResponse } from '../../../entities/CreateApplicationEntity/types';
import { DadataSuggestionSelect } from '../../../features/DadataSuggestionSelect';
import { Checkbox } from '../../../shared/components/Checkbox';
import { Input } from '../../../shared/components/Input';
import { DADATA_ACCEPT_FIAS_LEVELS, SUBJECT_TYPES } from '../../../shared/const';
import { SubjectResource } from '../../../shared/types';
import { fetcher } from '../../../shared/utils';
import { mobilePhoneTransformer } from '../transformers';

export const ContactsForm: React.FC = () => {
  const {
    formState: { errors },
    getValues,
    watch,
    control,
    setValue,
    resetField,
    register,
  } = useFormContext<SubjectResource>();

  const { subjectType } = useRecoilValue(applicationCurrentSubjectIDState);

  const isMatchedPlace = useMemo(() => {
    const { registrationAddress, residenceAddress } = getValues();

    if (residenceAddress === 'SAME_AS_REGISTRATION_ADDRESS') return true;

    return registrationAddress === residenceAddress;
  }, []);

  useEffect(() => {
    if (isMatchedPlace) {
      setValue('residenceAddress', 'SAME_AS_REGISTRATION_ADDRESS');
    }
  }, [isMatchedPlace, resetField]);

  const [matches, setMatches] = useState(isMatchedPlace);

  const handleSearchDadata = useCallback(async (query: string) => {
    const { suggestions } = await fetcher<DadataSuggestionResponse>(`/api/ml-application/common/dadata/address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ query }),
    });

    return suggestions;
  }, []);

  return (
    <div className="flex flex-col p-6 w-full shadow bg-white rounded" data-cypress="borrowerBlockWrapper">
      <h5 data-cypress="contacts-header" className="*h5">
        Контакты и адрес {subjectType === SUBJECT_TYPES.BORROWER ? 'Заемщика' : 'Созаемщика'}
      </h5>
      <div className="flex mt-4 flex-col space-y-6">
        <div className="flex children:flex-1 space-x-6">
          <Controller
            control={control}
            name="mobilePhone"
            render={({ field }) => (
              <Input
                data-cypress="mobilePhone"
                labelText="Номер телефона"
                type="tel"
                containerClasses="h-10"
                errorText={errors.mobilePhone?.message}
                value={mobilePhoneTransformer.output(field.value ?? '')}
                onChange={event => field.onChange(mobilePhoneTransformer.input(event.target.value))}
              />
            )}
          />
          <Input
            data-cypress="email"
            labelText="Электронная почта"
            placeholder="Адрес электронной почты"
            type="email"
            containerClasses="h-10"
            errorText={errors.email?.message}
            {...register('email')}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <Controller
            control={control}
            name="registrationAddress"
            render={({ field, fieldState: { error } }) => (
              <DadataSuggestionSelect
                labelText="Адрес регистрации"
                data-cypress="registrationAddress"
                placeholder="Город, улица, дом, квартира"
                type="text"
                containerClasses="h-10"
                defaultValue={field.value}
                callForSuggestion={handleSearchDadata}
                onSelectSuggestion={(suggestion, isValid) =>
                  isValid ? field.onChange(suggestion) : field.onChange('')
                }
                acceptFiasLevels={DADATA_ACCEPT_FIAS_LEVELS as unknown as string[]}
                errorText={error?.message}
              />
            )}
          />
          <Checkbox
            data-cypress="residenceAddressMatchesRegistrationAddress"
            checked={matches}
            onChange={event => {
              setMatches(event.target.checked);

              setValue('residenceAddress', event.target.checked ? 'SAME_AS_REGISTRATION_ADDRESS' : '');
            }}
            postfixElement={<span className="text-m ml-2 select-none">Совпадает с адресом проживания</span>}
          />
          {!matches && (
            <Controller
              control={control}
              name="residenceAddress"
              render={({ field, fieldState: { error } }) => (
                <DadataSuggestionSelect
                  labelText="Адрес проживания"
                  placeholder="Город, улица, дом, квартира"
                  type="text"
                  containerClasses="h-10"
                  defaultValue={field.value}
                  callForSuggestion={handleSearchDadata}
                  onSelectSuggestion={(suggestion, isValid) =>
                    isValid ? field.onChange(suggestion) : field.onChange('')
                  }
                  acceptFiasLevels={DADATA_ACCEPT_FIAS_LEVELS as unknown as string[]}
                  errorText={error?.message}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
