/* eslint-disable sonarjs/no-duplicate-string */
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  applicationNeedRefinementCountState,
  applicationsSearchState,
  applicationsSelectedTabState,
} from '../entities/ApplicationsEntity/states';
import { userProfileQuery } from '../entities/ProfileEntity/queries';
import HouseBG from '../shared/images/House-BG.svg';
import { debounce } from '../shared/utils/debounce';
import { ApplicationListWidget } from '../widgets/ApplicationsListWidget';

const EmptyApplicationScreen = () => {
  const currentProfile = useRecoilValue(userProfileQuery);

  const subTitle = currentProfile.isAdmin
    ? 'Здесь будут показаны заявки, которые создали вы или ваши сотрудники'
    : 'Здесь будут показаны ваши заявки';

  return (
    <div className="flex flex-col h-full mt-6 mb-10 bg-white items-center justify-center shadow rounded">
      <div style={{ background: `url(${HouseBG}) no-repeat top center` }} className="text-xxl font-700 pt-[91px]">
        Заявок пока нет
      </div>
      <span className="*typo-sm text-seattle-100 mt-2">{subTitle}</span>
    </div>
  );
};

export const ApplicationList: React.FC = () => {
  const kek = '1';

  return (
    <div className="pt-6 px-12 flex flex-col max-h-screen overflow-y-auto w-full">
      <div className="flex">
        <h1 className="*h1">Заявки</h1>
        <Link
          to="/applications/create"
          className="ml-auto *button bg-accent text-primary hover:bg-onAccent"
          data-cypress="create-application"
        >
          Создать заявку
        </Link>
      </div>

      {/* {applicationRows.length === 0 ? (
        <EmptyApplicationScreen /> */}

      <ApplicationListWidget />
    </div>
  );
};
