/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { FormProvider, NestedValue, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { z as validate } from 'zod';
import { applicationOCRDataState, applicationOCRStatusState } from '../../entities/ApplicationOCREntity/states';
import { isApplicationValidQuery, subjectPassportTypeQuery } from '../../entities/CreateApplicationEntity/queries';
import {
  applicationCurrentSubjectIDState,
  applicationIDState,
  applicationPersonalDataState,
  applicationStageState,
} from '../../entities/CreateApplicationEntity/states';
import {
  APPLICATION_IDENTIFY_DOCUMENT_TYPES,
  APPLICATION_STAGES,
  OCR_STATUSES,
  SUBJECT_TYPES,
} from '../../shared/const';
import BackSVG from '../../shared/icons/back.svg?->TSX';
import { IdentityDocument, NameRu, SubjectResource } from '../../shared/types';
import { fetcher } from '../../shared/utils';
import { OCRWidget } from '../OCRWidget';
import { ContactsForm } from './FormParts/ContactsForm';
import { PassportForm } from './FormParts/PassportForm';
import { PersonalForm } from './FormParts/PersonalForm';
import { dateISOStringTransformer, subunitCodeTransformer } from './transformers';

export const ApplicationFormSchemaRU = validate.object({
  mobilePhone: validate.string().regex(/^7(\d{10})$/, {
    message: 'Недопустимый формат номера телефона для РФ',
  }),
  email: validate
    .string()
    .email({ message: 'Недопустимый формат e-mail' })
    .regex(
      /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/,
      'Недопустимые символы',
    ),
  registrationAddress: validate
    .string({ required_error: 'Поле обязательное для заполнения' })
    .trim()
    .min(1, 'Неполный адрес, выберите адрес из списка'),
  residenceAddress: validate
    .string({ required_error: 'Поле обязательное для заполнения' })
    .trim()
    .min(1, 'Неполный адрес, выберите адрес из списка')
    .or(validate.literal('SAME_AS_REGISTRATION_ADDRESS')),
  birthDate: validate
    .string()
    .length(8, 'Разрешенная длина поля 8 символов')
    .regex(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(19|20)\d\d$/, 'Недопустимые символы или неверный формат даты')
    .refine(date => {
      const day = date.slice(0, 2);
      const month = date.slice(2, 4);
      const year = date.slice(4, 8);
      const checkIsDateExists = new Date(`${year}-${month}-${day}`);

      if (checkIsDateExists.toString() === 'Invalid Date') {
        return false;
      }

      return `${year}-${month}-${day}` === checkIsDateExists.toISOString().slice(0, 10);
    }, 'Даты не существует')
    .refine(date => {
      const ONE_DAY_IN_MS = 86_400_000;

      const day = date.slice(0, 2);
      const month = date.slice(2, 4);
      const year = date.slice(4, 8);
      const age = Math.abs(
        moment.duration(Date.now() - new Date(`${year}-${month}-${day}`).getTime() + ONE_DAY_IN_MS).years(),
      );

      return age >= 21 && age < 65 - 36 / 12;
    }, 'Допустимый возраст — от 21 до 65 лет на момент окончания кредита'),
  birthPlace: validate
    .string()
    .min(2, 'Поле обязательное для заполнения')
    .max(250, 'Разрешенная длина поля от 2 до 250 символов')
    .regex(/^[\d\s"'()*,./:;INVX_invx|«»ЁА-яё№\-]+$/, 'Недопустимые символы'),
  citizenship: validate.literal('RU', {
    invalid_type_error: 'Произошла непредвиденная ошибка, попробуйте позже',
    required_error: 'Произошла непредвиденная ошибка, попробуйте позже',
  }),
  gender: validate
    .string({ invalid_type_error: 'Поле обязательное для заполнения' })
    .regex(/M|F/, 'Поле обязательное для заполнения'),
  nameRu: validate.object({
    firstName: validate
      .string()
      .min(1, 'Поле обязательное для заполнения')
      .max(50, 'Разрешенная длина поля от 1 до 50 символов')
      .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы'),
    middleName: validate
      .string()
      .min(1, 'Поле обязательное для заполнения')
      .max(50, 'Разрешенная длина поля от 1 до 50 символов')
      .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы')
      .or(validate.string().length(0)),
    lastName: validate
      .string()
      .min(1, 'Поле обязательное для заполнения')
      .max(50, 'Разрешенная длина поля от 1 до 50 символов')
      .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы'),
  }),
  identityDocument: validate.object({
    series: validate.string().length(4, 'Разрешенная длина поля 4 символов').regex(/^\d+$/, 'Недопустимые символы'),
    number: validate.string().length(6, 'Разрешенная длина поля 6 символов').regex(/^\d+$/, 'Недопустимые символы'),
    issueDate: validate
      .string()
      .length(8, 'Разрешенная длина поля 8 символов')
      .regex(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(19|20)\d\d$/, 'Недопустимые символы или неверный формат даты')
      .refine(date => {
        const day = date.slice(0, 2);
        const month = date.slice(2, 4);
        const year = date.slice(4, 8);
        const checkIsDateExists = new Date(`${year}-${month}-${day}`);

        if (checkIsDateExists.toString() === 'Invalid Date') {
          return false;
        }

        return `${year}-${month}-${day}` === checkIsDateExists.toISOString().slice(0, 10);
      }, 'Даты не существует')
      .refine(date => {
        const day = date.slice(0, 2);
        const month = date.slice(2, 4);
        const year = date.slice(4, 8);

        const issueDate = new Date(`${year}-${month}-${day}`).getTime();
        const now = Date.now();
        return issueDate <= now;
      }, 'Дата не должна быть в будущем'),
    issuedBy: validate
      .string()
      .min(2, 'Поле обязательное для заполнения')
      .max(500, 'Разрешенная длина поля от 2 до 500 символов')
      .regex(/^[\d "'()*,./:;INVX_ivx|«»ЁА-яё№\-]+$/, 'Недопустимые символы'),
    subunitCode: validate
      .string()
      .length(6, 'Разрешенная длина поля 6 символов')
      .regex(/^\d+$/, 'Недопустимые символы'),
  }),
});

export const ApplicationFormSchemaF = validate.object({
  mobilePhone: validate.string().regex(/^7(\d{10})$/, {
    message: 'Недопустимый формат номера телефона для РФ',
  }),
  email: validate
    .string()
    .email({ message: 'Недопустимый формат e-mail' })
    .regex(
      /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/,
      'Недопустимые символы',
    ),
  registrationAddress: validate
    .string({ required_error: 'Поле обязательное для заполнения' })
    .min(1, 'Неполный адрес, выберите адрес из списка'),
  residenceAddress: validate
    .string({ required_error: 'Поле обязательное для заполнения' })
    .trim()
    .min(1, 'Неполный адрес, выберите адрес из списка')
    .or(validate.literal('SAME_AS_REGISTRATION_ADDRESS')),
  birthDate: validate
    .string()
    .length(8, 'Разрешенная длина поля 8 символов')
    .regex(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(19|20)\d\d$/, 'Недопустимые символы или неверный формат даты')
    .refine(date => {
      const day = date.slice(0, 2);
      const month = date.slice(2, 4);
      const year = date.slice(4, 8);
      const checkIsDateExists = new Date(`${year}-${month}-${day}`);

      if (checkIsDateExists.toString() === 'Invalid Date') {
        return false;
      }

      return `${year}-${month}-${day}` === checkIsDateExists.toISOString().slice(0, 10);
    }, 'Даты не существует')
    .refine(date => {
      const ONE_DAY_IN_MS = 86_400_000;

      const day = date.slice(0, 2);
      const month = date.slice(2, 4);
      const year = date.slice(4, 8);
      const age = Math.abs(
        moment.duration(Date.now() - new Date(`${year}-${month}-${day}`).getTime() + ONE_DAY_IN_MS).years(),
      );

      return age >= 21 && age < 65 - 36 / 12;
    }, 'Допустимый возраст — от 21 до 65 лет на момент окончания кредита'),
  birthPlace: validate
    .string()
    .min(2, 'Поле обязательное для заполнения')
    .max(250, 'Разрешенная длина поля от 2 до 250 символов')
    .regex(/^[\d\s"'()*,./:;INVX_invx|«»ЁА-яё№\-]+$/, 'Недопустимые символы'),
  citizenship: validate.string().length(2, 'Поле обязательное для заполнения'),
  gender: validate
    .string({ invalid_type_error: 'Поле обязательное для заполнения' })
    .regex(/M|F/, 'Поле обязательное для заполнения'),
  nameRu: validate.object({
    firstName: validate
      .string()
      .min(1, 'Поле обязательное для заполнения')
      .max(50, 'Разрешенная длина поля от 1 до 50 символов')
      .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы'),
    //FIXME: middle name wrong validation
    middleName: validate
      .string()
      .min(1, 'Поле обязательное для заполнения')
      .max(50, 'Разрешенная длина поля от 1 до 50 символов')
      .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы')
      .or(validate.string().length(0)),
    lastName: validate
      .string()
      .min(1, 'Поле обязательное для заполнения')
      .max(50, 'Разрешенная длина поля от 1 до 50 символов')
      .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы'),
  }),
  identityDocument: validate.object({
    series: validate
      .string()
      .max(4, 'Разрешенная длина поля до 4 символов')
      .regex(/^[\dA-Za-zЁА-яё]+$/, 'Недопустимые символы')
      .or(validate.string().length(0)),
    number: validate
      .string()
      .min(4, 'Поле обязательное для заполнения')
      .max(10, 'Разрешенная длина поля от 4 до 10 символов')
      .regex(/^[\dA-Za-zЁА-яё]+$/, 'Недопустимые символы'),
    // Regexp for date in ISO format (YYYY-MM-DD)
    issueDate: validate
      .string()
      .length(8, 'Разрешенная длина поля 8 символов')
      .regex(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(19|20)\d\d$/, 'Недопустимые символы или неверный формат даты')
      .refine(date => {
        const day = date.slice(0, 2);
        const month = date.slice(2, 4);
        const year = date.slice(4, 8);
        const checkIsDateExists = new Date(`${year}-${month}-${day}`);

        if (checkIsDateExists.toString() === 'Invalid Date') {
          return false;
        }

        return `${year}-${month}-${day}` === checkIsDateExists.toISOString().slice(0, 10);
      }, 'Даты не существует')
      .refine(date => {
        const day = date.slice(0, 2);
        const month = date.slice(2, 4);
        const year = date.slice(4, 8);

        const issueDate = new Date(`${year}-${month}-${day}`).getTime();
        const now = Date.now();
        return issueDate <= now;
      }, 'Дата не должна быть в будущем'),
    issuedBy: validate
      .string()
      .min(2, 'Поле обязательное для заполнения')
      .max(500, 'Разрешенная длина поля от 2 до 500 символов')
      .regex(/^[\d "'()*,./:;INVX_ivx|«»ЁА-яё№\-]+$/, 'Недопустимые символы'),
  }),
});

interface ApplicationFormFields {
  //! DDMMYYYY
  birthDate: string;
  birthPlace: string;
  // RU | UA | UK | US e.t.c
  citizenship: string;
  email: string;
  mobilePhone: string;
  gender: 'M' | 'F';
  identityDocument:
  | NestedValue<Omit<IdentityDocument, 'type'>>
  | NestedValue<Omit<IdentityDocument, 'type' | 'subunitCode'>>;
  nameRu: NestedValue<NameRu>;
  registrationAddress: string;
  residenceAddress: string;
}

//Regexp for symbols [X,V,I,N], [x,v,i], [А-Я], [а-я], [Ёё], [0-9], [, . ; : " ' * _ - ( ) / | № «»], [space]

const useFormSubmit = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (data: ApplicationFormFields) => {
        try {
          const applicationID = await snapshot.getPromise(applicationIDState);
          const { subjectType, subjectID } = await snapshot.getPromise(applicationCurrentSubjectIDState);
          const dataState = await snapshot.getPromise(applicationPersonalDataState(subjectID));

          if (!dataState.identityDocument?.type) {
            throw new Error('Unknown identity document type');
          }

          await fetcher(
            `/api/ml-application/partners-portal/loan-applications/${applicationID}/${
              subjectType === SUBJECT_TYPES.BORROWER ? 'borrowers' : 'coborrowers'
            }/${subjectID}`,
            {
              method: 'PUT',
              body: JSON.stringify({
                ...data,
                identityDocument: {
                  subunitCode: '',
                  ...data.identityDocument,
                  type: dataState.identityDocument?.type,
                  //? DDMMYYY to ISO YYYY-MM-DD
                  issueDate: data.identityDocument.issueDate.replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1'),
                },
                registrationAddress: data.registrationAddress,
                residenceAddress:
                  data.residenceAddress === 'SAME_AS_REGISTRATION_ADDRESS'
                    ? data.registrationAddress
                    : data.residenceAddress,
                //? DDMMYYY to ISO YYYY-MM-DD
                birthDate: data.birthDate.replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$2-$1'),
              }),
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },
            },
          );

          set(applicationPersonalDataState(subjectID), {
            ...data,
            registrationAddress: data.registrationAddress,
            residenceAddress:
              data.residenceAddress === 'SAME_AS_REGISTRATION_ADDRESS'
                ? data.registrationAddress
                : data.residenceAddress,
            identityDocument: {
              subunitCode: '',
              ...data.identityDocument,
              type: dataState.identityDocument.type,
            },
          });
          set(applicationStageState, APPLICATION_STAGES.DOCUMENTS);
        } catch {
          toast.error(
            'Возникла неожиданная ошибка при подтверждении формы. Обновите страницу или обратитесь в поддержку',
          );
        }
      },
    [],
  );

export const ApplicationFormWidget: React.FC = () => {
  const { subjectID } = useRecoilValue(applicationCurrentSubjectIDState);

  const initialValues = useRecoilValue(applicationPersonalDataState(subjectID));
  const setDataState = useSetRecoilState(applicationPersonalDataState(subjectID));

  const submitForm = useFormSubmit();

  const OCRValues = useRecoilValue(applicationOCRDataState(subjectID));
  const OCRStatus = useRecoilValue(applicationOCRStatusState(subjectID));
  const subjectPassportType = useRecoilValue(subjectPassportTypeQuery);
  const resetOCRValues = useResetRecoilState(applicationOCRDataState(subjectID));

  const goToChooseSubject = useRecoilCallback(
    ({ set, refresh }) =>
      () => {
        refresh(isApplicationValidQuery);
        set(applicationStageState, APPLICATION_STAGES.CHOOSE_SUBJECT);
      },
    [],
  );

  const goToDocuments = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationStageState, APPLICATION_STAGES.DOCUMENTS);
      },
    [],
  );

  const isRussianPassport = useMemo(
    () => subjectPassportType === APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT,
    [subjectPassportType],
  );

  //FIXME: SubjectResource is wrong type because this form doesn't have all the fields
  const methods = useForm<SubjectResource>({
    resolver: zodResolver(isRussianPassport ? ApplicationFormSchemaRU : ApplicationFormSchemaF),
    defaultValues: initialValues,
    mode: 'all',
  });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (OCRValues) {
      //FIXME: Универсальный метод, как в блоке else
      methods.setValue('nameRu.firstName', OCRValues.nameRu?.firstName ?? '');
      methods.setValue('nameRu.lastName', OCRValues.nameRu?.lastName ?? '');
      methods.setValue('nameRu.middleName', OCRValues.nameRu?.middleName ?? '');

      methods.setValue('birthDate', dateISOStringTransformer.input(OCRValues?.birthDate ?? ''));
      methods.setValue('birthPlace', OCRValues.birthPlace);
      methods.setValue('citizenship', OCRValues.citizenship);

      methods.setValue('gender', OCRValues.gender);

      methods.setValue('identityDocument.series', OCRValues.identityDocument?.series ?? '');
      methods.setValue('identityDocument.number', OCRValues.identityDocument?.number ?? '');
      methods.setValue('identityDocument.issuedBy', OCRValues.identityDocument?.issuedBy ?? '');
      methods.setValue(
        'identityDocument.issueDate',
        dateISOStringTransformer.input(OCRValues.identityDocument?.issueDate ?? ''),
      );
      methods.setValue(
        'identityDocument.subunitCode',
        subunitCodeTransformer.input(OCRValues.identityDocument?.subunitCode ?? ''),
      );

      setDataState(previousState => ({
        ...previousState,
        nameRu: {
          firstName: OCRValues.nameRu?.firstName ?? '',
          lastName: OCRValues.nameRu?.lastName ?? '',
          middleName: OCRValues.nameRu?.middleName ?? '',
        },
        birthDate: dateISOStringTransformer.input(OCRValues?.birthDate ?? ''),
        birthPlace: OCRValues.birthPlace,
        citizenship: OCRValues.citizenship,
        gender: OCRValues.gender,
        identityDocument: {
          ...previousState.identityDocument,
          series: OCRValues.identityDocument?.series ?? '',
          number: OCRValues.identityDocument?.number ?? '',
          subunitCode: subunitCodeTransformer.input(OCRValues.identityDocument?.subunitCode ?? ''),
          issueDate: dateISOStringTransformer.input(OCRValues.identityDocument?.issueDate ?? ''),
          issuedBy: OCRValues.identityDocument?.issuedBy ?? '',
        },
      }));

      resetOCRValues();
    }
  }, [OCRValues]);

  return (
    <div className="relative pt-6 mb-16 px-12 overflow-auto h-full flex flex-col">
      <div className="flex min-h-9 items-center">
        <Link className="flex h-full items-center space-x-2 text-primary" to="/applications">
          <BackSVG className="mb-0.5" width={20} height={20} />
          <span data-cypress="all-applications" className="font-500 text-m">
            Все заявки
          </span>
        </Link>
      </div>

      <div data-cypress="application-header" className="flex items-center space-x-4">
        <h1 className="*h1">Новая заявка</h1>
      </div>

      <form className="mt-6 mb-25 space-y-4">
        <FormProvider {...methods}>
          <OCRWidget />
          {OCRStatus !== OCR_STATUSES.NOT_STARTED && <ContactsForm />}
          {(OCRStatus === OCR_STATUSES.COMPLETED_WITH_WARNINGS ||
            OCRStatus === OCR_STATUSES.COMPLETED_WITH_ERRORS ||
            OCRStatus === OCR_STATUSES.NOT_NEEDED ||
            OCRStatus === OCR_STATUSES.NOT_INFORMATION) && (
            <div className="p-6 w-full shadow bg-white rounded">
              <PersonalForm />
              <PassportForm />
            </div>
          )}
        </FormProvider>
      </form>

      <div className="fixed bottom-0 right-0 w-[calc(100%-252px)] py-2 px-12 bg-white">
        <div className="flex space-x-4 children:(w-full flex items-center justify-center font-500 px-5 rounded text-m text-primary h-10)">
          <button
            data-cypress="back"
            className="border-2 border-seattle-30 hover:border-primary"
            type="button"
            onClick={goToChooseSubject}
          >
            Назад
          </button>

          <button
            className="bg-accent hover:bg-onAccent"
            data-cypress="continue"
            type="button"
            onClick={methods.handleSubmit(
              //FIXME: ???
              data => submitForm(data as unknown as ApplicationFormFields),
              errors => {
                console.log(errors);
                toast.error('Проверьте правильность заполнения полей', {
                  pauseOnHover: false,
                });
              },
            )}
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
  );
};
