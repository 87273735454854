import { APPLICATION_DOCUMENT_TYPES, RAIFFEISEN_REALTY_ALL_ROLES, SUBJECT_TYPES, USER_QUERY_OPTIONS } from './const';

export interface EmployeeInfoResponse {
  id: string;
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  mobilePhone: string | null;
  email: string | null;
  state: keyof typeof USER_QUERY_OPTIONS;
  role: keyof typeof RAIFFEISEN_REALTY_ALL_ROLES;
  editable: boolean | null;
  partnerRdmId: boolean | null;
}

export interface DocumentFileResponse {
  borrowerId: string;
  documentType: keyof typeof APPLICATION_DOCUMENT_TYPES;
  fileName: string;
  id: string;
  size: number;
  //* String EQ Date
  uploadTime: string;
}

export type ApplicationFileState = Omit<DocumentFileResponse, 'borrowerId'>;

export interface DocumentFileResponseElaboration extends Omit<DocumentFileResponse, 'borrowerId'> {
  borrowerId: null;
}

/*
Draft
SentToBank
ProcessingByBank
ApplicationDenied
ApplicationApproved
Canceled
LoanIssued
Elaboration
*/

export const APPLICATION_STATUS_CODES = {
  Elaboration: 'Elaboration',
  SentToBank: 'SentToBank',
  ProcessingByBank: 'ProcessingByBank',
  ApplicationDenied: 'ApplicationDenied',
  ApplicationApproved: 'ApplicationApproved',
  Canceled: 'Canceled',
  LoanIssued: 'LoanIssued',
  Draft: 'Draft',
} as const;

export interface ApplicationStatus {
  code: keyof typeof APPLICATION_STATUS_CODES;
  nameEn: string;
  nameRu: string;
}

export interface ApplicationInfoResponse {
  id: string;
  assignedToNameRu: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
  borrowerNameRu: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
  status: ApplicationStatus;
  borrowerMobilePhone: string;
  // Date
  createdTime: string;
  // Date
  updatedTime: string;
}

export interface ApplicationInfoResponseWithPagination {
  paging: {
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    totalElements: number;
  };
  result: ApplicationInfoResponse[] | [];
}

export interface IdentityDocument {
  type: 'RUPASSPORT' | 'FPASSPORT';
  series: string;
  number: string;
  subunitCode: string;
  // ISO Date: yyyy-MM-dd
  issueDate: string;
  issuedBy: string;
}

export interface NameRu {
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface NaturalPersonResponse {
  // ISO Date: yyyy-MM-dd
  birthDate: string | null;
  birthPlace: string | null;
  // RU | UA | UK | US e.t.c
  citizenship: string | null;
  email: string | null;
  mobilePhone: string | null;
  gender: 'M' | 'F' | null;
  identityDocument: IdentityDocument | null;
  nameRu: NameRu | null;
  registrationAddress: string | null;
  residenceAddress: string | null;
}

export interface SubjectResource extends NaturalPersonResponse {
  // ISO Date: yyyy-MM-dd
  birthDate: string;
  birthPlace: string;
  // RU | UA | UK | US e.t.c
  citizenship: string;
  email: string;
  mobilePhone: string;
  gender: 'M' | 'F' | null;
  identityDocument: IdentityDocument;
  nameRu: NameRu;
  registrationAddress: string;
  residenceAddress: string;
}

export interface ApplicationDraftResponse {
  id: string;
  //* String EQ Date
  createdTime: string;
  borrower: (SubjectResource & { id: string } & { clientType: keyof typeof SUBJECT_TYPES })[];
}

export type ContactsFormFields = Pick<
SubjectResource,
'email' | 'mobilePhone' | 'registrationAddress' | 'residenceAddress'
>;

export type PersonalFormFields = Omit<SubjectResource, keyof ContactsFormFields>;

export interface SubjectIDInfo {
  subjectID: string;
  subjectType: keyof typeof SUBJECT_TYPES;
}

export interface ResultValidationItem {
  application: {
    success: boolean;
  };
  documents: {
    success: boolean;
  };
}

export type ResultValidationInformation = {
  isValid: boolean;
  subjects: Record<string, ResultValidationItem>;
};
