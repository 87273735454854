import cx from 'clsx';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import AttachSVG from '../shared/icons/attach.svg?->TSX';
import CancelSVG from '../shared/icons/cancel.svg?->TSX';
import { colors } from '../shared/theme';
import { formatDate, useDropArea } from '../shared/utils';

export interface FileType {
  readonly id: string;
  name: string;
  lastModified: number;
  size: number;
}

const makeUniqueFileHash = (file: Omit<FileType, 'id'>) =>
  `${file.name}|${file.lastModified}|${file.size}__${nanoid()}`;

const parseExtensionFromFileName = (fileName: string, dot = false) =>
  fileName.slice(Math.max(0, fileName.lastIndexOf('.') + (dot ? 0 : 1))).toLocaleLowerCase();

const FILE_SIZE = {
  ТБ: 1 * 1024 * 1024 * 1024 * 1024,
  ГБ: 1 * 1024 * 1024 * 1024,
  МБ: 1 * 1024 * 1024,
  КБ: 1 * 1024,
  Б: 1,
} as const;

export type FileSize = [number, keyof typeof FILE_SIZE];

export const parseFileSize = (size: FileSize): number => {
  const [sizeValue, sizeUnit] = size;
  switch (sizeUnit) {
    case 'ТБ':
      return sizeValue * FILE_SIZE.ТБ;
    case 'ГБ':
      return sizeValue * FILE_SIZE.ГБ;
    case 'МБ':
      return sizeValue * FILE_SIZE.МБ;
    case 'КБ':
      return sizeValue * FILE_SIZE.КБ;
    case 'Б':
      return sizeValue * FILE_SIZE.Б;
    default:
      return 0;
  }
};

//parse bytes to string
export const bytesToUnitsString = (size: number): string => {
  const units = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];

  for (const [index, unit] of units.entries()) {
    if (size <= Math.pow(1024, index + 1)) {
      return `${(size / Math.pow(1024, index)).toFixed(2)} ${unit}`;
    }
  }

  return '';
};

export const Reasons = {
  BIG_SIZE: 'BIG_SIZE',
  WRONG_EXTENSION: 'WRONG_EXTENSION',
  FILE_NAME_LENGTH_OVER: 'FILE_NAME_LENGTH_OVER',
  FILE_NAME_LENGTH_LESS: 'FILE_NAME_LENGTH_LESS',
  DUPLICATE: 'DUPLICATE',
} as const;

export interface FileLoaderProps {
  files: FileType[];
  onFileLoaded?: (file: File) => Promise<void>;
  onFileDelete?: (file: FileType) => Promise<void>;
  onFileRejected?: (file: File, reason: keyof typeof Reasons) => void;
  showProgressBar?: boolean;
  maxFileSize?: FileSize;
  allowedExtensions?: readonly string[];
  allowedFileNameLength?: { MIN: number; MAX: number };
  allowDuplicateFileName?: boolean;
  required?: boolean;
  errorMessage?: string;
}

interface FileItemProps {
  file: FileType;
  onFileDelete: (file: FileType) => Promise<void>;
}

const FileItem: React.FC<FileItemProps> = ({ file, onFileDelete }) => {
  const { name, lastModified, size } = file;
  const [shouldDisabled, setShouldDisabled] = useState(false);

  return (
    <div className="flex flex-col children:py-4">
      <div className="flex">
        <div className="flex flex-shrink-0 items-center text-s justify-center w-10 h-10 rounded-sm bg-paris-10">
          <span className="truncate">{parseExtensionFromFileName(name).toUpperCase()}</span>
        </div>

        <div className="w-full ml-4">
          <div className="flex flex-col">
            <span data-cypress="document-name" className="text-m font-500">
              {name}
            </span>
            <span data-cypress="document-date" className="text-s text-seattle-60">
              {formatDate(new Date(lastModified))} • {bytesToUnitsString(size)}
            </span>
          </div>

          {/* <ProgressBar
                    guessTimeInMilliseconds={Math.max((files[index].size / parsefiles[index]Size([1, 'МБ'])) * 1000, 500)}
                    stopAndUnmountOnEnd
                    isStopped={files[index]s.includes(files[index])}
                  /> */}
        </div>

        <button
          data-cypress="delete-doc"
          onClick={async () => {
            setShouldDisabled(true);
            await onFileDelete(file);
            setShouldDisabled(false);
          }}
          disabled={shouldDisabled}
          className="px-6 disabled:(cursor-not-allowed)"
          type="button"
        >
          <CancelSVG width={16} height={16} />
        </button>
      </div>
    </div>
  );
};

export const FileLoader: React.FC<FileLoaderProps> = ({
  files,
  allowDuplicateFileName = false,
  maxFileSize = [10, 'МБ'],
  allowedExtensions = [],
  allowedFileNameLength = { MIN: 3, MAX: 100 },
  onFileLoaded,
  onFileDelete,
  onFileRejected,
  errorMessage,
}) => {
  const [droppedFilesNames, setDroppedFilesNames] = useState<string[]>([]);

  const fileCallback = useCallback(
    (file: File) => {
      console.debug('File Loaded', file);

      // FileName without extension
      const fileName = file.name.slice(0, file.name.lastIndexOf('.'));

      if (fileName.length >= allowedFileNameLength.MAX) {
        onFileRejected?.(file, Reasons.FILE_NAME_LENGTH_OVER);
        return;
      }

      if (fileName.length <= allowedFileNameLength.MIN) {
        onFileRejected?.(file, Reasons.FILE_NAME_LENGTH_LESS);
        return;
      }

      if (!allowDuplicateFileName && droppedFilesNames.includes(file.name)) {
        return onFileRejected?.(file, Reasons.DUPLICATE);
      }

      const fileSize = parseFileSize(maxFileSize);
      if (file.size > fileSize) {
        return onFileRejected?.(file, Reasons.BIG_SIZE);
      }

      const fileExtension = parseExtensionFromFileName(file.name, true);

      if (allowedExtensions.length > 0 && !allowedExtensions.includes(fileExtension)) {
        return onFileRejected?.(file, Reasons.WRONG_EXTENSION);
      }

      setDroppedFilesNames(previous => [...previous, file.name]);

      onFileLoaded?.(file);
    },
    [allowDuplicateFileName, droppedFilesNames, maxFileSize, onFileLoaded, onFileRejected, allowedExtensions],
  );

  const fileDeleteCallback = useCallback(
    async (file: FileType) => {
      setDroppedFilesNames(previous => previous.filter(name => name !== file.name));
      await onFileDelete?.(file);
    },
    [onFileDelete],
  );

  const [bond, state] = useDropArea({
    onFiles: newFiles => {
      for (const element of newFiles) {
        fileCallback(element);
      }
    },
  });

  const AllowedExtensionsText = useMemo(() => {
    if (allowedExtensions.length === 0) return '';

    const extensionsText =
      allowedExtensions.length > 0
        ? allowedExtensions.map(extension => extension.slice(1).toUpperCase()).join(', ')
        : '';

    const maxSizeText = maxFileSize.length > 0 ? `до ${maxFileSize.join(' ')} каждый` : '';

    return `${extensionsText} ${maxSizeText}`;
  }, [allowedExtensions]);

  return (
    <>
      <div
        style={!state.over ? undefined : { borderColor: colors.oslo[100], backgroundColor: colors.oslo[10] }}
        className={cx(
          'w-full py-6 flex flex-col justify-center space-y-1 border border-dashed rounded text-m bg-seattle-5',
          errorMessage ? 'border-moscow-100' : 'border-seattle-30',
        )}
        {...bond}
      >
        <div className="flex mx-auto">
          <span className="text-seattle-100">Перетащите файл сюда или </span>
          <label className="relative flex space-x-1 text-oslo-120 ml-2 cursor-pointer">
            <AttachSVG width={20} height={20} />
            <span>загрузите документы</span>
            <input
              data-cypress="upload-file"
              accept={allowedExtensions.join(',')}
              onChange={event => {
                const manuallyUploadedFiles = event.target.files;
                if (manuallyUploadedFiles) {
                  for (const element of manuallyUploadedFiles) {
                    fileCallback(element);
                  }
                }
              }}
              required
              className="visually-hidden"
              multiple
              type="file"
            />
          </label>
        </div>
        {AllowedExtensionsText.length > 0 && <span className="text-seattle-100 mx-auto">{AllowedExtensionsText}</span>}
      </div>
      {errorMessage && (
        <div className="mt-3 text-m text-moscow-100 flex items-center justify-center">{errorMessage}</div>
      )}
      {files.length > 0 && (
        <div className="mt-6 w-full max-h-[30vh] overflow-y-auto">
          {files.map(file => (
            <FileItem key={file.id} file={file} onFileDelete={fileDeleteCallback} />
          ))}
        </div>
      )}
    </>
  );
};
