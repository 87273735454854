import { useEffect, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { DocumentsForm } from '..';
import {
  useDeleteSubjectDocument,
  useUploadSubjectDocuments,
} from '../../../entities/CreateApplicationEntity/controllers';
import { FileLoader } from '../../../features';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_FILENAME_LENGTH,
  ALLOWED_FILESIZE,
  APPLICATION_DOCUMENT_TYPES,
} from '../../../shared/const';
import { ApplicationFileState } from '../../../shared/types';
import { handleFileUploadError } from '../../../shared/utils';

interface ApplicationFormProps {
  files: ApplicationFileState[];
}

// FIXME: Fuck :(
const pickFilesByType = (files: ApplicationFileState[], documentType: keyof typeof APPLICATION_DOCUMENT_TYPES) =>
  files
    .filter(file => file.documentType === documentType)
    .map(file => ({
      id: file.id,
      name: file.fileName,
      lastModified: new Date(file.uploadTime).getTime(),
      size: file.size,
    }));

export const ApplicationForm: React.FC<ApplicationFormProps> = ({ files }) => {
  const uploadDocument = useUploadSubjectDocuments();
  const deleteDocument = useDeleteSubjectDocument();

  const {
    formState: { errors },
    clearErrors,
    setValue,
  } = useFormContext<DocumentsForm>();

  const filesByType = useMemo(() => pickFilesByType(files, APPLICATION_DOCUMENT_TYPES.APPLICATION_FORM), [files]);

  useEffect(() => {
    setValue(
      APPLICATION_DOCUMENT_TYPES.APPLICATION_FORM,
      filesByType.map(file => file.id),
    );
    clearErrors(APPLICATION_DOCUMENT_TYPES.APPLICATION_FORM);
  }, [files]);

  const error = errors?.APPLICATION_FORM as unknown as FieldError;

  return (
    <div className="space-y-4">
      <span className="text-xxl font-700">Анкета</span>
      <FileLoader
        files={filesByType}
        allowedExtensions={ALLOWED_EXTENSIONS}
        allowedFileNameLength={ALLOWED_FILENAME_LENGTH}
        maxFileSize={ALLOWED_FILESIZE}
        onFileLoaded={file => uploadDocument(file, APPLICATION_DOCUMENT_TYPES.APPLICATION_FORM)}
        onFileRejected={handleFileUploadError}
        onFileDelete={async file => deleteDocument(file.id)}
        errorMessage={error?.message}
      />
    </div>
  );
};
