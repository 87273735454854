import cx from 'clsx';
import React, { forwardRef } from 'react';

interface RadioTextElement {
  value: string;
  label: string;
  counterBubble?: {
    count: number;
    color: string;
  };
}

interface RadioTextProps {
  radioElements: RadioTextElement[];
  value: string;
  uniqueName: string;
  prefixElement?: React.ReactNode;
  postfixElement?: React.ReactNode;
  labelText?: string;
  errorText?: string;
  containerClasses?: string;
  elementClasses?: string;
  activeElementClasses?: string;
}

type RadioTextPropsWithAttributes = RadioTextProps & React.InputHTMLAttributes<HTMLInputElement>;

export const RadioText = forwardRef<HTMLInputElement, RadioTextPropsWithAttributes>(
  (
    {
      prefixElement,
      postfixElement,
      errorText,
      labelText,
      containerClasses,
      disabled,
      uniqueName,
      value,
      radioElements,
      ...attributes
    },
    reference,
  ) => (
    <div>
      {labelText && (
        <div className="input-label">
          <span className="input-label-text">{labelText}</span>
        </div>
      )}
      <div
        className={cx(
          'flex items-center',
          errorText && 'border-moscow',
          disabled && 'input-disabled',
          containerClasses,
        )}
      >
        {prefixElement && <span className="input-prefixElement">{prefixElement}</span>}

        <div className="flex items-center text-m font-500 py-1 space-x-6">
          {radioElements.map(radioElement => (
            <label
              className={cx('cursor-pointer', radioElement.value === value ? 'text-primary' : 'text-seattle-100')}
              key={`${radioElement.value}_${uniqueName}`}
            >
              <input
                ref={reference}
                name={uniqueName}
                type="radio"
                className="visually-hidden"
                disabled={disabled}
                value={radioElement.value}
                {...attributes}
              />
              <div className="flex space-x-2">
                <div>
                  <span>{radioElement.label}</span>
                  <div
                    className={cx(
                      'mt-2 rounded-sm w-full h-1',
                      radioElement.value === value ? 'bg-accent' : 'bg-transparent',
                    )}
                  />
                </div>
                {radioElement.counterBubble && radioElement.counterBubble.count > 0 && (
                  <div className={cx(radioElement.counterBubble.color)}>{radioElement.counterBubble.count}</div>
                )}
              </div>
            </label>
          ))}
        </div>

        {postfixElement && <span className="input-suffixElement">{postfixElement}</span>}
      </div>
      {errorText && (
        <div className="input-error">
          <span data-cypress="inputValidation" className="input-error-text">
            {errorText}
          </span>
        </div>
      )}
    </div>
  ),
);
