import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { applicationOCRStatusState } from '../../../entities/ApplicationOCREntity/states';
import { applicationCurrentSubjectIDState } from '../../../entities/CreateApplicationEntity/states';
import { Input } from '../../../shared/components/Input';
import { Radio } from '../../../shared/components/Radio';
import { OCR_STATUSES } from '../../../shared/const';
import CheckmarkSVG from '../../../shared/icons/checkmark.svg?->TSX';
import InfoRingSVG from '../../../shared/icons/info-ring.svg?->TSX';
import WarningTriangleSVG from '../../../shared/icons/warning-tr.svg?->TSX';
import { PersonalFormFields } from '../../../shared/types';
import { dateISOStringTransformer, firstLetterUppercaseTransformer } from '../transformers';

const AlertPlain: React.FC = () => {
  const { subjectID } = useRecoilValue(applicationCurrentSubjectIDState);
  const OCRStatus = useRecoilValue(applicationOCRStatusState(subjectID));

  switch (OCRStatus) {
    //TODO: Talk with team about NOT_INFORMATION status (go to form from the draft)
    case OCR_STATUSES.NOT_INFORMATION:
      return (
        <div className="mt-8 w-full flex items-center text-m text-geneva-120 p-4 rounded space-x-4 bg-geneva-10">
          <CheckmarkSVG width={24} height={24} />
          <span>Проверьте данные, которые получилось распознать и заполните недостающие</span>
        </div>
      );

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case OCR_STATUSES.COMPLETED_WITH_WARNINGS:
      return (
        <div className="mt-8 w-full flex items-center text-m text-geneva-120 p-4 rounded space-x-4 bg-geneva-10">
          <CheckmarkSVG width={24} height={24} />
          <span>Проверьте данные, которые получилось распознать и заполните недостающие</span>
        </div>
      );

    case OCR_STATUSES.COMPLETED_WITH_ERRORS:
      return (
        <div className="mt-8 w-full flex items-center text-m text-osaka-120 p-4 rounded space-x-4 bg-osaka-10">
          <WarningTriangleSVG width={24} height={24} />
          <span>Не получилось распознать данные паспорта. Пожалуйста, введите вручную</span>
        </div>
      );

    case OCR_STATUSES.NOT_NEEDED:
      return (
        <div className="mt-8 w-full flex items-center text-m p-4 rounded space-x-4 bg-wildSand">
          <InfoRingSVG width={24} height={24} />
          <span>Данные иностранного гражданина принимаются только на русском языке</span>
        </div>
      );

    default:
      return null;
  }
};

export const PersonalForm: React.FC = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<PersonalFormFields>();

  return (
    <div className="flex flex-col" data-cypress="personalDataBlockWrapper">
      <h5 data-cypress="personalDataHeader" className="*h5">
        Личные данные
      </h5>

      <AlertPlain />

      <div className="flex mt-6 flex-col space-y-6" data-cypress="personalDataBlockWrapper">
        <Controller
          name="nameRu.lastName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              data-cypress="lastName"
              labelText="Фамилия"
              placeholder="Фамилия"
              type="text"
              value={firstLetterUppercaseTransformer.input(field.value)}
              onChange={field.onChange}
              containerClasses="h-10"
              errorText={error?.message}
            />
          )}
        />
        <Controller
          name="nameRu.firstName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              data-cypress="firstName"
              labelText="Имя"
              placeholder="Имя"
              type="text"
              value={firstLetterUppercaseTransformer.input(field.value)}
              onChange={field.onChange}
              containerClasses="h-10"
              errorText={error?.message}
            />
          )}
        />
        <Controller
          name="nameRu.middleName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              data-cypress="middleName"
              labelText="Отчество"
              placeholder="Оставьте пустым, если отчества нет"
              type="text"
              value={firstLetterUppercaseTransformer.input(field.value)}
              onChange={field.onChange}
              containerClasses="h-10"
              errorText={error?.message}
            />
          )}
        />
        <div className="flex space-x-6 children:flex-1">
          <Controller
            name="birthDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                data-cypress="birthDate"
                labelText="Дата рождения"
                type="text"
                placeholder="DD.MM.YYYY"
                containerClasses="h-10"
                value={dateISOStringTransformer.output(field.value)}
                onChange={event => field.onChange(dateISOStringTransformer.input(event.target.value))}
                errorText={error?.message}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <Radio
                data-cypress="gender"
                labelText="Пол"
                value={field.value ?? ''}
                onChange={value => {
                  field.onChange(value);
                }}
                uniqueName="gender"
                radioElements={[
                  {
                    label: 'Мужской',
                    value: 'M',
                  },
                  {
                    label: 'Женский',
                    value: 'F',
                  },
                ]}
                containerClasses="h-10"
                errorText={errors.gender?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
