/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useMountedState } from './useMountedState';

export const noop = () => {};

export interface DropAreaState {
  over: boolean;
}

export interface DropAreaBond {
  onDragOver: React.DragEventHandler;
  onDragEnter: React.DragEventHandler;
  onDragLeave: React.DragEventHandler;
  onDrop: React.DragEventHandler;
  onPaste: React.ClipboardEventHandler;
}

export interface DropAreaOptions {
  onFiles?: (files: File[], event?: any) => void;
  onText?: (text: string, event?: any) => void;
  onUri?: (url: string, event?: any) => void;
}

/*
const defaultState: DropAreaState = {
  over: false,
};
*/

const createProcess = (options: DropAreaOptions, mounted: boolean) => (dataTransfer: DataTransfer, event: any) => {
  const uri = dataTransfer.getData('text/uri-list');

  if (uri) {
    (options.onUri || noop)(uri, event);
    return;
  }

  if (dataTransfer.files && dataTransfer.files.length > 0) {
    (options.onFiles || noop)([...dataTransfer.files], event);
    return;
  }

  if (dataTransfer.items && dataTransfer.items.length > 0) {
    dataTransfer.items[0].getAsString(text => {
      if (mounted) {
        (options.onText || noop)(text, event);
      }
    });
  }
};

const createBond = (
  process: (data: DataTransfer, event: React.DragEvent | React.ClipboardEvent<Element>) => unknown,
  setOver: (isOver: boolean) => unknown,
): DropAreaBond => ({
  onDragOver: event => {
    event.preventDefault();
  },
  onDragEnter: event => {
    event.preventDefault();
    setOver(true);
  },
  onDragLeave: () => {
    setOver(false);
  },
  onDrop: event => {
    event.preventDefault();
    event.persist();
    setOver(false);
    process(event.dataTransfer, event);
  },
  onPaste: event => {
    event.persist();
    process(event.clipboardData, event);
  },
});

export const useDropArea = (options: DropAreaOptions = {}): [DropAreaBond, DropAreaState] => {
  const { onFiles, onText, onUri } = options;
  const isMounted = useMountedState();
  const [over, setOver] = useState<boolean>(false);
  const process = useMemo(() => createProcess(options, isMounted()), [onFiles, onText, onUri]);
  const bond: DropAreaBond = useMemo(() => createBond(process, setOver), [process, setOver]);

  return [bond, { over }];
};
