import { useRecoilCallback } from 'recoil';
import {
  APPLICATIONS_LIST_TABS,
  APPLICATION_DOCUMENT_TYPES,
  APPLICATION_IDENTIFY_DOCUMENT_TYPES,
  APPLICATION_STAGES,
  DOCUMENT_TYPE,
  OCR_STATUSES,
  SUBJECT_TYPES,
} from '../../shared/const';
import { ApplicationDraftResponse, DocumentFileResponse, SubjectIDInfo } from '../../shared/types';
import { fetcher } from '../../shared/utils';
import { applicationOCRStatusState } from '../ApplicationOCREntity/states';
import { applicationsPageState } from '../ApplicationsEntity/states';
import {
  applicationCurrentSubjectIDState,
  applicationEmployeeBookTypeState,
  applicationIDState,
  applicationIncomeCertificateTypeState,
  applicationPersonalDataState,
  applicationStageState,
  applicationSubjectHistoryState,
  subjectFilesState,
} from './states';
import { CreateLoanApplicationWithEmptyBorrowerResponse, UploadDocumentFileResponse } from './types';

export const useResetApplication = () =>
  useRecoilCallback(({ set, reset, refresh }) => () => {
    // STATES
    reset(applicationIDState);
    reset(applicationCurrentSubjectIDState);
    // reset(subjectFilesState);
    reset(applicationStageState);
    reset(applicationSubjectHistoryState);
  });

export const useUploadSubjectDocuments = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (file: File, documentType: keyof typeof APPLICATION_DOCUMENT_TYPES) => {
        const { subjectID } = await snapshot.getPromise(applicationCurrentSubjectIDState);
        const applicationID = await snapshot.getPromise(applicationIDState);

        if (!applicationID || !subjectID) {
          throw new Error('Не задан номер заявки');
        }

        const data = new FormData();
        data.append('file', file);
        data.append('documentType', documentType);
        data.append('borrowerId', subjectID);
        data.append('appNumber', applicationID);

        const { id, uploadTime } = await fetcher<UploadDocumentFileResponse>(`/api/ml-document/application-file`, {
          method: 'POST',
          body: data,
        });

        set(subjectFilesState(subjectID), previous => [
          ...previous,
          {
            id,
            borrowerId: subjectID,
            documentType,
            fileName: file.name,
            size: file.size,
            uploadTime,
          },
        ]);
      },
    [subjectFilesState, applicationIDState, applicationCurrentSubjectIDState],
  );

export const useDeleteSubjectDocument = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (documentId: string) => {
        const { subjectID } = await snapshot.getPromise(applicationCurrentSubjectIDState);

        await fetcher<void>(`/api/ml-document/file/${documentId}`, {
          method: 'DELETE',
        });
        set(subjectFilesState(subjectID), previous => previous.filter(file => file.id !== documentId));
      },
    [subjectFilesState, applicationCurrentSubjectIDState],
  );

export const useDeleteCoborrower = (coborrowerIDInfo: SubjectIDInfo) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        if (coborrowerIDInfo.subjectType === SUBJECT_TYPES.BORROWER) return;

        const applicationID = await snapshot.getPromise(applicationIDState);
        await fetcher<void>(
          `/api/ml-application/partners-portal/loan-applications/${applicationID}/coborrowers/${coborrowerIDInfo.subjectID}`,
          {
            method: 'DELETE',
          },
        );
        set(applicationSubjectHistoryState, previous =>
          previous.filter(subject => subject.subjectID !== coborrowerIDInfo.subjectID),
        );
      },
    [coborrowerIDInfo],
  );

interface CreateBorrowerAndApplicationIDParameters {
  documentType: keyof typeof APPLICATION_IDENTIFY_DOCUMENT_TYPES;
}

interface OpenApplicationDraftParameters {
  applicationID: string;
}

export const useCreateBorrowerIDAndApplicationID = () =>
  useRecoilCallback(({ set, snapshot }) => async ({ documentType }: CreateBorrowerAndApplicationIDParameters) => {
    const { borrowerId, loanApplicationId } = await fetcher<CreateLoanApplicationWithEmptyBorrowerResponse>(
      `/api/ml-application/partners-portal/loan-application-with-borrower`,
      {
        method: 'POST',
      },
    );

    const newSubjectInfo = { subjectID: borrowerId, subjectType: SUBJECT_TYPES.BORROWER };

    const isRussianPassport = documentType === APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT;

    set(applicationIDState, loanApplicationId);
    set(applicationCurrentSubjectIDState, newSubjectInfo);
    set(applicationSubjectHistoryState, previous => [...previous, newSubjectInfo]);
    set(applicationOCRStatusState(borrowerId), isRussianPassport ? OCR_STATUSES.NOT_STARTED : OCR_STATUSES.NOT_NEEDED);
    set(applicationPersonalDataState(borrowerId), previous => ({
      ...previous,
      identityDocument: previous.identityDocument
        ? { ...previous.identityDocument, type: documentType }
        : { type: documentType, issueDate: '', issuedBy: '', number: '', series: '', subunitCode: '' },
    }));
  });

export const useCreateCoborrowerID = () =>
  useRecoilCallback(({ set, snapshot }) => async ({ documentType }: CreateBorrowerAndApplicationIDParameters) => {
    const applicationID = await snapshot.getPromise(applicationIDState);
    const { coborrowerId } = await fetcher<{ coborrowerId: string }>(
      `/api/ml-application/partners-portal/loan-applications/${applicationID}/coborrowers`,
      { method: 'POST' },
    );

    const newSubjectInfo = { subjectID: coborrowerId, subjectType: SUBJECT_TYPES.COBORROWER };

    set(applicationCurrentSubjectIDState, newSubjectInfo);
    set(applicationSubjectHistoryState, previous => [...previous, newSubjectInfo]);

    const isRussianPassport = documentType === APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT;

    set(
      applicationOCRStatusState(coborrowerId),
      isRussianPassport ? OCR_STATUSES.NOT_STARTED : OCR_STATUSES.NOT_NEEDED,
    );
    // eslint-disable-next-line sonarjs/no-identical-functions
    set(applicationPersonalDataState(coborrowerId), previous => ({
      ...previous,
      identityDocument: previous.identityDocument
        ? { ...previous.identityDocument, type: documentType }
        : { type: documentType, issueDate: '', issuedBy: '', number: '', series: '', subunitCode: '' },
    }));
  });

export const useOpenDraftApplication = () =>
  useRecoilCallback(
    //FIXME: Eslint error
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ({ set, snapshot, refresh, reset }) =>
      async ({ applicationID }: OpenApplicationDraftParameters) => {
        if (!applicationID) {
          throw new Error('Не задан номер заявки');
        }

        set(applicationStageState, APPLICATION_STAGES.CHOOSE_SUBJECT);

        const applicationDraft = await fetcher<ApplicationDraftResponse>(
          `/api/ml-application/partners-portal/loan-applications/${applicationID}`,
        );

        const filesFromDraft = await fetcher<DocumentFileResponse[]>(
          `/api/ml-document/application-files?appNumber=${applicationID}`,
        );

        if (applicationDraft) {
          const { id: applicationIDFromAPI, borrower: subjectDrafts } = applicationDraft;

          for (const { id: subjectID, clientType: subjectType, ...subject } of subjectDrafts) {
            const draftSubjectInfo = { subjectID, subjectType };

            //TODO: Узнать, что лучше, постоянно перезапрашивать, или проверять если ли сохраненная инфа на приложении
            reset(applicationPersonalDataState(subjectID));
            reset(subjectFilesState(subjectID));

            set(applicationIDState, applicationIDFromAPI);

            if (subjectType === SUBJECT_TYPES.BORROWER) {
              /* Костыль */
              if (!subject?.identityDocument?.type) {
                throw new Error('Не задан тип документа для заемщика');
              }

              set(applicationSubjectHistoryState, previous => [draftSubjectInfo, ...previous]);
            } else {
              /* Костыль */
              if (!subject?.identityDocument?.type) {
                await fetcher<void>(
                  `/api/ml-application/partners-portal/loan-applications/${applicationID}/coborrowers/${subjectID}`,
                  {
                    method: 'DELETE',
                  },
                );

                continue;
              }

              set(applicationSubjectHistoryState, previous => [...previous, draftSubjectInfo]);
            }

            const isRussianPassport = subject.identityDocument.type === APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT;

            // set(applicationCurrentSubjectIDState, draftSubjectInfo);

            set(
              applicationOCRStatusState(subjectID),
              isRussianPassport ? OCR_STATUSES.NOT_INFORMATION : OCR_STATUSES.NOT_NEEDED,
            );

            // ISO Date to DD.MM.YYYY
            set(applicationPersonalDataState(subjectID), {
              ...subject,
              birthDate:
                subject.birthDate.split('-')[2] + subject.birthDate.split('-')[1] + subject.birthDate.split('-')[0],
              identityDocument: {
                ...subject.identityDocument,
                issueDate:
                  subject.identityDocument.issueDate.split('-')[2] +
                  subject.identityDocument.issueDate.split('-')[1] +
                  subject.identityDocument.issueDate.split('-')[0],
              },
            });
          }

          const userFiles: Record<string, string[]> = {};

          for (const { borrowerId: fileSubjectID, ...file } of filesFromDraft) {
            userFiles[fileSubjectID] = userFiles[fileSubjectID]
              ? [...userFiles[fileSubjectID], file.documentType]
              : [file.documentType];
            set(subjectFilesState(fileSubjectID), previous => [...previous, file]);
          }

          for (const [userID, documentTypes] of Object.entries(userFiles)) {
            if (!documentTypes.includes(APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE)) {
              set(applicationIncomeCertificateTypeState(userID), DOCUMENT_TYPE.DIGITAL);
            }
            if (!documentTypes.includes(APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK)) {
              set(applicationEmployeeBookTypeState(userID), DOCUMENT_TYPE.DIGITAL);
            }
          }
        }
      },
  );
