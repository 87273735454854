import { selector } from 'recoil';
import { RECOIL_KEYS } from '../../shared/const';
import { fetcher, formatPhoneNumber, prettyFullNameWithInitials, prettyUserAccess } from '../../shared/utils';
import { ActiveUserResponse } from './types';

export const activeEmployeeKeys = {
  Employee: 'Сотрудник',
  AccessLevel: 'Уровень доступа',
} as const;

export interface ActiveEmployeeRows extends Pick<ActiveUserResponse, 'id'> {
  Employee: {
    fullName: string;
    phone: string;
  };
  AccessLevel: ReturnType<typeof prettyUserAccess>;
}

export const getActiveUsersQuery = selector({
  key: RECOIL_KEYS.ACTIVE_EMPLOYEES_QUERY,
  get: async ({ get }) => {
    const activeEmployees = await fetcher<ActiveUserResponse[] | []>('/api/ml-partner-users/active');

    const activeEmployeeRows = activeEmployees.map(employee => ({
      id: employee.id,
      Employee: {
        fullName: prettyFullNameWithInitials(employee),
        phone: formatPhoneNumber(employee.mobilePhone),
      },
      AccessLevel: prettyUserAccess(employee.role),
    })) as ActiveEmployeeRows[];

    const lastUpdate = Date.now();

    return {
      activeEmployeeRows,
      lastUpdate,
    };
  },
});
