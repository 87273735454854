import cx, { ClassValue } from 'clsx';
import React from 'react';
import { colors } from '../theme';

interface SpinnerProps {
  position?: 'relative' | 'absolute';
  size?: number;
  containerStyles?: ClassValue;
  color?: keyof typeof colors;
}

export const Spinner: React.FC<SpinnerProps> = props => {
  const { size = 20, position = 'relative', color = 'primary', containerStyles } = props;

  const positionStyles = position === 'absolute' ? 'absolute inset-0 z-5 m-auto' : 'relative';

  return (
    <div
      className={cx('bg-cover bg-no-repeat align-top inline-flex', positionStyles, containerStyles)}
      style={{ width: size, height: size }}
    >
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle
          strokeMiterlimit={10}
          strokeDasharray="85 195"
          startOffset={0}
          strokeLinecap="round"
          fill="none"
          className={`origin-1/2 stroke-${color} animate-spinner`}
          strokeWidth="12px"
          r="44"
          cx="50"
          cy="50"
        />
      </svg>
    </div>
  );
};

// Spinner.displayName = 'Spinner';
// Spinner.defaultProps = {
//   size: 'm',
//   position: 'relative',
//   color: 'primary',
// };
