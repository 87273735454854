import { createPortal } from 'react-dom';
import { MODAL_NODE } from '../const';

interface ModalProps {
  children: React.ReactNode;
  handleClose: () => void;
  isOpen: boolean;
}

export const Modal: React.FC<ModalProps> = ({ children, isOpen, handleClose }) => {
  if (!isOpen) {
    return null;
  }

  return createPortal(
    <>
      <div
        onClick={event => event.target === event.currentTarget && handleClose()}
        className="fixed h-full w-full top-0 bg-primary bg-opacity-70 grid place-items-center z-4"
      >
        {children}
      </div>
    </>,
    MODAL_NODE,
  );
};
