import cx from 'clsx';
import { forwardRef } from 'react';

type RadioCirclePropsWithAttributes = React.InputHTMLAttributes<HTMLInputElement>;

export const RadioCircle = forwardRef<HTMLInputElement, RadioCirclePropsWithAttributes>(
  ({ className, ...attributes }, reference) => (
    <label
      className={cx(
        'relative cursor-pointer flex items-center justify-center rounded-full border-2  w-5 h-5',
        attributes.checked ? 'bg-accent border-transparent' : 'border-seattle-30',
      )}
    >
      <input type="radio" ref={reference} className="visually-hidden" {...attributes} />
      {attributes.checked && <div className="w-2 h-2 bg-primary rounded-full" />}
    </label>
  ),
);
