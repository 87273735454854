/* eslint-disable sonarjs/no-duplicate-string */
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { z as validate } from 'zod';
import { isApplicationValidQuery } from '../../entities/CreateApplicationEntity/queries';
import {
  applicationCurrentSubjectIDState,
  applicationPersonalDataState,
  applicationStageState,
  subjectFilesState,
} from '../../entities/CreateApplicationEntity/states';
import { APPLICATION_DOCUMENT_TYPES, APPLICATION_STAGES, DOCUMENT_TYPE } from '../../shared/const';
import BackSVG from '../../shared/icons/back.svg?->TSX';
import DocumentsBG from '../../shared/images/Documents-BG.svg';
import { prettyFullNameWithInitials } from '../../shared/utils';
import { ApplicationForm } from './DocumentsParts/ApplicationForm';
import { EmploymentRecordBook } from './DocumentsParts/EmploymentRecordBook';
import { IncomeCertificate } from './DocumentsParts/IncomeCertificate';
import { Other } from './DocumentsParts/Other';
import { Passport } from './DocumentsParts/Passport';

export interface DocumentsForm {
  [APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK]: string[] | typeof DOCUMENT_TYPE.DIGITAL;
  [APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE]: string[] | typeof DOCUMENT_TYPE.DIGITAL;
  [APPLICATION_DOCUMENT_TYPES.APPLICATION_FORM]: string[];
  [APPLICATION_DOCUMENT_TYPES.OTHER]: string[];
  //* Because its same
  PASSPORT: string[];
}

export const DocumentFormSchema = validate.object({
  [APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK]: validate
    .array(validate.string().uuid())
    .nonempty('Этот документ необходим для подачи заявки')
    .or(validate.literal(DOCUMENT_TYPE.DIGITAL)),
  [APPLICATION_DOCUMENT_TYPES.APPLICATION_FORM]: validate
    .array(validate.string().uuid())
    .nonempty('Этот документ необходим для подачи заявки'),
  [APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE]: validate
    .array(validate.string().uuid())
    .nonempty('Этот документ необходим для подачи заявки')
    .or(validate.literal(DOCUMENT_TYPE.DIGITAL)),
  //* Because its same
  PASSPORT: validate.array(validate.string().uuid()).nonempty('Этот документ необходим для подачи заявки'),
  [APPLICATION_DOCUMENT_TYPES.OTHER]: validate.array(validate.string().uuid()),
});

export const ApplicationDocumentsWidget = () => {
  const { subjectID, subjectType } = useRecoilValue(applicationCurrentSubjectIDState);
  const files = useRecoilValue(subjectFilesState(subjectID));
  const subjectFormState = useRecoilValue(applicationPersonalDataState(subjectID));

  const helpText = useMemo(() => {
    if (subjectType === 'BORROWER') {
      return 'Прикрепите документы заемщика';
    }
    if (subjectType === 'COBORROWER') {
      return 'Прикрепите документы созаемщика';
    }
  }, [subjectType]);

  const methods = useForm<DocumentsForm>({
    mode: 'all',
    resolver: zodResolver(DocumentFormSchema),
  });

  const subjectFullNameText = useMemo(
    () => `Документы — ${prettyFullNameWithInitials(subjectFormState.nameRu)}`,
    [subjectFormState],
  );

  const goToApplication = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationStageState, APPLICATION_STAGES.PERSONAL_DATA);
      },
    [],
  );

  const goToChooseSubject = useRecoilCallback(
    ({ set, refresh }) =>
      () => {
        refresh(isApplicationValidQuery);
        set(applicationStageState, APPLICATION_STAGES.CHOOSE_SUBJECT);
      },
    [],
  );

  return (
    <div className="pt-6 px-12 overflow-auto h-full flex flex-col">
      <div className="mb-2 flex min-h-9 items-center">
        <Link className="flex h-full items-center space-x-2 text-primary" to="/applications">
          <BackSVG className="mb-0.5" width={20} height={20} />
          <span data-cypress="all-applications" className="font-500 text-m">
            Все заявки
          </span>
        </Link>
      </div>

      <div data-cypress="application-header" className="flex items-center space-x-4">
        <h1 className="*h1">Новая заявка</h1>
      </div>
      <div className="space-y-4 mt-6 mb-25">
        <div
          style={{ background: `url(${DocumentsBG}) no-repeat bottom right` }}
          className="flex flex-col pt-4 space-y-2 h-25"
        >
          <span data-cypress="documents-pretty-fio" className="text-xxl font-700">
            {subjectFullNameText}
          </span>
          <span className="text-m text-seattle-100">{helpText}</span>
        </div>

        <div className="space-y-4 children:(shadow bg-white rounded p-6)">
          <FormProvider {...methods}>
            <Passport files={files} />

            <EmploymentRecordBook files={files} />

            <IncomeCertificate files={files} />

            <ApplicationForm files={files} />

            <Other files={files} />
          </FormProvider>
        </div>
      </div>

      <div className="fixed bottom-0 right-0 w-[calc(100%-252px)] py-2 px-12 bg-white">
        <div className="flex space-x-4 children:(w-full flex items-center justify-center font-500 px-5 rounded text-m text-primary h-10)">
          <button
            data-cypress="back"
            className="border-2 border-seattle-30 hover:border-primary"
            type="button"
            onClick={goToApplication}
          >
            Назад
          </button>

          <button
            data-cypress="continue"
            className="bg-accent hover:bg-onAccent"
            onClick={methods.handleSubmit(goToChooseSubject, console.debug)}
            type="button"
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
  );
};
