import { Suspense, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useResetPagination } from '../entities/ApplicationsEntity/controllers';
import {
  applicationNeedRefinementCountState,
  applicationsSearchState,
  applicationsSelectedTabState,
} from '../entities/ApplicationsEntity/states';
import { Loading } from '../features';
import { ValueOfObject } from '../shared';
import { Input } from '../shared/components/Input';
import { RadioText } from '../shared/components/RadioText';
import { APPLICATIONS_LIST_TABS } from '../shared/const';
import SearchIcon from '../shared/icons/search.svg';
import { debounce } from '../shared/utils/debounce';
import { ApplicationsTableWidget } from '../widgets/ApplicationsTableWidget';

export const ApplicationListWidget = () => {
  const [selectedTab, setSelectedTab] = useRecoilState(applicationsSelectedTabState);
  const needRefinementCount = useRecoilValue(applicationNeedRefinementCountState);
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useRecoilState(applicationsSearchState(selectedTab));
  const resetPagination = useResetPagination();

  const handleSearch = useCallback(
    debounce((value: string) => {
      resetPagination();

      setSearchQuery(value);
    }, 500),
    [setSearchQuery],
  );

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  useEffect(() => {
    setSearch(searchQuery);
  }, [selectedTab]);

  return (
    <div className="flex flex-col bg-white shadow mt-6 mb-10 rounded p-6">
      <div className="mb-6">
        <RadioText
          radioElements={[
            { label: APPLICATIONS_LIST_TABS.ALL, value: APPLICATIONS_LIST_TABS.ALL },
            {
              label: APPLICATIONS_LIST_TABS.NEED_REFINEMENT,
              value: APPLICATIONS_LIST_TABS.NEED_REFINEMENT,
              counterBubble: {
                count: needRefinementCount,
                color: 'flex mb-3 py-.5 px-1.5 rounded-full align-center justify-center bg-moscow-100 text-white',
              },
            },
            { label: APPLICATIONS_LIST_TABS.DRAFTS, value: APPLICATIONS_LIST_TABS.DRAFTS },
          ]}
          onClick={event => setSelectedTab(event.currentTarget.value as ValueOfObject<typeof APPLICATIONS_LIST_TABS>)}
          value={selectedTab}
          uniqueName="applicationsListVariants"
          data-cypress="need-refinment-tab"
        />
      </div>
      <Input
        data-cypress="search-field"
        containerClasses="*input-l"
        placeholder="Поиск по ФИО клиента, номеру телефона или номеру заявки"
        value={search}
        onChange={event => setSearch(event.target.value)}
        prefixElement={<div className="w-5 h-5" style={{ background: `url(${SearchIcon}) no-repeat center center` }} />}
      />
      <div className="mt-4">
        <Suspense fallback={<Loading fullScreen />}>
          <ApplicationsTableWidget />
        </Suspense>
      </div>
    </div>
  );
};
