import cx from 'clsx';
import React, { forwardRef } from 'react';

interface InputProps {
  prefixElement?: React.ReactNode;
  postfixElement?: React.ReactNode;
  labelText?: string;
  errorText?: string;
  containerClasses?: string;
}

export type InputPropsWithAttributes = InputProps & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputPropsWithAttributes>(
  ({ prefixElement, postfixElement, errorText, labelText, containerClasses, disabled, ...attributes }, reference) => (
    <label>
      {labelText && (
        <div className="input-label">
          <span className="input-label-text">{labelText}</span>
        </div>
      )}
      <div
        className={cx('input-container', errorText && 'border-moscow', disabled && 'input-disabled', containerClasses)}
      >
        {prefixElement && <span className="input-prefixElement">{prefixElement}</span>}
        <input ref={reference} className="input" disabled={disabled} {...attributes} />
        {postfixElement && <span className="input-suffixElement">{postfixElement}</span>}
      </div>
      {errorText && (
        <div className="input-error">
          <span data-cypress="inputValidation" className="input-error-text">
            {errorText}
          </span>
        </div>
      )}
    </label>
  ),
);
