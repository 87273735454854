/* eslint-disable sonarjs/no-duplicate-string */
import cx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useResetPagination } from '../entities/ApplicationsEntity/controllers';
import { applicationKeys, getApplicationsBySelectedTabQuery } from '../entities/ApplicationsEntity/queries';
import {
  applicationSortState,
  applicationsPageState,
  applicationsSelectedTabState,
} from '../entities/ApplicationsEntity/states';
import { useOpenDraftApplication } from '../entities/CreateApplicationEntity/controllers';
import { allowedApplicationListActionsQuery } from '../entities/EmployeeProfileEntity/queries';
import { applicationsToReassignStore, startReassignApplicationStore } from '../entities/ReassignApplications/states';
import { Pagination } from '../features/Pagination';
import { Checkbox } from '../shared/components/Checkbox';
import { APPLICATIONS_LIST_TABS } from '../shared/const';
import ArrowsLeftRightSVG from '../shared/icons/arrows-left-right.svg?->TSX';
import BackSVG from '../shared/icons/back.svg?->TSX';
import NotFoundApplicationsBG from '../shared/images/NotFoundApplications-BG.svg';
import { ReassignApplicationsWidget } from './ReassignApplicationsWidget';

const ColoredStatusBadge = ({ status }: { status: string }) => {
  if (status === 'Отказано' || status === 'Нужна доработка' || status === 'Отменена') {
    return <span className="*badge-m bg-nice-30">{status}</span>;
  } else if (status === 'Заявка одобрена') {
    return <span className="*badge-m bg-miami-30">{status}</span>;
  }

  return <span className="*badge-m bg-seattle-10">{status}</span>;
};

export const ApplicationsTableWidget: React.FC = () => {
  const { id: employeeID = '' } = useParams();

  const getAllowedActions = useRecoilValue(allowedApplicationListActionsQuery(employeeID));

  const startReassignApplication = useSetRecoilState(startReassignApplicationStore);

  const [applicationsToReassign, setApplicationsToReassign] = useRecoilState(applicationsToReassignStore);

  const [tableRowWidth, setTableRowWidth] = useState<string[]>([
    'minmax(200px, 1fr)',
    'minmax(200px, 1fr)',
    'minmax(200px, 1fr)',
    'minmax(200px, 1fr)',
    'minmax(200px, 1fr)',
  ]);

  useEffect(() => {
    if (getAllowedActions?.reassign) {
      //* Add Checkbox column
      setTableRowWidth(previous => ['52px', ...previous]);
    }
  }, [getAllowedActions]);

  const tableRowTemplateColumns = useMemo(() => tableRowWidth.join(' '), [tableRowWidth]);

  const resetPagination = useResetPagination();

  const selectedTab = useRecoilValue(applicationsSelectedTabState);

  const { applicationRows: selectedTableRows, paging, lastUpdate } = useRecoilValue(getApplicationsBySelectedTabQuery);
  const setApplicationPage = useSetRecoilState(applicationsPageState(selectedTab));
  const [sortedFields, setSortedFields] = useRecoilState(applicationSortState(selectedTab));
  const navigate = useNavigate();
  const openDraft = useOpenDraftApplication();

  const refresh = useRecoilRefresher_UNSTABLE(getApplicationsBySelectedTabQuery);

  useEffect(() => {
    if (Date.now() - lastUpdate > 1500) {
      refresh();
      resetPagination();
    }

    return resetPagination;
  }, []);

  if (selectedTableRows.length > 0) {
    return (
      <>
        <div className="overflow-auto">
          <div
            style={{ gridTemplateColumns: tableRowTemplateColumns }}
            className="grid text-s text-seattle-60 items-center children:(select-none)"
          >
            {getAllowedActions.reassign && (
              <div className="flex justify-center p-2 h-10 border-seattle-10 border-b-1">
                <Checkbox
                  checked={selectedTableRows.length === applicationsToReassign.length}
                  onChange={event => {
                    if (!event.target.checked) {
                      setApplicationsToReassign([]);
                    } else {
                      setApplicationsToReassign(selectedTableRows.map(row => row.id));
                    }
                  }}
                />
              </div>
            )}
            {Object.entries(applicationKeys).map(([key, value]) =>
              value === 'Дата создания' ? (
                <button
                  className="flex items-center p-2 h-10 border-seattle-10 border-b-1 space-x-2 hover:(bg-wildSand)"
                  type="button"
                  key={key}
                  onClick={() =>
                    setSortedFields(previous => ({
                      ...previous,
                      createdTime: previous.createdTime === 'DESC' ? 'ASC' : 'DESC',
                    }))
                  }
                >
                  <div>{value}</div>
                  <div
                    className={cx(
                      'text-oslo-120 transform',
                      sortedFields.createdTime === 'DESC' && 'rotate-270',
                      sortedFields.createdTime === 'ASC' && 'rotate-90',
                    )}
                  >
                    <BackSVG width={16} height={16} />
                  </div>
                </button>
              ) : (
                <div className="flex items-center p-2 h-10 border-seattle-10 border-b-1" key={key}>
                  {value}
                </div>
              ),
            )}
          </div>
          {selectedTableRows.map(({ id, Status, Employee, CreationDate, ResponsibleEmployee, StatusChangedDate }) => (
            <div
              data-cypress="application-table-row"
              style={{ gridTemplateColumns: tableRowTemplateColumns }}
              className="grid children:(h-20 select-none w-full border-seattle-10 border-b-1 cursor-pointer hover:bg-seattle-10)
"
              onClick={() => {
                if (Status === 'Черновик') {
                  navigate('/applications/create');
                  openDraft({ applicationID: id });
                } else {
                  navigate(`/applications/info/${id}`);
                }
              }}
              key={id}
            >
              {getAllowedActions.reassign && (
                <div onClick={event => event.stopPropagation()} className="py-[10px] px-4 flex items-center">
                  <Checkbox
                    checked={applicationsToReassign.includes(id)}
                    onChange={event => {
                      if (!event.target.checked) {
                        setApplicationsToReassign(previous => previous.filter(row => row !== id));
                      } else {
                        setApplicationsToReassign(previous => [...previous, id]);
                      }
                    }}
                  />
                </div>
              )}

              <div className="flex flex-col justify-center p-2">
                <span data-cypress="borrower-name" className="font-500">
                  {Employee.fullName}
                </span>
                <span data-cypress="emp-phone" className="text-seattle-100">
                  {Employee.phone}
                </span>
              </div>
              <div data-cypress="status" className="flex p-2 items-center">
                <ColoredStatusBadge status={Status} />
              </div>
              <div data-cypress="resp-emp" className="flex p-2 items-center">
                {ResponsibleEmployee}
              </div>
              <div data-cypress="creation-date" className="flex p-2 items-center">
                {CreationDate}
              </div>
              <div data-cypress="changed-date" className="flex p-2 items-center">
                {StatusChangedDate}
              </div>
            </div>
          ))}
        </div>
        {paging.totalPages > 1 && (
          <Pagination
            containerClasses="mt-6 w-full flex items-center justify-center"
            total={paging.totalElements}
            current={paging.pageNumber}
            pageSize={10}
            onChange={requestedPage => setApplicationPage(requestedPage)}
          />
        )}

        {getAllowedActions.reassign && applicationsToReassign.length > 0 && (
          <div className="fixed bottom-0 right-0 w-[calc(100%-252px)] py-2 px-12 bg-white shadow-xl">
            <div className="h-10 text-m text-primary font-500 flex items-center space-x-8">
              <div>Выбрано {applicationsToReassign.length}</div>
              <button
                type="button"
                className="text-m text-primary font-500 flex items-center space-x-2"
                onClick={() => startReassignApplication(true)}
              >
                <ArrowsLeftRightSVG width={20} height={20} />
                <div>Переназначить заявки</div>
              </button>
            </div>
          </div>
        )}

        {getAllowedActions.reassign && <ReassignApplicationsWidget />}
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col h-full mt-6 mb-10 items-center justify-center">
        <div
          style={{ background: `url(${NotFoundApplicationsBG}) no-repeat 70px top` }}
          className="text-xxl font-700 pt-[91px]"
        >
          Заявки не найдены
        </div>
        <span className="*typo-sm text-seattle-100 mt-2">Попробуйте изменить критерии поиска</span>
      </div>
      {paging.totalPages > 1 && (
        <Pagination
          containerClasses="mt-6 w-full flex items-center justify-center"
          total={paging.totalElements}
          current={paging.pageNumber}
          pageSize={10}
          onChange={requestedPage => setApplicationPage(requestedPage)}
        />
      )}
    </>
  );
};
