import { toast } from 'react-toastify';
import { useRecoilCallback } from 'recoil';
import { ApplicationInfoResponseWithPagination } from '../../shared/types';
import { fetcher } from '../../shared/utils';
import { userProfileQuery } from '../ProfileEntity/queries';
import {
  applicationsToReassignStore,
  startReassignApplicationStore,
  successReassignCallbackStore,
} from '../ReassignApplications/states';
import { employeeInfoQuery } from './queries';
import { isProfileDeletedStore, selectedEmployeeIDProfileStore } from './states';

const handleDelete = async (selectedEmployeeID: string) => {
  try {
    await fetcher(`/api/ml-partner-users/${selectedEmployeeID}`, {
      method: 'DELETE',
    });
    toast.success('Сотрудник успешно удален');
  } catch {
    toast.error('Не удалось удалить сотрудника');
  }
};

export const useDeleteEmployeeProfile = () =>
  useRecoilCallback(({ snapshot, set, reset }) => async () => {
    const selectedEmployeeID = await snapshot.getPromise(selectedEmployeeIDProfileStore);

    if (!selectedEmployeeID) {
      throw new Error('Unexpected Error In Application');
    }

    const requestURL = new URL(
      `/api/ml-application/partners-portal/loan-applications/assigned/${selectedEmployeeID}`,
      window.location.origin,
    );

    requestURL.searchParams.append('page', '0');
    requestURL.searchParams.append('size', '10000');

    const {
      result,
      paging: { totalElements },
    } = await fetcher<ApplicationInfoResponseWithPagination>(requestURL.href);

    const { whatICanDoWith } = await snapshot.getPromise(userProfileQuery);
    const { rawInfo } = await snapshot.getPromise(employeeInfoQuery(selectedEmployeeID));

    const allowedActions = whatICanDoWith(rawInfo.role);

    const deleteSteps = async () => {
      await handleDelete(selectedEmployeeID);
      reset(selectedEmployeeIDProfileStore);
      set(isProfileDeletedStore, true);
    };

    if (totalElements === 0) {
      await deleteSteps();

      return;
    }

    //* If user to delete has applications and user who deleting is don't have permissions to reassign show warn modal and return
    if (!allowedActions.reassign) {
      toast.warn('С текущей ролью вы не можете удалить данного пользователя, обратитесь в тех поддержку.');

      return;
    }

    const idToReassign = result.map(({ id }) => id);

    set(applicationsToReassignStore, idToReassign);
    set(successReassignCallbackStore, () => deleteSteps);
    set(startReassignApplicationStore, true);
  });
