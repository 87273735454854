import cx from 'clsx';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  useDeleteApplicationDocument,
  useSendElaboration,
  useUploadApplicationDocument,
} from '../entities/ApplicationInfoEntity/controllers';
import { applicationFilesLoaderQuery, applicationInfoQuery } from '../entities/ApplicationInfoEntity/quiries';
import { applicationNeedRefinementCountState } from '../entities/ApplicationsEntity/states';
import { FileLoader } from '../features';
import { Input } from '../shared/components/Input';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_FILENAME_LENGTH,
  ALLOWED_FILESIZE,
  APPLICATION_DOCUMENT_TYPES,
} from '../shared/const';
import BackSVG from '../shared/icons/back.svg?->TSX';
import DocumentErrorSVG from '../shared/icons/doc-error.svg?->TSX';
import DocumentReissueSVG from '../shared/icons/doc-reissue.svg?->TSX';
import DocumentSentSVG from '../shared/icons/doc-sent.svg?->TSX';
import DocumentVerifiedSVG from '../shared/icons/doc-verified.svg?->TSX';
import { APPLICATION_STATUS_CODES } from '../shared/types';
import { handleFileUploadError } from '../shared/utils';

//FIXME: Duplicate
const ColoredStatusBadge = ({ status }: { status: string }) => {
  if (status === 'Отказано' || status === 'Нужна доработка' || status === 'Отменена') {
    return <span className="*badge-m bg-nice-30">{status}</span>;
  } else if (status === 'Заявка одобрена') {
    return <span className="*badge-m bg-miami-30">{status}</span>;
  }

  return <span className="*badge-m bg-seattle-10">{status}</span>;
};

const StatusIcon = ({ statusCode, active }: { statusCode: keyof typeof APPLICATION_STATUS_CODES; active: boolean }) => {
  const NOT_ACTIVE_COLOR = 'text-seattle-60';

  if (
    statusCode === APPLICATION_STATUS_CODES.ApplicationApproved ||
    statusCode === APPLICATION_STATUS_CODES.LoanIssued
  ) {
    return (
      <div className={cx(active ? 'text-oslo-120' : NOT_ACTIVE_COLOR)}>
        <DocumentVerifiedSVG width={24} height={24} />
      </div>
    );
  }

  if (statusCode === APPLICATION_STATUS_CODES.ApplicationDenied || statusCode === APPLICATION_STATUS_CODES.Canceled) {
    return (
      <div className={cx(active ? 'text-moscow-100' : NOT_ACTIVE_COLOR)}>
        <DocumentErrorSVG width={24} height={24} />
      </div>
    );
  }

  if (statusCode === APPLICATION_STATUS_CODES.Elaboration) {
    return (
      <div className={cx(active ? 'text-moscow-100' : NOT_ACTIVE_COLOR)}>
        <DocumentReissueSVG width={24} height={24} />
      </div>
    );
  }

  if (statusCode === APPLICATION_STATUS_CODES.SentToBank || statusCode === APPLICATION_STATUS_CODES.ProcessingByBank) {
    return (
      <div className={cx(active ? 'text-primary' : NOT_ACTIVE_COLOR)}>
        <DocumentSentSVG width={24} height={24} />
      </div>
    );
  }

  return null;
};

export const ApplicationInfo: React.FC = () => {
  const { id: applicationID } = useParams();

  if (!applicationID) {
    throw new Error('Unexpected applicationID');
  }

  const [loading, setIsLoading] = useState<boolean>(false);

  const { clientPhone, client, comment, history, bonusType, birthDate, responsibleEmployee, status, lastUpdate } =
    useRecoilValue(applicationInfoQuery(applicationID));
  const files = useRecoilValue(applicationFilesLoaderQuery(applicationID));

  const uploadDocument = useUploadApplicationDocument(applicationID);
  const deleteDocument = useDeleteApplicationDocument(applicationID);
  const sendElaboration = useSendElaboration(applicationID);
  const [inputValue, setInputValue] = useState<string>('');

  const refresh = useRecoilRefresher_UNSTABLE(applicationInfoQuery(applicationID));
  const resetBadge = useResetRecoilState(applicationNeedRefinementCountState);

  useEffect(() => {
    if (Date.now() - lastUpdate > 500) {
      refresh();
    }
  }, []);

  return (
    <div className="pt-6 px-12 overflow-auto h-full flex flex-col">
      <div className="mb-2 flex min-h-9 items-center">
        <Link className="flex h-full items-center space-x-2 text-primary" to="/applications">
          <BackSVG className="mb-0.5" width={20} height={20} />
          <span data-cypress="all-applications" className="font-500 text-m">
            Все заявки
          </span>
        </Link>
      </div>

      <div className="flex items-center space-x-4">
        <h1 className="*h1">Заявка {applicationID}</h1>
        <ColoredStatusBadge status={status.nameRu} />
      </div>

      <div className="flex flex-col bg-white shadow mt-6 rounded p-6">
        <div className="flex flex-col text-m space-y-6">
          <ul className="grid grid-cols-applicationInfoTable m-0 items-center">
            <li className="flex text-seattle-100">Клиент</li>
            <li data-cypress="client-fio" className="flex">
              {client}
            </li>
          </ul>
          <ul className="grid grid-cols-applicationInfoTable m-0 items-center">
            <li className="flex text-seattle-100">Телефон</li>
            <li data-cypress="client-phone" className="flex">
              {clientPhone}
            </li>
          </ul>
          <ul className="grid grid-cols-applicationInfoTable m-0 items-center">
            <li className="flex text-seattle-100">Дата рождения</li>
            <li data-cypress="client-birthdate" className="flex">
              {birthDate}
            </li>
          </ul>
          <ul className="grid grid-cols-applicationInfoTable m-0 items-center">
            <li className="flex text-seattle-100">Ответственный сотрудник</li>
            <li data-cypress="responsible-employee" className="flex">
              {responsibleEmployee}
            </li>
          </ul>
          <ul className="grid grid-cols-applicationInfoTable m-0 items-center">
            <li className="flex text-seattle-100">Тип бонуса</li>
            <li data-cypress="bonus-type" className="flex">
              {bonusType}
            </li>
          </ul>
          <ul className="grid grid-cols-applicationInfoTable m-0 items-center">
            <li className="flex text-seattle-100">Комментарий</li>
            <li data-cypress="comment" className="flex">
              {comment}
            </li>
          </ul>
        </div>
      </div>

      <span className="flex mt-6 text-s text-seattle-60 uppercase font-700 tracking-2px">История заявки</span>

      <div className="mt-2 mb-17">
        {history.map((historyItem, index) =>
          index === 0 && status.code === 'Elaboration' && historyItem.status.code === 'Elaboration' ? (
            <div key={historyItem.changedAt} className="flex flex-col bg-white shadow mt-6 rounded px-6 py-4">
              <div className="flex items-center">
                <div className="flex items-center space-x-3">
                  <StatusIcon active={index === 0} statusCode={historyItem.status.code} />
                  <div className="flex text-l font-700">{historyItem.status.nameRu}</div>
                </div>
                <span className="flex text-m ml-auto text-seattle-100">{`${historyItem.changedAt}`}</span>
              </div>
              <div className="space-y-4 mt-6">
                {historyItem.comment && (
                  <div className="flex flex-col bg-seattle-5 shadow rounded px-6 py-4">
                    <span className="text-s text-seattle-100">
                      {historyItem.status.nameRu === 'Отправлена в банк'
                        ? 'Комментарий'
                        : 'Комментарий сотрудника банка'}
                    </span>
                    <span className="mt-1 text-m">{historyItem.comment}</span>
                  </div>
                )}
                <FileLoader
                  files={files}
                  allowedExtensions={ALLOWED_EXTENSIONS}
                  maxFileSize={ALLOWED_FILESIZE}
                  allowedFileNameLength={ALLOWED_FILENAME_LENGTH}
                  onFileLoaded={file => uploadDocument(file, APPLICATION_DOCUMENT_TYPES.OTHER)}
                  onFileRejected={handleFileUploadError}
                  onFileDelete={async file => deleteDocument(file.id)}
                />
                <div className="flex items-end w-full space-x-4">
                  <div className="w-full">
                    <Input
                      data-cypress="return-comment"
                      value={inputValue}
                      disabled={loading}
                      onChange={event => setInputValue(event.target.value)}
                      containerClasses="h-10"
                      placeholder="Комментарий"
                      labelText="Комментарий (необязательно)"
                    />
                  </div>
                  <div className="flex flex-none w-40 flex space-x-4 children:(w-full flex items-center justify-center font-500 px-5 rounded text-m h-10)">
                    <button
                      className="bg-accent text-primary hover:bg-onAccent disabled:(bg-seattle-10 text-london-120 cursor-not-allowed)"
                      type="button"
                      disabled={loading || (files.length === 0 && inputValue.length === 0)}
                      onClick={async () => {
                        try {
                          setIsLoading(true);
                          await sendElaboration(inputValue);
                          toast.success('Заявка отправлена в банк', {
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                          });
                          resetBadge();
                          refresh();
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    >
                      Отправить в банк
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div key={historyItem.changedAt} className="flex flex-col bg-white shadow mt-6 rounded px-6 py-4">
              <div className="flex items-center">
                <div className="flex items-center space-x-3">
                  <StatusIcon active={index === 0} statusCode={historyItem.status.code} />
                  <div data-cypress="application-status" className="flex text-l font-700">
                    {historyItem.status.nameRu}
                  </div>
                </div>
                <span
                  data-cypress="changed-by-when"
                  className="flex text-m ml-auto text-seattle-100"
                >{`${historyItem.changedAt} • ${historyItem.changedBy}`}</span>
              </div>
              {historyItem.comment && (
                <div className="flex flex-col bg-seattle-5 shadow mt-6 rounded px-6 py-4">
                  <span className="text-s text-seattle-100">
                    {historyItem.status.nameRu === 'Отправлена в банк' ? 'Комментарий' : 'Комментарий сотрудника банка'}
                  </span>
                  <span data-cypress="bank-comment" className="mt-1 text-m">
                    {historyItem.comment}
                  </span>
                </div>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};
