import { useCallback, useEffect, useRef } from 'react';

export function useMountedState(): () => boolean {
  const mountedReference = useRef<boolean>(false);
  const get = useCallback(() => mountedReference.current, []);

  useEffect(() => {
    mountedReference.current = true;

    return () => {
      mountedReference.current = false;
    };
  }, []);

  return get;
}
