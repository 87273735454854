import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../features';

export const Login: React.FC = () => {
  const { initialized, keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (keycloak && keycloak.token) {
      navigate('/applications');
    } else if (initialized && keycloak && !keycloak.token) {
      keycloak.login();
    }
  }, [initialized, keycloak]);

  return <Loading fullScreen />;
};
