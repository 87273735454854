import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { getEmployeesQuery } from '../entities/EmployeeEntity/queries';
import { userProfileQuery } from '../entities/ProfileEntity/queries';
// import { Input } from '../shared/components/Input';
// import SearchIcon from '../shared/icons/search.svg';
import WorkSpaceBG from '../shared/images/WorkSpace-BG.svg';
import { EmployeesTableWidget } from '../widgets/EmployeesTableWidget';

//TODO: pick column content width and append it to the column header width

const EmptyEmployeesScreen = () => (
  <div className="flex flex-col h-full mt-6 mb-10 bg-white items-center justify-center shadow rounded">
    <div style={{ background: `url(${WorkSpaceBG}) no-repeat top center` }} className="text-xxl font-700 pt-[127px]">
      Сотрудников пока нет
    </div>
    <span className="*typo-sm text-seattle-100 mt-2">Здесь появятся сотрудники вашей компании</span>
  </div>
);

export const EmployeeList: React.FC = () => {
  const currentProfile = useRecoilValue(userProfileQuery);
  const { employeeRows, lastUpdate } = useRecoilValue(getEmployeesQuery);
  const navigate = useNavigate();
  const refresh = useRecoilRefresher_UNSTABLE(getEmployeesQuery);

  if (!currentProfile.isAdmin) {
    return <Navigate to="/applications" replace />;
  }

  useEffect(() => {
    if (Date.now() - lastUpdate > 500) {
      refresh();
    }
  }, []);

  //TODO: Make a custom checkbox component
  //TODO: Make a custom table component reference -> https://mui.com/components/tables/
  //TODO: Fix table container (scroll)

  return (
    <div className="pt-6 overflow-auto px-12 h-screen flex flex-col">
      <h1 className="*h1">Сотрудники</h1>
      {employeeRows.length === 0 ? (
        <EmptyEmployeesScreen />
      ) : (
        <div className="flex flex-col bg-white shadow mt-6 mb-10 rounded p-6">
          {/* <Input
            containerClasses="*input-l"
            placeholder="Поиск"
            prefixElement={
              <div className="w-5 h-5" style={{ background: `url(${SearchIcon}) no-repeat center center` }} />
            }
          /> */}
          <div className="mt-6">
            <EmployeesTableWidget />
          </div>
        </div>
      )}
    </div>
  );
};
