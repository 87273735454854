import { toast } from 'react-toastify';
import { FETCH_ERROR_MESSAGES, UNHANDLED_ERROR_MESSAGE } from '../errors';

export const getMessageWhenError = (status: number) =>
  FETCH_ERROR_MESSAGES[status as keyof typeof FETCH_ERROR_MESSAGES] ?? UNHANDLED_ERROR_MESSAGE;

export type Fetcher = <T = unknown>(input: RequestInfo, init?: RequestInit) => Promise<T>;

export const fetcher: Fetcher = async (...fetchParameters) => {
  const defaultHeaders = {
    // 'Content-Type': 'application/json;charset=utf-8',
    Authorization: `Bearer ${window.sessionStorage.getItem('token') ?? ''}`,
  };

  const [URL, { headers, body, ...options } = { headers: defaultHeaders, method: 'GET', body: null }] = fetchParameters;

  const response = await fetch(URL, { headers: { ...defaultHeaders, ...headers }, body, ...options });

  await new Promise(resolve => setTimeout(resolve, 1000));

  if (!response.ok) {
    toast.error(getMessageWhenError(response.status));
    throw new Error(getMessageWhenError(response.status));
  }

  if (response.headers.get('content-type')?.includes('application/json')) {
    console.log(response.bodyUsed);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.json();
  }
};
