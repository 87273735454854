/* eslint-disable @typescript-eslint/no-explicit-any */
import { useKeycloak } from '@react-keycloak/web';
import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { KeyCloakStore } from '../entities/ProfileEntity/states';
import { Loading } from '../features';
import { ErrorHandler } from '../pages/ErrorHandler';
import { SideBar } from '../shared/components';
// import './reset.css'
import './preflight.css';

//className="first:last:(flex mx-auto)"

export const LayoutController: React.FC = () => {
  const { keycloak } = useKeycloak();
  const setKeyCloakStore = useSetRecoilState(KeyCloakStore);

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setKeyCloakStore(keycloak as any);
    }
  }, [keycloak.authenticated]);

  if (keycloak && !keycloak.authenticated) {
    keycloak.login();
  }

  return (
    <Suspense fallback={<Loading fullScreen />}>
      <ErrorHandler>
        <div className="flex h-screen font-ALSHauss">
          <SideBar />
          <main className="w-[calc(100%-252px)] h-full bg-grayWhite">
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </ErrorHandler>
    </Suspense>
  );
};
