import { Spinner } from '../shared/components/Spinner';

interface LoadingProps {
  fullScreen?: boolean;
  size?: number;
}

export const Loading: React.FC<LoadingProps> = ({ fullScreen = false, size = 32 }) => (
  <div className={`flex items-center justify-center w-full ${fullScreen ? 'h-screen' : 'h-full'}`}>
    <Spinner size={32} />
  </div>
);
