export const colors = {
  accent: '#FEE600',
  grayWhite: '#F8F8F8',
  onAccent: '#FED500',
  primary: '#2B2D33',
  onPrimary: '#000000',
  wildSand: '#F4F4F4',
  white: '#FFFFFF',
  transparent: 'transparent',
  seattle: {
    1: '#FBFBFB',
    5: '#F8F8F8',
    10: '#E9EAEA',
    30: '#D5D5D6',
    60: '#AAABAD',
    DEFAULT: '#808185',
    100: '#808185',
    120: '#55575C',
    140: '#404247',
  },
  oslo: {
    10: '#E6F6F8',
    30: '#B3E4EB',
    60: '#66C9D7',
    DEFAULT: '#00A5BC',
    100: '#00A5BC',
    120: '#00809C',
    140: '#04607D',
  },
  miami: {
    10: '#E7F8F4',
    30: '#CFF1E9',
    100: '#5ED1B6',
    DEFAULT: '#5ED1B6',
  },
  moscow: {
    10: '#FDEEEE',
    30: '#FACED1',
    60: '#F27C83',
    100: '#EE505A',
    DEFAULT: '#EE505A',
    120: '#C74952',
  },
  osaka: {
    10: '#FEF3EC',
    30: '#FDDAC7',
    60: '#FAA373',
    100: '#F88545',
    DEFAULT: '#F88545',
    120: '#CB662F',
  },
  geneva: {
    10: '#E8F7F4',
    30: '#BFE9DF',
    60: '#52C5A9',
    100: '#19B28D',
    DEFAULT: '#19B28D',
    120: '#008E7D',
  },
  dubai: {
    10: '#FFF4E8',
    30: '#FEE9D1',
    100: '#FCB664',
    DEFAULT: '#FCB664',
  },
  paris: {
    10: '#F0F0FE',
    30: '#E1E0FE',
    100: '#9A99FB',
    DEFAULT: '#9A99FB',
  },
  nice: {
    30: '#FEE0E0',
  },
  london: {
    120: '#2B2D33',
  },
};

export const sizes = {
  spinner: {
    size: {
      xs: {
        width: '12px',
        height: '12px',
      },
      s: {
        width: '16px',
        height: '16px',
      },
      m: {
        width: '20px',
        height: '20px',
      },
      l: {
        width: '24px',
        height: '24px',
      },
      xl: {
        width: '32px',
        height: '32px',
      },
      xxl: {
        width: '32px',
        height: '32px',
      },
    },
  },
};

export const InputStyles = {
  'input-container': {
    '@apply': 'flex items-center h-full w-full border border-seattle-30 rounded px-3 transition-all duration-200',
    '&:hover, &:focus-within': {
      'box-shadow': 'inset 0px 0px 0px 1px #2B2D33',
      '@apply': 'border-primary',
    },
  },
  'input-label': {
    '@apply': 'mb-1',
  },
  'input-label-text': {
    '@apply': 'text-s text-seattle-100',
  },
  'input-error': {
    '@apply': 'mt-1',
  },
  'input-error-text': {
    '@apply': 'text-s text-moscow',
  },
  'input-disabled': {
    '@apply': 'bg-wildSand border-wildSand cursor-not-allowed',
    '&:hover, &:focus-within': {
      '@apply': 'border-wildSand shadow-none',
    },
  },
  input: {
    '@apply': 'outline-none appearance-none bg-transparent h-full w-full m-0',
    '&::placeholder': {
      '@apply': 'text-seattle-60',
    },
    '&:disabled': {
      '@apply': 'cursor-not-allowed',
    },
  },
  'input-prefixElement': {
    '@apply': 'mr-3',
  },
  'input-suffixElement': {
    '@apply': 'ml-3',
  },
};

export const SelectStyles = {
  select: {},
};

export const ButtonStyles = {
  'button-outline': {},

  button: {
    '@apply': '',
  },
};

export const TableStyles = {
  'rf-table': {
    '@apply': 'block',
  },
  'rf-table-row': {
    '@apply': 'grid grid-cols-test border-b-1 h-20 border-seattle-10',
  },
  'rf-table-header': {
    '@apply': 'text-seattle-60 text-s h-10 font-500',
  },
  // 'table-header': {
  //   '@apply': 'h-10 text-s font-500 text-seattle-60',
  // },
  // 'table-row': {
  //   '@apply': 'h-20 text-m',
  // },
};
