import { atomFamily, selector } from 'recoil';
import { ApplicationFileState } from '../../shared/types';
import { applicationInfoQuery } from './quiries';

export const applicationFilesState = atomFamily<ApplicationFileState[], string>({
  key: 'applicationFilesState',
  default: applicationID =>
    selector({
      key: 'applicationFilesState',
      get: ({ get }) => {
        const { files } = get(applicationInfoQuery(applicationID));
        return files;
      },
    }),
});
