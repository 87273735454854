import cx from 'clsx';
import { forwardRef, useEffect, useMemo, useState } from 'react';

interface SelectOptions {
  label: string;
  value: string | number;
}

interface SelectProps {
  options: SelectOptions[];
  search?: string;
  onSearch?: (search: string) => void;
  onSelect: (value: string | number) => void;
  prefixElement?: React.ReactNode;
  postfixElement?: React.ReactNode;
  labelText?: string;
  containerClasses?: string;
  errorText?: string;
}

type SelectPropsWithAttributes = SelectProps & React.InputHTMLAttributes<HTMLDivElement>;

//TODO: https://react-select-search.com/?path=/story/single-select--search-with-empty-message-renderer

export const Select = forwardRef<HTMLDivElement, SelectPropsWithAttributes>(
  (
    {
      options,
      onSelect,
      labelText,
      errorText,
      prefixElement,
      postfixElement,
      onSearch,
      disabled,
      containerClasses,
      value,
      ...attributes
    },
    reference,
  ) => {
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    const [search, setSearch] = useState('');

    const searchedOptions = useMemo(() => {
      if (!search || onSearch) {
        return options;
      }

      return options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()));
    }, [options, search]);

    const selectedOption = useMemo(() => options.find(option => option.value === value), [options, value]);

    useEffect(() => {
      onSearch?.(search);
    }, [search]);

    useEffect(() => {
      if (inputFocused && !onSearch) {
        setSearch('');
      } else {
        setSearch(selectedOption?.label ?? '');
      }
    }, [selectedOption, inputFocused]);

    return (
      <label className="relative">
        {labelText && (
          <div className="input-label">
            <span className="input-label-text">{labelText}</span>
          </div>
        )}
        <div
          className={cx(
            'input-container',
            errorText && 'border-moscow',
            disabled && 'input-disabled',
            containerClasses,
          )}
        >
          {prefixElement && <span className="input-prefixElement">{prefixElement}</span>}

          <input
            className={cx('input', !inputFocused && 'cursor-pointer')}
            value={search}
            onChange={event => setSearch(event.target.value)}
            disabled={disabled}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            {...attributes}
          />

          {postfixElement && <span className="input-suffixElement">{postfixElement}</span>}
        </div>
        {errorText && (
          <div className="input-error">
            <span className="input-error-text">{errorText}</span>
          </div>
        )}
        {inputFocused && (
          <ul
            role="listbox"
            className="absolute bg-white top-14 left-0 py-2 px-0 rounded shadow-xl overflow-auto max-h-75 z-100 list-none w-full"
          >
            {searchedOptions.map(option => (
              <li role="listitem" key={`${option.value}${option.label}`}>
                <button
                  tabIndex={0}
                  className="min-h-10 w-full text-left py-2 px-4 text-m cursor-pointer hover:(bg-grayWhite)"
                  role="option"
                  onMouseDown={event => {
                    onSelect(option.value);
                  }}
                  type="button"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        )}
      </label>
    );
  },
);
