/* eslint-disable sonarjs/no-duplicate-string */
import { toast } from 'react-toastify';
import { FileLoaderProps } from '../../features';
import { RAIFFEISEN_REALTY_ADMIN_ROLES, RAIFFEISEN_REALTY_USER_ROLES } from '../const';
import { EmployeeInfoResponse } from '../types';

type PhonePrettifier = (phone?: string | null) => string | '-';

/*
    input: +79998887766
    output: +7 (999) 888-77-66
*/
export const formatPhoneNumber: PhonePrettifier = phone => {
  const phoneNumber = phone?.replace(/\D/g, '');
  if (phoneNumber?.length !== 11) {
    return phoneNumber ?? '-';
  }
  const firstPart = phoneNumber.slice(0, 1);
  const secondPart = phoneNumber.slice(1, 4);
  const thirdPart = phoneNumber.slice(4, 7);
  const fourthPart = phoneNumber.slice(7, 9);
  const fifthPart = phoneNumber.slice(9, 11);
  return `+${firstPart} (${secondPart}) ${thirdPart}-${fourthPart}-${fifthPart}`;
};

type EmailPrettifier = (email?: string | null) => string | '-';

/*
  input: email@email.email
  output: email@email.email
*/
export const formatEmail: EmailPrettifier = email => {
  if (!email) {
    return '-';
  }
  return email.toLocaleLowerCase();
};

interface NamePrettifiers {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  emptyMessage?: string;
}

type NamePrettifier = (props?: NamePrettifiers | null) => string | 'ФИО не указано';

export const prettyFullName: NamePrettifier = props =>
  props && props.lastName?.trim()
    ? [props.firstName, props.middleName, props.lastName].filter(Boolean).join(' ')
    : props?.emptyMessage ?? 'ФИО не указано';

// input: 'Иванов Иван Иванович'; output: 'Иванов И.И.'
export const prettyFullNameWithInitials: NamePrettifier = props => {
  if (!props) return 'ФИО не указано';

  const middleNameFirstLetter = props.middleName ? props.middleName[0] + '.' : '';
  const firstNameFirstLetter = props.firstName ? props.firstName[0] + '.' : '';

  if (!props.lastName?.trim()) {
    return props.emptyMessage ?? 'ФИО не указано';
  }

  return `${props.lastName} ${firstNameFirstLetter}${middleNameFirstLetter}`;
};

const numberTwoDigitFormatter = (number: number) => {
  if (Number.isNaN(number)) return `${number}`;

  if (number < 10) {
    return `0${number}`;
  }
  return number.toString();
};

//input: date; output: 23.08.2020
export const formatDateWithoutTime = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return [day, month, year].map(number => numberTwoDigitFormatter(number)).join('.');
};

export const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${numberTwoDigitFormatter(hours)}:${numberTwoDigitFormatter(minutes)}`;
};

// Function to convert Date to 20.04.2022 в 09:45
export const formatDateWithPointToTime = (date?: string | null) => {
  if (!date) return '-';

  const dateObject = new Date(date);

  const dateWithoutTime = formatDateWithoutTime(dateObject);
  const dateTime = formatTime(dateObject);

  return `${dateWithoutTime} в ${dateTime}`;
};

//input: date; output: 23.08.2020, 16:04:34
export const formatDate = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const dateWithoutTime = formatDateWithoutTime(date);

  return `${dateWithoutTime}, ${[hours, minutes, seconds].map(number => numberTwoDigitFormatter(number)).join(':')}`;
};

export const prettyUserAccess = (role: EmployeeInfoResponse['role']) => {
  switch (role) {
    case RAIFFEISEN_REALTY_ADMIN_ROLES.Administrator1:
      return 'Главный Администратор';
    case RAIFFEISEN_REALTY_ADMIN_ROLES.Administrator2:
      return 'Администратор';
    case RAIFFEISEN_REALTY_USER_ROLES.Employee:
      return 'Пользователь';
    default:
      throw new Error('Unknown Access Role');
  }
};

export const prettyUserStatus = (status: EmployeeInfoResponse['state']) => {
  switch (status) {
    case 'ACTIVE':
      return 'Активен';
    case 'ON_CONFIRM':
      return 'На подтверждении';
    case 'INACTIVE':
      return 'Неактивен';
    default:
      throw new Error('Unknown Status');
  }
};

export const handleFileUploadError: FileLoaderProps['onFileRejected'] = (file, reason) => {
  /* WIKI: https://confluence.raiffeisen.ru/pages/viewpage.action?pageId=306426575 */
  switch (reason) {
    case 'DUPLICATE':
      return toast.error(`Файл ${file.name} уже загружен`, { pauseOnHover: false });
    case 'WRONG_EXTENSION':
      return toast.error(`Файл ${file.name} не подходит по расширению`, { pauseOnHover: false });
    case 'BIG_SIZE':
      return toast.error(`Файл ${file.name} слишком большой`, { pauseOnHover: false });
    case 'FILE_NAME_LENGTH_LESS':
      return toast.error(
        `Имя файла ${file.name} имеет длину меньше 1 символов (имя файла без расширения должно иметь длину от 1 до 64 символов)`,
        { pauseOnHover: false },
      );
    case 'FILE_NAME_LENGTH_OVER':
      return toast.error(`Имя файла ${file.name} больше 64 символов`, { pauseOnHover: false });
    default:
      return toast.error(`Произошла неожиданная ошибка. Обратитесь в техподдержку`, {
        pauseOnHover: false,
      });
  }
};

export * from './fetcher';
export * from './useDropArea';
