import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Loading } from '../features';

export const Logout: React.FC = () => {
  const { keycloak } = useKeycloak();
  const [searchParameters, setSearchParameters] = useSearchParams();

  useEffect(() => {
    if (searchParameters.has('redirectToIndex')) {
      window.location.href = '/';
    } else {
      setSearchParameters({ redirectToIndex: 'YES' });
      keycloak.logout();
    }
  }, [keycloak]);

  return <Loading fullScreen />;
};
