import { useRecoilValue } from 'recoil';
import { userProfileQuery } from '../entities/ProfileEntity/queries';
import MailIcon from '../shared/icons/mail.svg?->TSX';
import PhoneIcon from '../shared/icons/phone.svg?->TSX';

export const Profile: React.FC = () => {
  const currentProfile = useRecoilValue(userProfileQuery);

  return (
    <div className="mt-8 mx-12">
      <div className="p-6 flex flex-col w-full font-ALSHauss bg-white shadow rounded">
        <div className="flex flex-col space-y-4">
          <span className="*h1">{currentProfile.formattedFullName}</span>
          <div className="flex space-x-4">
            <PhoneIcon width={22} height={22} color="#AAABAD" />
            <span className="*typo-sm">{currentProfile.formattedPhoneNumber}</span>
          </div>
          <div className="flex space-x-4">
            <MailIcon width={22} height={22} color="#AAABAD" />
            <span className="*typo-sm">{currentProfile.formattedEmail}</span>
          </div>
        </div>

        <button
          className="mt-10 bg-primary *button disabled:bg-seattle-60 disabled:cursor-not-allowed"
          disabled
          type="button"
        >
          Редактировать
        </button>
      </div>
    </div>
  );
};
