import { atom, atomFamily, selector } from 'recoil';
import { ValueOfObject } from '../../shared';
import { APPLICATIONS_LIST_TABS, RECOIL_KEYS } from '../../shared/const';
import { ApplicationInfoResponse, ApplicationInfoResponseWithPagination } from '../../shared/types';
import { URL_LIST } from '../../shared/URLs';
import { fetcher } from '../../shared/utils';
import { selectedEmployeeIDProfileStore } from '../EmployeeProfileEntity/states';

export const applicationsSelectedTabState = atom<ValueOfObject<typeof APPLICATIONS_LIST_TABS>>({
  key: RECOIL_KEYS.APPLICATIONS_SELECTED_TAB_STORE,
  default: APPLICATIONS_LIST_TABS.ALL,
});

export const applicationsSearchState = atomFamily<string, ValueOfObject<typeof APPLICATIONS_LIST_TABS>>({
  key: RECOIL_KEYS.APPLICATIONS_SEARCH_STORE,
  default: '',
});

export const applicationsPageState = atomFamily<number, ValueOfObject<typeof APPLICATIONS_LIST_TABS>>({
  key: RECOIL_KEYS.APPLICATIONS_PAGE_STORE,
  default: 1,
});

export const applicationNeedRefinementCountState = atom<number>({
  key: RECOIL_KEYS.APPLICATION_NEED_REFINEMENT_STORE,
  default: selector({
    key: RECOIL_KEYS.APPLICATION_NEED_REFINEMENT_STORE__DEFAULT,
    get: async ({ get }) => {
      const requestURL = new URL(URL_LIST.LOAN_APPLICATIONS_GET_URL);
      const selectedEmployeeID = get(selectedEmployeeIDProfileStore);

      if (selectedEmployeeID) {
        requestURL.pathname = `/api/ml-application/partners-portal/loan-applications/assigned/${selectedEmployeeID}`;
      }

      requestURL.searchParams.append('status', 'Elaboration');
      requestURL.searchParams.append('page', '0');
      requestURL.searchParams.append('size', '1');

      const {
        paging: { totalElements },
      } = await fetcher<ApplicationInfoResponseWithPagination>(requestURL.href);

      return totalElements;
    },
  }),
});

type ApplicationSortState<T = ApplicationInfoResponse> = {
  [K in keyof T]?: 'ASC' | 'DESC';
};

export const applicationSortState = atomFamily<ApplicationSortState, ValueOfObject<typeof APPLICATIONS_LIST_TABS>>({
  key: RECOIL_KEYS.APPLICATION_SORT_STAGE_STORE,
  default: {
    createdTime: 'DESC',
  },
});
