import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import 'virtual:fonts.css';
import 'virtual:windi-devtools';
import 'virtual:windi.css';
import { fakeKeyCloakInstants, keycloak } from './app/keycloak';
import { RouteController } from './app/Routes';
import { Loading } from './features';
// import './UI-Kit/reset.css';

const container = document.querySelector('#root') as Element;
const root = createRoot(container);

const keyCloakInitialOptions = {
  checkLoginIframe: true,
  enableLogging: false,
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  silentCheckSsoFallback: true,
  pkceMethod: 'S256',
};

const trowsErrorIfNoToken = () => {
  throw new Error('No Token!');
};

const isDevelopmentMode = import.meta.env.DEV && import.meta.env.MODE !== 'PROXY';

export const keyCloakToUse = isDevelopmentMode ? fakeKeyCloakInstants : keycloak;

const eventLogger = (event: unknown, error: unknown) => {
  // eslint-disable-next-line no-console
  console.log('onKeycloakEvent', event, error);
};

if (isDevelopmentMode && !window.sessionStorage.getItem('token')) {
  window.sessionStorage.setItem(
    'token',
    'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpZW1aU2txY3dYTTlEMlNQbC1tSVZTVDhvdkVLS1NQMkFZaTJFR3BmNGtBIn0.eyJleHAiOjE2NDkxNTA0NzYsImlhdCI6MTY0OTE0OTU3NiwiYXV0aF90aW1lIjoxNjQ5MTQ3Mzg1LCJqdGkiOiIxODY5ZjM3Ni0xYzgyLTQzYjctYjgxMC01NmNhNzNmMDE1MTUiLCJpc3MiOiJodHRwczovL3MtbXNrLXYtbWxwLWFzMS5yYWlmZmVpc2VuLnJ1Ojk4NDMvYXV0aC9yZWFsbXMvb3JpZ2luIiwiYXVkIjpbInBhcnRuZXJzLWFwaSIsImFjY291bnQiLCJwYXJ0bmVycy1hcGkgb2xkIl0sInN1YiI6IjJiY2I5MmVlLTk2YzUtNDk0Ni1iOTVmLTFkZGZjMWM2ZGI2ZCIsInR5cCI6IkJlYXJlciIsImF6cCI6InBhcnRuZXJzIiwibm9uY2UiOiIyMzJmYjdlYy0yMjA1LTQzMjgtOTVmMC00NTgwNzA5NWI3NDYiLCJzZXNzaW9uX3N0YXRlIjoiNmZmYzBhYWEtYzlmYi00NDc2LWI0NTgtM2NmMGFmMTYzMGJhIiwiYWNyIjoiMCIsImFsbG93ZWQtb3JpZ2lucyI6WyIqIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicGFydG5lcnMtYXBpIjp7InJvbGVzIjpbIkFkbWluaXN0cmF0b3IyIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX0sInBhcnRuZXJzLWFwaSBvbGQiOnsicm9sZXMiOlsiQWRtaW5pc3RyYXRvcjIiXX19LCJzY29wZSI6Im9wZW5pZCBwaG9uZSBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZSI6Iis3MTIzNDU2Nzg5MiIsIm5hbWUiOiLQkNC00LzQuNC9IDIg0YDQsNC90LPQsCIsImxhc3RfbmFtZSI6ItCQ0LTQvNC40L0gMiDRgNCw0L3Qs9CwIiwiZ3JvdXBzIjpbIi9SRE1fMTAwOTY0NjU0L1JETV8yNDc2NjI3MzAyIl0sImF0dHJpYnV0ZXMiOnsicGFydG5lcl9ib251c190eXBlcyI6W3siY29kZSI6IlBSRUZFUkVOQ0UiLCJuYW1lIjoi0J_RgNC10YTQtdGA0LXQvdGG0LjRjyDQv9C-INGB0YLQsNCy0LrQtSJ9XSwiaG9sZGluZ19yZG1faWQiOiJSRE1fMTAwOTY0NjU0IiwicGFydG5lcl9yZG1faWQiOiJSRE1fMjQ3NjYyNzMwMiJ9LCJtaWRkbGVfbmFtZSI6ItCk0LAiLCJmaXJzdF9uYW1lIjoiIiwiZW1haWwiOiJmYWtlLTJAZmFrZS5pdCIsInVzZXJuYW1lIjoidWEyIn0.V9jmTMtyd46_9jvAH7qrF3N6H4liu9Uzpvyzuq-hCrIY8dHrvyXduZm8zvGRym1FxNqo1PXgV90IIVQJ8q9ZShqNZxVXjA7o5ubyse4bPjrRTGVgTIUQxZoMIjxKOiFWWy-RBpJESyKDQk5Xto9id6-vdccYa5KGQLlhaQ-XMbjlPFXZYoYnDszJQO6pOCFXOW0OKzxah09HSLSSJ968ngMj6KAIqDFMTsww2XUf2JkBcQurfEnIjSkltI1em66Lr1TY1ucq8pU8Ra_-0tG61_p5aPEdncKjuS63m-B_X6hLxULv5hNVYzWhmfJnGMIFR28LwmQThGC88foAlC-OLA',
  );
}

function RecoilDebugObserver(): null {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

root.render(
  <ReactKeycloakProvider
    authClient={keyCloakToUse}
    initOptions={keyCloakInitialOptions}
    onEvent={eventLogger}
    LoadingComponent={!isDevelopmentMode ? <Loading fullScreen /> : undefined}
    onTokens={token => (token.token ? window.sessionStorage.setItem('token', token.token) : trowsErrorIfNoToken())}
  >
    {/* <StrictMode> */}
    <RecoilRoot>
      <RecoilDebugObserver />
      <BrowserRouter>
        <RouteController />
        <ToastContainer />
      </BrowserRouter>
    </RecoilRoot>
    {/* </StrictMode> */}
  </ReactKeycloakProvider>,
);
