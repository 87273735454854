/* eslint-disable sonarjs/no-duplicate-string */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { z as validate } from 'zod';
import { employeeInfoQuery } from '../entities/EmployeeProfileEntity/queries';
import { userProfileQuery } from '../entities/ProfileEntity/queries';
import { Input } from '../shared/components/Input';
import { RadioCircle } from '../shared/components/RadioCircle';
import { RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE, RAIFFEISEN_REALTY_USER_ROLES } from '../shared/const';
import BackSVG from '../shared/icons/back.svg?->TSX';
import { fetcher } from '../shared/utils';
import { firstLetterUppercaseTransformer, mobilePhoneTransformer } from '../widgets/ApplicationFormWidget/transformers';

interface EditEmployeeForm {
  lastName: string;
  middleName: string;
  firstName: string;
  mobilePhone: string;
  email: string;
  role: typeof RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE | typeof RAIFFEISEN_REALTY_USER_ROLES.Employee;
}

const EditEmployeeFormSchema = validate.object({
  firstName: validate
    .string()
    .min(1, 'Поле обязательное для заполнения')
    .max(50, 'Разрешенная длина поля от 1 до 50 символов')
    .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы'),
  middleName: validate
    .string()
    .min(1, 'Поле обязательное для заполнения')
    .max(50, 'Разрешенная длина поля от 1 до 50 символов')
    .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы')
    .or(validate.string().length(0)),
  lastName: validate
    .string()
    .min(1, 'Поле обязательное для заполнения')
    .max(50, 'Разрешенная длина поля от 1 до 50 символов')
    .regex(/^[ .ЁА-яё-]+$/, 'Недопустимые символы'),
  mobilePhone: validate.string().regex(/^7(\d{10})$/, {
    message: 'Недопустимый формат номера телефона для РФ',
  }),
  email: validate
    .string()
    .email({ message: 'Недопустимый формат e-mail' })
    .regex(
      /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/,
      'Недопустимые символы',
    ),
  role: validate.enum([RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE, RAIFFEISEN_REALTY_USER_ROLES.Employee]),
});

export const EditEmployee = () => {
  const { id: employeeID } = useParams();

  if (!employeeID) {
    throw new Error('Unexpected employeeID');
  }

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { lastNameWithInitials, rawInfo, employee, lastUpdate } = useRecoilValue(employeeInfoQuery(employeeID));
  const refresh = useRecoilRefresher_UNSTABLE(employeeInfoQuery(employeeID));

  const { whatICanDoWith } = useRecoilValue(userProfileQuery);

  const allowedActions = useMemo(() => whatICanDoWith(rawInfo.role), [whatICanDoWith]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditEmployeeForm>({
    mode: 'all',
    resolver: zodResolver(EditEmployeeFormSchema),
    defaultValues: {
      firstName: rawInfo.firstName ?? '',
      middleName: rawInfo.middleName ?? '',
      lastName: rawInfo.lastName ?? '',
      mobilePhone: rawInfo.mobilePhone ?? '',
      email: rawInfo.email ?? '',
      role: rawInfo.role as typeof RAIFFEISEN_REALTY_USER_ROLES.Employee | typeof RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE,
    },
  });

  useEffect(() => {
    if (!allowedActions.edit) {
      navigate(`/employees/profile/${employeeID}`);
    }
  }, [allowedActions, navigate]);

  useEffect(() => {
    if (Date.now() - lastUpdate > 5000) {
      refresh();
    }
  }, []);

  return (
    <>
      <div className="pt-6 overflow-auto px-12 max-h-full h-full flex mb-4 flex-col">
        <div className="mb-2 flex min-h-9 items-center">
          <Link className="flex h-full items-center space-x-2 text-primary" to={`/employees/profile/${employeeID}`}>
            <BackSVG className="mb-0.5" width={20} height={20} />
            <span className="font-500 text-m">{lastNameWithInitials}</span>
          </Link>
        </div>
        <div className="flex items-center space-x-4">
          <h1 className="*h1">Редактировать данные</h1>
        </div>
        <div className="flex space-x-6">
          <div className="flex flex-col w-[500px] bg-white shadow mt-6 rounded p-6 space-y-6">
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState: { error } }) => (
                <Input
                  containerClasses="h-10"
                  labelText="Фамилия"
                  placeholder="Фамилия"
                  type="text"
                  value={firstLetterUppercaseTransformer.input(field.value)}
                  onChange={field.onChange}
                  errorText={error?.message}
                  disabled={loading}
                />
              )}
            />
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState: { error } }) => (
                <Input
                  containerClasses="h-10"
                  labelText="Имя"
                  placeholder="Имя"
                  type="text"
                  value={firstLetterUppercaseTransformer.input(field.value)}
                  onChange={field.onChange}
                  errorText={error?.message}
                  disabled={loading}
                />
              )}
            />
            <Controller
              control={control}
              name="middleName"
              render={({ field, fieldState: { error } }) => (
                <Input
                  containerClasses="h-10"
                  labelText="Отчество"
                  placeholder="Оставьте пустым, если отчества нет"
                  type="text"
                  value={firstLetterUppercaseTransformer.input(field.value)}
                  onChange={field.onChange}
                  errorText={error?.message}
                  disabled={loading}
                />
              )}
            />

            <Input
              labelText="Электронная почта"
              placeholder="Адрес электронной почты"
              type="email"
              containerClasses="h-10"
              errorText={errors.email?.message}
              disabled={loading}
              {...register('email')}
            />

            <Controller
              control={control}
              name="mobilePhone"
              render={({ field, fieldState: { error } }) => (
                <Input
                  labelText="Номер телефона"
                  type="tel"
                  containerClasses="h-10"
                  value={mobilePhoneTransformer.output(field.value)}
                  onChange={event => field.onChange(mobilePhoneTransformer.input(event.target.value))}
                  disabled={loading}
                  errorText={error?.message}
                />
              )}
            />
          </div>

          <div className="flex h-max flex-col w-[500px] bg-white shadow mt-6 rounded p-6 space-y-6">
            <div className="text-xxl font-700 text-primary">Уровень доступа</div>

            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <>
                  <div className="space-y-2">
                    <label className="flex cursor-pointer items-center space-x-2">
                      <RadioCircle
                        checked={field.value === RAIFFEISEN_REALTY_USER_ROLES.Employee}
                        value={RAIFFEISEN_REALTY_USER_ROLES.Employee}
                        onChange={() => field.onChange(RAIFFEISEN_REALTY_USER_ROLES.Employee)}
                        name="edit-employee-access"
                      />
                      <div className="text-l font-500 text-primary">Обычный</div>
                    </label>
                    <div className="text-m text-seattle-120">
                      Может создавать, просматривать, редактировать свои заявки
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="flex cursor-pointer items-center space-x-2">
                      <RadioCircle
                        checked={field.value === RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE}
                        value={RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE}
                        onChange={() => field.onChange(RAIFFEISEN_REALTY_MAIN_ADMIN_ROLE)}
                        name="edit-employee-access"
                      />
                      <div className="text-l font-500 text-primary">Администратор</div>
                    </label>
                    <div className="text-m text-seattle-120">
                      Может создавать, просматривать, редактировать свои заявки и заявки сотрудников
                    </div>
                    <div className="text-m text-seattle-120">
                      Может приглашать, редактировать, удалять любых сотрудников, кроме администраторов
                    </div>
                  </div>
                </>
              )}
            />
          </div>
        </div>
        <div className="fixed bottom-0 right-0 w-[calc(100%-252px)] py-2 px-12 bg-white">
          <div className="flex space-x-4 max-w-[500px] children:(w-full flex items-center justify-center font-500 px-5 rounded text-m h-10)">
            <button
              data-cypress="continue"
              className="bg-accent text-primary hover:bg-onAccent disabled:(bg-seattle-10 text-london-120 cursor-not-allowed)"
              type="button"
              disabled={loading}
              onClick={handleSubmit(async data => {
                try {
                  setLoading(true);
                  await fetcher(`/api/ml-partner-users/${employeeID}`, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: {
                      'Content-Type': 'application/json;charset=utf-8',
                    },
                  });
                  navigate('/employees');
                } finally {
                  setLoading(false);
                }
              }, console.debug)}
            >
              Продолжить
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
