/* eslint-disable sonarjs/no-duplicate-string */
import { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { countriesListQuery, subjectPassportTypeQuery } from '../../../entities/CreateApplicationEntity/queries';
import { Input } from '../../../shared/components/Input';
import { Select } from '../../../shared/components/Select';
import { APPLICATION_IDENTIFY_DOCUMENT_TYPES } from '../../../shared/const';
import { SubjectResource } from '../../../shared/types';
import { dateISOStringTransformer, subunitCodeTransformer } from '../transformers';

export const PassportForm: React.FC = () => {
  const {
    formState: { errors },
    control,
    setFocus,
    setValue,
    register,
  } = useFormContext<SubjectResource>();

  const subjectPassportType = useRecoilValue(subjectPassportTypeQuery);
  const countriesList = useRecoilValue(countriesListQuery);

  const isRussianPassport = subjectPassportType === APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT;

  return (
    <div className="mt-8 flex flex-col">
      <h5 data-cypress="passport-header" className="*h5">
        Паспортные данные
      </h5>

      <div className="flex mt-4 flex-col space-y-6">
        <Controller
          name="citizenship"
          control={control}
          render={({ field }) => (
            <Select
              data-cypress="citizenship"
              options={countriesList}
              labelText="Страна гражданства"
              containerClasses="h-10"
              placeholder="Выберите из списка"
              disabled={isRussianPassport}
              value={field.value ?? ''}
              onSelect={element => {
                field.onChange(element);
              }}
              errorText={errors.citizenship?.message}
            />
          )}
        />

        <div className="flex space-x-6 children:(flex-1 flex-shrink-0)">
          <Input
            data-cypress="series"
            labelText="Серия"
            type="text"
            size={4}
            maxLength={4}
            minLength={4}
            required
            containerClasses="h-10"
            onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
              const value = event.clipboardData.getData('Text');

              if (value.length > 4) {
                setValue('identityDocument.series', value.slice(0, 4));
                setValue('identityDocument.number', value.slice(4));
                setFocus('identityDocument.number');
              }
            }}
            errorText={errors.identityDocument?.series?.message}
            {...register('identityDocument.series', {
              onChange: (event: SyntheticEvent<InputEvent> & { target: { value: string } }) => {
                console.log(event.target.value);
                if (event.target.value.length === 4) {
                  setFocus('identityDocument.number');
                }
              },
            })}
          />

          <Input
            data-cypress="number"
            labelText="Номер"
            containerClasses="h-10"
            type="text"
            maxLength={isRussianPassport ? 6 : 10}
            errorText={errors.identityDocument?.number?.message}
            {...register('identityDocument.number')}
          />

          {isRussianPassport && (
            <Controller
              control={control}
              name="identityDocument.subunitCode"
              render={({ field }) => (
                <Input
                  data-cypress="unitCode"
                  labelText="Код подразделения"
                  type="text"
                  size={7}
                  maxLength={7}
                  minLength={7}
                  onBlur={field.onBlur}
                  containerClasses="h-10"
                  value={subunitCodeTransformer.output(field.value)}
                  onChange={event => field.onChange(subunitCodeTransformer.input(event.target.value))}
                  errorText={errors.identityDocument?.subunitCode?.message}
                />
              )}
            />
          )}

          <Controller
            name="identityDocument.issueDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                data-cypress="issuedDate"
                labelText="Дата выдачи"
                type="text"
                containerClasses="h-10"
                placeholder="DD.MM.YYYY"
                value={dateISOStringTransformer.output(field.value)}
                onChange={event => field.onChange(dateISOStringTransformer.input(event.target.value))}
                errorText={error?.message}
              />
            )}
          />
        </div>

        <Input
          data-cypress="issuedBy"
          labelText="Кем выдан"
          placeholder="Наименования органа, выдавшего документ"
          type="text"
          containerClasses="h-10"
          errorText={errors.identityDocument?.issuedBy?.message}
          {...register('identityDocument.issuedBy')}
        />
        <Input
          data-cypress="birthPlace"
          labelText="Место рождения"
          placeholder="Укажите как в паспорте"
          type="text"
          containerClasses="h-10"
          errorText={errors.birthPlace?.message}
          {...register('birthPlace')}
        />
      </div>
    </div>
  );
};
