import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { toast } from 'react-toastify';
import { OCR_STATUSES } from '../../../shared/const';

interface OCRRequest {
  applicationId: string | number;
  borrowerId: string | number;
  documentIds: string[];
}

type Status = keyof Omit<typeof OCR_STATUSES, 'NOT_NEEDED'>;

interface OCRResponse {
  status: Status;
  result?: {
    lastName: string;
    firstName: string;
    middleName: string;
    birthdate: string; //ISO Date
    gender: string; // Could be any clown letters, like: М, Муж, Мужской, e.t.c
    series: string; // Numbers
    number: string; // Number
    subunitCode: string; //500-500
    issueDate: string; // ISO Date
    issuedBy: string;
    birthPlace: string;
    minPrecision: number; // Tech. Info
  };
}

export const getOCR = async ({ applicationId, borrowerId, documentIds }: OCRRequest): Promise<OCRResponse> => {
  const ctrl = new AbortController();

  let result: OCRResponse['result'];

  let status: OCRResponse['status'] = OCR_STATUSES.NOT_STARTED;

  const emergedStop = new Promise(resolve =>
    setTimeout(() => {
      ctrl.abort();
      console.warn('OCR request aborted');
      status = OCR_STATUSES.COMPLETED_WITH_ERRORS;
      resolve({ result, status });
    }, 65_000),
  );

  const request = fetchEventSource('/api/ml-ocr/ocr-result', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('token') ?? ''}`,
      Accept: '*/*',
      'Content-Type': '*/*',
      applicationId: applicationId.toString(),
      borrowerId: borrowerId.toString(),
      documentIds: documentIds.join(' '),
    },
    signal: ctrl.signal,
    // eslint-disable-next-line @typescript-eslint/require-await
    async onopen(response) {
      if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
        return; // everything's good
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        // client-side errors are usually non-retriable:
        ctrl.abort();
        status = OCR_STATUSES.COMPLETED_WITH_ERRORS;

        throw new Error('Client Side Error!');
      } else if (response.status >= 500) {
        ctrl.abort();
        status = OCR_STATUSES.COMPLETED_WITH_ERRORS;

        throw new Error('Server Side Error!');
      }
    },
    onmessage(message) {
      // if the server emits an error message, throw an exception
      // so it gets handled by the onerror callback below:

      console.log('[EVENT_STREAM]', message);

      if (message.event === 'FatalError') {
        throw new Error(message.data);
      }

      if (message.event === 'POOR_RESULT' || message.event === 'GOOD_RESULT') {
        ctrl.abort();

        status = message.event === 'POOR_RESULT' ? OCR_STATUSES.COMPLETED_WITH_WARNINGS : OCR_STATUSES.COMPLETED;

        try {
          return (result = JSON.parse(message.data) as OCRResponse['result']);
        } catch (error) {
          toast.error('OCR_ERROR');
          throw error;
        }
      }

      if (message.event === 'BAD_RESULT') {
        ctrl.abort();

        return (status = OCR_STATUSES.COMPLETED_WITH_ERRORS);
      }

      if (message.event === 'NO_RESULT') {
        status = OCR_STATUSES.COMPLETED_WITH_ERRORS;
      }
    },
  });

  await Promise.race([emergedStop, request]);

  console.log(status, result);

  return { result, status };
};
