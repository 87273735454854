import Questionnaire_PDF from '../../materials/anketa.pdf';
import PFRStatement_PDF from '../../materials/kak-zakazat-vypisku-iz-pfr.pdf';
// import AppraisalCompanies_PDF from '../../materials/ocenochnye-kompanii.pdf';
import BusinessOwners_PDF from '../../materials/prilozhenie-k-ankete-sobstvennikov-biznesa.pdf';
import ThirdPartyBank_PDF from '../../materials/prilozhenie-k-ankete-stotonnego-banka.pdf';
import DocumentList_PDF from '../../materials/spisok-documentov-dlya-podachi-zayavki.pdf';
import BankStatement_PDF from '../../materials/spravka-po-forme-banka.pdf';
// import InsuranceCompanies_PDF from '../../materials/strahovye-kompanii.pdf';

interface MaterialsList {
  title: string;
  link: string;
}

const QuestionnaireList: MaterialsList[] = [
  {
    title: 'Анкета',
    link: Questionnaire_PDF,
  },
  {
    title: 'Справка по форме банка',
    link: BankStatement_PDF,
  },
  {
    title: 'Приложение к анкете для собственников бизнеса',
    link: BusinessOwners_PDF,
  },
  {
    title: 'Приложение к анкете стороннего банка',
    link: ThirdPartyBank_PDF,
  },
];

const ReferenceInfoList: MaterialsList[] = [
  {
    title: 'Список документов для подачи заявки',
    link: DocumentList_PDF,
  },
  {
    title: 'Как заказать выписку из ПФР',
    link: PFRStatement_PDF,
  },
  // {
  //   title: 'Оценочные компании',
  //   link: AppraisalCompanies_PDF,
  // },
  // {
  //   title: 'Страховые компании',
  //   link: InsuranceCompanies_PDF,
  // },
];

const MaterialsGroup = [
  { title: 'Анкеты и приложения', materials: QuestionnaireList },
  { title: 'Справочная информация', materials: ReferenceInfoList },
] as const;

export const Materials: React.FC = () => (
  <div className="mt-6 mx-12">
    <h1 className="*h1">Материалы</h1>
    <div className="space-y-4 mt-6">
      {MaterialsGroup.map(element => (
        <div key={element.title} className="p-8 flex space-y-8 flex-col w-full bg-white shadow rounded">
          <h5 className="*h5">{element.title}</h5>
          {element.materials.map(questionary => (
            <a
              className="*typo *link"
              key={questionary.title}
              target="_blank"
              href={questionary.link}
              rel="noopener noreferrer"
            >
              {questionary.title}
            </a>
          ))}
        </div>
      ))}
    </div>
  </div>
);
