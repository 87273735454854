import cx from 'clsx';
import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useDeleteCoborrower } from '../entities/CreateApplicationEntity/controllers';
import { isApplicationValidQuery } from '../entities/CreateApplicationEntity/queries';
import {
  applicationCurrentSubjectIDState,
  applicationPersonalDataState,
  applicationStageState,
  applicationSubjectHistoryState,
} from '../entities/CreateApplicationEntity/states';
import { Loading } from '../features';
import { APPLICATION_MAX_COBORROWERS_COUNT, APPLICATION_STAGES, SUBJECT_TYPES } from '../shared/const';
import AddRingSVG from '../shared/icons/add-ring.svg?->TSX';
import BackSVG from '../shared/icons/back.svg?->TSX';
import CheckmarkSVG from '../shared/icons/checkmark.svg?->TSX';
import TrachDeleteSVG from '../shared/icons/trash-delete.svg?->TSX';
import WarningTriangleSVG from '../shared/icons/warning-tr.svg?->TSX';
import CoborrowersBG from '../shared/images/Coborrowers-BG.svg';
import { ResultValidationInformation, SubjectIDInfo } from '../shared/types';
import { prettyFullName } from '../shared/utils';

interface SubjectCardProps {
  subjectInfo: SubjectIDInfo;
  isValid: ResultValidationInformation['subjects'];
}

const SubjectCard: React.FC<SubjectCardProps> = ({ subjectInfo, isValid }) => {
  const subject = useRecoilValue(applicationPersonalDataState(subjectInfo.subjectID));
  const deleteSubject = useDeleteCoborrower(subjectInfo);

  const isSubjectValid = useMemo(() => {
    const subjectToValid = isValid[subjectInfo.subjectID];
    return { application: subjectToValid.application.success, documents: subjectToValid.documents.success };
  }, [isValid, subjectInfo.subjectID]);

  const goToDocuments = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationCurrentSubjectIDState, subjectInfo);
        set(
          applicationStageState,
          subject?.identityDocument?.type ? APPLICATION_STAGES.DOCUMENTS : APPLICATION_STAGES.CHOSE_TYPE,
        );
      },
    [],
  );

  const goToForm = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationCurrentSubjectIDState, subjectInfo);
        set(
          applicationStageState,
          subject?.identityDocument?.type ? APPLICATION_STAGES.PERSONAL_DATA : APPLICATION_STAGES.CHOSE_TYPE,
        );
      },
    [],
  );

  return (
    <div className="mt-2 flex items-center p-6 bg-white rounded shadow">
      <div className="flex flex-col items-start">
        <button
          data-cypress="go-to-form"
          type="button"
          onClick={goToForm}
          className="font-700 text-xxl flex items-center space-x-2"
        >
          <div className={cx('', isSubjectValid.application ? 'text-geneva-120' : 'text-moscow-100')}>
            {isSubjectValid.application && <CheckmarkSVG width={30} height={30} />}
            {!isSubjectValid.application && <WarningTriangleSVG width={30} height={30} />}
          </div>
          <div>{prettyFullName(subject.nameRu)}</div>
        </button>
        <button
          type="button"
          data-cypress="go-to-docs"
          onClick={goToDocuments}
          className="mt-1 text-oslo-100 text-m flex items-center space-x-2"
        >
          <div className={cx('', isSubjectValid.documents ? 'text-geneva-120' : 'text-moscow-100')}>
            {isSubjectValid.documents && <CheckmarkSVG width={30} height={30} />}
            {!isSubjectValid.documents && <WarningTriangleSVG width={30} height={30} />}
          </div>
          <div>Документы</div>
        </button>
      </div>
      {subjectInfo.subjectType === SUBJECT_TYPES.COBORROWER && (
        <button
          data-cypress="delete-coborrower"
          className="ml-auto p-2 flex items-center text-m font-500"
          type="button"
          onClick={deleteSubject}
        >
          <TrachDeleteSVG width={20} height={20} viewBox="0 0 20 20" />
          <span className="pl-2">Удалить</span>
        </button>
      )}
    </div>
  );
};

export const ChooseSubjectWidget: React.FC = () => {
  const [borrower, ...coborrowers] = useRecoilValue(applicationSubjectHistoryState);
  const { isValid, subjects } = useRecoilValue(isApplicationValidQuery);

  const goToChooseType = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationStageState, APPLICATION_STAGES.CHOSE_TYPE);
      },
    [],
  );

  const goToSendToBank = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationStageState, APPLICATION_STAGES.SEND_TO_BANK);
      },
    [],
  );

  if (!borrower) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="pt-6 px-12 max-h-full overflow-auto h-full flex mb-4 flex-col">
        <div className="mb-2 flex min-h-9 items-center">
          <Link className="flex h-full items-center space-x-2 text-primary" to="/applications">
            <BackSVG className="mb-0.5" width={20} height={20} />
            <span data-cypress="all-applications" className="font-500 text-m">
              Все заявки
            </span>
          </Link>
        </div>

        <div data-cypress="application-header" className="flex items-center space-x-4">
          <h1 className="*h1">Новая заявка</h1>
        </div>

        <div
          style={{ background: `url(${CoborrowersBG}) no-repeat bottom right` }}
          className="flex h-24 flex-col mt-6 space-y-2"
        >
          <span className="text-xxl font-700">Участники сделки</span>
          <span data-cypress="continue" className="text-m text-seattle-100">
            Добавьте не более 3 созаемщиков. Если их нет, нажмите “Продолжить”
          </span>
        </div>

        <div className="mt-3">
          <span>Заемщик</span>
          <SubjectCard subjectInfo={borrower} isValid={subjects} />
        </div>

        <div className="mt-6 mb-25">
          <span>Созаемщики</span>
          <div className="space-y-4">
            {coborrowers.map(coborrower => (
              <SubjectCard key={coborrower.subjectID} subjectInfo={coborrower} isValid={subjects} />
            ))}
            {coborrowers.length < APPLICATION_MAX_COBORROWERS_COUNT && (
              <button
                data-cypress="add-coborrower"
                className="mt-2 bg-primary w-full flex items-center justify-center text-white h-10 px-5 space-x-2"
                type="button"
                onClick={goToChooseType}
              >
                <AddRingSVG width={20} height={20} />
                <span>Добавить созаемщика</span>
              </button>
            )}
          </div>
        </div>

        <div className="fixed bottom-0 right-0 w-[calc(100%-252px)] py-2 px-12 bg-white">
          <div className="flex space-x-4 children:(w-full flex items-center justify-center font-500 px-5 rounded text-m h-10)">
            <button
              data-cypress="continue"
              className="bg-accent text-primary hover:bg-onAccent disabled:(bg-seattle-10 text-london-120 cursor-not-allowed)"
              type="button"
              onClick={goToSendToBank}
              disabled={!isValid}
            >
              Продолжить
            </button>
          </div>
        </div>
      </div>
    </Suspense>
  );
};
