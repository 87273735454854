import { KeycloakInstance } from 'keycloak-js';
import { atom } from 'recoil';
import { RECOIL_KEYS } from '../../shared/const';

export const KeyCloakStore = atom<
KeycloakInstance & {
  tokenParsed: {
    attributes: {
      partner_bonus_types: [
        {
          code: 'COMMISSION';
          name: 'Комиссионное вознаграждение';
        },
        {
          code: 'PREFERENCE';
          name: 'Преференция по ставке';
        },
      ];
      holding_rdm_id: string;
      partner_rdm_id: string;
    };
  };
}
>({
  key: RECOIL_KEYS.USER_PROFILE_STORE,
});
