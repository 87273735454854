import { Link } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import {
  useCreateBorrowerIDAndApplicationID,
  useCreateCoborrowerID,
} from '../entities/CreateApplicationEntity/controllers';
import { applicationStageState, applicationSubjectHistoryState } from '../entities/CreateApplicationEntity/states';
import { APPLICATION_IDENTIFY_DOCUMENT_TYPES, APPLICATION_STAGES } from '../shared/const';
import BackSVG from '../shared/icons/back.svg?->TSX';

export const ChooseTypeWidget: React.FC = () => {
  const createBorrowerAndApplication = useCreateBorrowerIDAndApplicationID();
  const createCoborrower = useCreateCoborrowerID();

  const continueApplicationFlow = useRecoilCallback(
    ({ set, snapshot }) =>
      async (documentType: keyof typeof APPLICATION_IDENTIFY_DOCUMENT_TYPES) => {
        const [borrower, ...coborrowers] = await snapshot.getPromise(applicationSubjectHistoryState);

        await (!borrower ? createBorrowerAndApplication({ documentType }) : createCoborrower({ documentType }));

        set(applicationStageState, APPLICATION_STAGES.PERSONAL_DATA);
      },
    [],
  );

  return (
    <div className="pt-6 px-12 overflow-auto h-full flex flex-col">
      <div className="mb-2 flex min-h-9 items-center">
        <Link className="flex h-full items-center space-x-2 text-primary" to="/applications">
          <BackSVG className="mb-0.5" width={20} height={20} />
          <span data-cypress="all-applications" className="font-500 text-m">
            Все заявки
          </span>
        </Link>
      </div>

      <div data-cypress="application-header" className="flex items-center space-x-4">
        <h1 className="*h1">Новая заявка</h1>
      </div>

      <div className="mt-6 flex flex-col bg-white shadow rounded p-6 h-max">
        <h4 className="text-xxl font-700">У клиента есть гражданство РФ?</h4>
        <span className="mt-2 text-m">
          Если есть, то мы сможем распознать данные по паспорту. А если нет, потребуется вводить всё вручную
        </span>
        <div className="flex space-x-4 mt-4 children:(w-full flex items-center justify-center font-500 px-5 rounded text-m text-primary h-10)">
          <button
            data-cypress="ocr-application"
            className="bg-accent hover:bg-onAccent"
            type="button"
            onClick={() => continueApplicationFlow(APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT)}
          >
            Да
          </button>
          <button
            data-cypress="manual-application"
            className="border-2 border-seattle-30 hover:border-primary"
            type="button"
            onClick={() => continueApplicationFlow(APPLICATION_IDENTIFY_DOCUMENT_TYPES.FPASSPORT)}
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  );
};
