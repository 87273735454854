import { atom, atomFamily } from 'recoil';
import {
  APPLICATION_IDENTIFY_DOCUMENT_TYPES,
  APPLICATION_STAGES,
  DOCUMENT_TYPE,
  RECOIL_KEYS,
} from '../../shared/const';
import { ApplicationFileState, SubjectIDInfo, SubjectResource } from '../../shared/types';

export const applicationStageState = atom<keyof typeof APPLICATION_STAGES>({
  key: RECOIL_KEYS.APPLICATION_STAGE_STORE,
  default: APPLICATION_STAGES.CHOSE_TYPE,
});

export const applicationIDState = atom<string>({
  key: RECOIL_KEYS.APPLICATION_ID_STORE,
});

export const applicationCurrentSubjectIDState = atom<SubjectIDInfo>({
  key: RECOIL_KEYS.APPLICATION_CURRENT_SUBJECT_ID_STORE,
});

export const applicationSubjectHistoryState = atom<SubjectIDInfo[]>({
  key: RECOIL_KEYS.APPLICATION_SUBJECT_HISTORY_STORE,
  default: [],
});

export const subjectFilesState = atomFamily<ApplicationFileState[], SubjectIDInfo['subjectID']>({
  key: RECOIL_KEYS.APPLICATION_FILES_STORE,
  default: [],
});

export const applicationPersonalDataState = atomFamily<SubjectResource, SubjectIDInfo['subjectID']>({
  key: RECOIL_KEYS.APPLICATION_PERSONAL_DATA_STORE,
  default: {
    birthDate: '',
    birthPlace: '',
    citizenship: '',
    email: '',
    mobilePhone: '',
    gender: null,
    registrationAddress: '',
    residenceAddress: '',
    nameRu: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    identityDocument: {
      subunitCode: '',
      issueDate: '',
      issuedBy: '',
      number: '',
      series: '',
      type: APPLICATION_IDENTIFY_DOCUMENT_TYPES.FPASSPORT,
    },
  },
});

export const applicationEmployeeBookTypeState = atomFamily<keyof typeof DOCUMENT_TYPE, SubjectIDInfo['subjectID']>({
  key: RECOIL_KEYS.APPLICATION_EMPLOYEE_BOOK_TYPE_STORE,
  default: DOCUMENT_TYPE.PAPER,
});

export const applicationIncomeCertificateTypeState = atomFamily<keyof typeof DOCUMENT_TYPE, SubjectIDInfo['subjectID']>(
  {
    key: RECOIL_KEYS.APPLICATION_INCOME_CERTIFICATE_TYPE_STORE,
    default: DOCUMENT_TYPE.PAPER,
  },
);
