import { useEffect, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DocumentsForm } from '..';
import {
  useDeleteSubjectDocument,
  useUploadSubjectDocuments,
} from '../../../entities/CreateApplicationEntity/controllers';
import {
  applicationCurrentSubjectIDState,
  applicationIncomeCertificateTypeState,
} from '../../../entities/CreateApplicationEntity/states';
import { FileLoader } from '../../../features';
import { Radio } from '../../../shared/components/Radio';
import { Tooltip } from '../../../shared/components/Tooltip';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_FILENAME_LENGTH,
  ALLOWED_FILESIZE,
  APPLICATION_DOCUMENT_TYPES,
  DOCUMENT_TYPE,
} from '../../../shared/const';
import CopySVG from '../../../shared/icons/copy.svg?->TSX';
import HintRingSVG from '../../../shared/icons/hint-ring.svg?->TSX';
import MailSVG from '../../../shared/icons/mail.svg?->TSX';
import { ApplicationFileState } from '../../../shared/types';
import { handleFileUploadError } from '../../../shared/utils';

interface IncomeCertificateProps {
  files: ApplicationFileState[];
}

// FIXME: Fuck :(
const pickFilesByType = (files: ApplicationFileState[], documentType: keyof typeof APPLICATION_DOCUMENT_TYPES) =>
  files
    .filter(file => file.documentType === documentType)
    .map(file => ({
      id: file.id,
      name: file.fileName,
      lastModified: new Date(file.uploadTime).getTime(),
      size: file.size,
    }));

const incomeCertificateTypes = [
  {
    label: 'Бумажная',
    value: 'PAPER',
  },
  {
    label: 'Выписка из ПФР',
    value: 'DIGITAL',
  },
];

export const IncomeCertificate: React.FC<IncomeCertificateProps> = ({ files }) => {
  const uploadDocument = useUploadSubjectDocuments();
  const deleteDocument = useDeleteSubjectDocument();
  const { subjectID } = useRecoilValue(applicationCurrentSubjectIDState);

  const [incomeCertificateType, setIncomeCertificateType] = useRecoilState(
    applicationIncomeCertificateTypeState(subjectID),
  );

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<DocumentsForm>();

  const filesByType = useMemo(() => pickFilesByType(files, APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE), [files]);

  useEffect(() => {
    setValue(
      APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE,
      incomeCertificateType === DOCUMENT_TYPE.PAPER ? filesByType.map(file => file.id) : DOCUMENT_TYPE.DIGITAL,
    );
    clearErrors(APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE);
  }, [filesByType, incomeCertificateType]);

  const error = errors?.INCOME_CERTIFICATE as unknown as FieldError;

  return (
    <div className="space-y-4">
      <span className="text-xxl font-700">Подтверждение дохода</span>
      <Radio
        value={incomeCertificateType}
        radioElements={incomeCertificateTypes}
        uniqueName={APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE}
        onClick={event => setIncomeCertificateType(event.currentTarget.value as keyof typeof DOCUMENT_TYPE)}
      />
      {incomeCertificateType === 'PAPER' && (
        <>
          <div className="flex items-center text-m text-seattle-100 space-x-1">
            <span>Справка 2-НДФЛ за последние 12 месяцев или другие</span>
            <div className="relative">
              <HintRingSVG className="hover:sibling:flex" height={20} width={20} />
              <Tooltip containerClasses="hidden">
                <div />
              </Tooltip>
            </div>
          </div>
          <FileLoader
            files={pickFilesByType(files, APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE)}
            allowedExtensions={ALLOWED_EXTENSIONS}
            allowedFileNameLength={ALLOWED_FILENAME_LENGTH}
            maxFileSize={ALLOWED_FILESIZE}
            onFileLoaded={file => uploadDocument(file, APPLICATION_DOCUMENT_TYPES.INCOME_CERTIFICATE)}
            onFileRejected={handleFileUploadError}
            onFileDelete={async file => deleteDocument(file.id)}
            errorMessage={error?.message}
          />
        </>
      )}
      {incomeCertificateType === 'DIGITAL' && (
        <div className="mt-4">
          <div>
            Попросите клиента получить выписку из ПФР и отправить её через Госуслуги на электронную почту ниже,
            прикладывать ничего не надо.
          </div>
          <div className="mt-6">
            Это ссылка на электронную книжку — отправьте её клиенту, чтобы не искать нужную страницу на Госуслугах.
          </div>
          <div className="h-15 mt-4 bg-seattle-5 py-4 px-6 flex">
            <a
              className="flex text-m text-oslo-120 items-center select-none space-x-3"
              href="https://www.gosuslugi.ru/600303/1/form"
              rel="noreferrer noopener"
            >
              https://www.gosuslugi.ru/600303/1/form
            </a>
            <button
              className="flex items-center ml-auto space-x-2 font-500 cursor-pointer"
              type="button"
              onClick={() => {
                navigator.clipboard
                  .writeText('https://www.gosuslugi.ru/600303/1/form')
                  .then(() => toast.success('Скопировано в буфер обмена'))
                  .catch(() => toast.error('Ошибка копирования'));
              }}
            >
              <CopySVG width={24} height={24} />
              <div className="select-none">Скопировать ссылку</div>
            </button>
          </div>
          <div className="h-15 mt-4 bg-seattle-5 p-4 flex">
            <a
              className="flex text-oslo-120 items-center space-x-3"
              href="mailto:pfr@raiffeisen.ru"
              rel="noreferrer noopener"
            >
              <MailSVG width={24} height={24} />
              <div className="text-primary select-none">pfr@raiffeisen.ru</div>
            </a>
            <button
              className="flex items-center ml-auto space-x-2 font-500 cursor-pointer"
              type="button"
              onClick={() => {
                navigator.clipboard
                  .writeText('pfr@raiffeisen.ru')
                  .then(() => toast.success('Скопировано в буфер обмена'))
                  .catch(() => toast.error('Ошибка копирования'));
              }}
            >
              <CopySVG width={24} height={24} />
              <div className="select-none">Скопировать адрес</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
