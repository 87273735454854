import { selector } from 'recoil';
import { RECOIL_KEYS } from '../../shared/const';
import { EmployeeInfoResponse } from '../../shared/types';
import {
  fetcher,
  formatEmail,
  formatPhoneNumber,
  prettyFullNameWithInitials,
  prettyUserAccess,
  prettyUserStatus,
} from '../../shared/utils';

export const employeeKeys = {
  Employee: 'Сотрудник',
  Email: 'Электронная почта',
  AccessLevel: 'Уровень доступа',
  Status: 'Статус',
} as const;

export interface EmployeeRows extends Pick<EmployeeInfoResponse, 'id'> {
  Employee: {
    fullName: string;
    phone: string;
  };
  Email: string;
  AccessLevel: ReturnType<typeof prettyUserAccess>;
  Status: ReturnType<typeof prettyUserStatus>;
}

export const getEmployeesQuery = selector({
  key: RECOIL_KEYS.EMPLOYEES_QUERY,
  get: async ({ get }) => {
    const employees = await fetcher<EmployeeInfoResponse[] | []>('/api/ml-partner-users/');

    const employeeRows = employees.map(employee => ({
      id: employee.id,
      Employee: {
        fullName: prettyFullNameWithInitials(employee),
        phone: formatPhoneNumber(employee.mobilePhone),
      },
      Email: formatEmail(employee.email),
      AccessLevel: prettyUserAccess(employee.role),
      Status: prettyUserStatus(employee.state),
    })) as EmployeeRows[];

    const lastUpdate = Date.now();

    return {
      employeeRows,
      lastUpdate,
    };
  },
});
