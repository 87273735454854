/* eslint-disable sonarjs/no-duplicate-string */
import { zodResolver } from '@hookform/resolvers/zod';
import { Suspense, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { z as validate } from 'zod';
import { regionsListQuery } from '../entities/CreateApplicationEntity/queries';
import { applicationIDState, applicationStageState } from '../entities/CreateApplicationEntity/states';
import { userBonusTypeQuery } from '../entities/ProfileEntity/queries';
import { Loading } from '../features';
import { Radio } from '../shared/components/Radio';
import { Select } from '../shared/components/Select';
import { TextArea } from '../shared/components/TextArea';
import { APPLICATION_STAGES } from '../shared/const';
import BackSVG from '../shared/icons/back.svg?->TSX';
import HintRingSVG from '../shared/icons/hint-ring.svg?->TSX';
import { fetcher } from '../shared/utils';

export const SendToBankSchema = validate.object({
  bonusType: validate
    .string({ invalid_type_error: 'Поле обязательное для заполнения' })
    .regex(/COMMISSION|PREFERENCE/, 'Поле обязательное для заполнения'),
  comment: validate
    .string()
    .max(1500, 'Разрешенная длина поля до 1500 символов')
    .regex(
      /^[\s\w!"#%&'()*+,./:;<=>?@[\\\]{|}«»ЁА-яё—№\-]*$/,
      `Допустимы только русские и латинские буквы, цифры, символы , . ! ? ; : % " ' * _ - + = < > ( ) [ ] { } / | \ & # № @ «»`,
    ),
  region: validate.number({ invalid_type_error: 'Поле обязательное для заполнения' }).positive(),
});

interface SendToBankProperties {
  bonusType: string;
  comment: string;
  regionId: number;
}

export const SendToBankWidget: React.FC = () => {
  const { radioElements } = useRecoilValue(userBonusTypeQuery);
  const regionList = useRecoilValue(regionsListQuery);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: zodResolver(SendToBankSchema),
    defaultValues: {
      region: '',
      bonusType: radioElements[0].value,
      comment: '',
    },
  });

  const goToChooseSubject = useRecoilCallback(
    ({ set }) =>
      () => {
        set(applicationStageState, APPLICATION_STAGES.CHOOSE_SUBJECT);
      },
    [],
  );

  const sendToBank = useRecoilCallback(
    ({ snapshot }) =>
      async (data: SendToBankProperties) => {
        try {
          setLoading(true);
          const applicationID = await snapshot.getPromise(applicationIDState);
          await fetcher(`/api/ml-application/partners-portal/loan-applications/${applicationID}/info`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
          });
          await fetcher(`/api/ml-application/partners-portal/loan-applications/${applicationID}/send-to-bank`, {
            method: 'POST',
            body: JSON.stringify({
              status: 'COMPLETED',
            }),
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
          });
          navigate(`/applications/info/${applicationID}`);
        } finally {
          setLoading(false);
        }
      },
    [],
  );

  return (
    <Suspense fallback={<Loading />}>
      <div className="pt-6 px-12 max-h-full overflow-auto h-full flex mb-4 flex-col">
        <div className="mb-2 flex min-h-9 items-center">
          <Link className="flex h-full items-center space-x-2 text-primary" to="/applications">
            <BackSVG className="mb-0.5" width={20} height={20} />
            <span data-cypress="all-applications" className="font-500 text-m">
              Все заявки
            </span>
          </Link>
        </div>

        <div data-cypress="application-header" className="flex items-center space-x-4">
          <h1 className="*h1">Новая заявка</h1>
        </div>

        <div className="mt-6 space-y-4 children:(shadow bg-white rounded p-6)">
          <div className="space-y-6 children:(block)">
            <span className="text-xxl font-700">Информация по заявке</span>

            <div className="flex flex-col space-y-2">
              <Controller
                name="region"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    data-cypress="region"
                    options={regionList}
                    labelText="Регион объекта недвижимости"
                    containerClasses="h-10"
                    placeholder="Начните вводить"
                    value={field.value}
                    disabled={loading}
                    onSelect={element => {
                      console.log(element);
                      field.onChange(element);
                    }}
                    errorText={error?.message}
                  />
                )}
              />
              <div className="flex items-center text-primary space-x-2">
                <HintRingSVG width={20} height={20} />
                <div className="text-m font-500">Московская область или населенный пункт внутри города</div>
              </div>
            </div>

            <TextArea
              data-cypress="comment"
              labelText="Комментарий (необязательно)"
              placeholder={
                'Комментарии, которые будут полезны при рассмотрении заявки:\n• По какой акции заявка\n• Будет ли использоваться материнский капитал в качестве первоначального взноса\n• Будет ли оформлен брачный контракт между супругами\n'
              }
              type="text"
              disabled={loading}
              containerClasses="h-28"
              className="input font-ALSHauss text-m py-3 text-primary resize-none placeholder-seattle-60 placeholder-opacity-100"
              errorText={errors.comment?.message}
              {...register('comment')}
            />

            <Controller
              name="bonusType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Radio
                  labelText="Тип бонуса"
                  radioElements={radioElements}
                  uniqueName="sendToBank"
                  value={field.value}
                  onChange={event => field.onChange(event.target.value)}
                  errorText={error?.message}
                  disabled={loading}
                />
              )}
            />
          </div>
        </div>

        <div className="fixed bottom-0 right-0 w-[calc(100%-252px)] py-2 px-12 bg-white">
          <div className="flex space-x-4 children:(w-full flex items-center justify-center font-500 px-5 rounded text-m text-primary h-10)">
            <button
              data-cypress="back"
              className="border-2 border-seattle-30 hover:border-primary disabled:(bg-seattle-10 border-none text-london-120 cursor-not-allowed)"
              type="button"
              disabled={loading}
              onClick={goToChooseSubject}
            >
              Назад
            </button>

            <button
              className="bg-accent hover:bg-onAccent disabled:(bg-seattle-10 text-london-120 cursor-not-allowed)"
              data-cypress="continue"
              onClick={handleSubmit(
                ({ bonusType, comment, region }) => sendToBank({ bonusType, comment, regionId: Number(region) }),
                formErrors => console.log(formErrors),
              )}
              disabled={loading}
              type="button"
            >
              Продолжить
            </button>
          </div>
        </div>
      </div>
    </Suspense>
  );
};
