import { atomFamily } from 'recoil';
import { OCR_STATUSES, RECOIL_KEYS } from '../../shared/const';
import { SubjectIDInfo } from '../../shared/types';
import { OCRSubjectResource } from './types';

export const applicationOCRStatusState = atomFamily<keyof typeof OCR_STATUSES, SubjectIDInfo['subjectID']>({
  key: RECOIL_KEYS.APPLICATION_OCR_STORE,
});

export const applicationOCRDataState = atomFamily<OCRSubjectResource, SubjectIDInfo['subjectID']>({
  key: RECOIL_KEYS.APPLICATION_OCR_DATA_STORE,
  default: undefined,
});
