import { useEffect, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DocumentsForm } from '..';
import {
  useDeleteSubjectDocument,
  useUploadSubjectDocuments,
} from '../../../entities/CreateApplicationEntity/controllers';
import {
  applicationCurrentSubjectIDState,
  applicationEmployeeBookTypeState,
} from '../../../entities/CreateApplicationEntity/states';
import { FileLoader } from '../../../features';
import { Radio } from '../../../shared/components/Radio';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_FILENAME_LENGTH,
  ALLOWED_FILESIZE,
  APPLICATION_DOCUMENT_TYPES,
  DOCUMENT_TYPE,
} from '../../../shared/const';
import CopySVG from '../../../shared/icons/copy.svg?->TSX';
import MailSVG from '../../../shared/icons/mail.svg?->TSX';
import { ApplicationFileState } from '../../../shared/types';
import { handleFileUploadError } from '../../../shared/utils';

// FIXME: Fuck :(
const pickFilesByType = (files: ApplicationFileState[], documentType: keyof typeof APPLICATION_DOCUMENT_TYPES) =>
  files
    .filter(file => file.documentType === documentType)
    .map(file => ({
      id: file.id,
      name: file.fileName,
      lastModified: new Date(file.uploadTime).getTime(),
      size: file.size,
    }));

interface EmploymentRecordBookProps {
  files: ApplicationFileState[];
}

const employmentRecordBookTypes = [
  {
    label: 'Бумажная',
    value: DOCUMENT_TYPE.PAPER,
  },
  {
    label: 'Электронная',
    value: DOCUMENT_TYPE.DIGITAL,
  },
];

export const EmploymentRecordBook: React.FC<EmploymentRecordBookProps> = ({ files }) => {
  const uploadDocument = useUploadSubjectDocuments();
  const deleteDocument = useDeleteSubjectDocument();
  const { subjectID } = useRecoilValue(applicationCurrentSubjectIDState);
  const [employmentRecordBookType, setEmploymentRecordBookType] = useRecoilState(
    applicationEmployeeBookTypeState(subjectID),
  );

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<DocumentsForm>();

  const filesByType = useMemo(() => pickFilesByType(files, APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK), [files]);

  useEffect(() => {
    setValue(
      APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK,
      employmentRecordBookType === DOCUMENT_TYPE.PAPER ? filesByType.map(file => file.id) : DOCUMENT_TYPE.DIGITAL,
    );
    clearErrors(APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK);
  }, [filesByType, employmentRecordBookType]);

  const error = errors?.EMPLOYMENT_RECORD_BOOK as unknown as FieldError;

  return (
    <div className="space-y-4">
      <div className="text-xxl font-700">Трудовая книжка</div>
      <Radio
        value={employmentRecordBookType}
        radioElements={employmentRecordBookTypes}
        uniqueName={APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK}
        onClick={event => setEmploymentRecordBookType(event.currentTarget.value as keyof typeof DOCUMENT_TYPE)}
      />
      {employmentRecordBookType === 'PAPER' && (
        <>
          <div className="text-m text-seattle-100">
            Все заполненные страницы трудовой книжки, заверенные работодателем
          </div>
          <FileLoader
            files={filesByType}
            allowedExtensions={ALLOWED_EXTENSIONS}
            allowedFileNameLength={ALLOWED_FILENAME_LENGTH}
            maxFileSize={ALLOWED_FILESIZE}
            onFileLoaded={file => uploadDocument(file, APPLICATION_DOCUMENT_TYPES.EMPLOYMENT_RECORD_BOOK)}
            onFileRejected={handleFileUploadError}
            onFileDelete={async file => deleteDocument(file.id)}
            errorMessage={error?.message}
          />
        </>
      )}
      {employmentRecordBookType === 'DIGITAL' && (
        <div className="mt-4">
          <div>
            Попросите клиента получить электронную книжку и отправить её через Госуслуги на электронную почту ниже,
            прикладывать ничего не надо.
          </div>
          <div className="mt-6">
            Это ссылка на электронную книжку — отправьте её клиенту, чтобы не искать нужную страницу на Госуслугах.
          </div>
          <div className="h-15 mt-4 bg-seattle-5 py-4 px-6 flex">
            <a
              className="flex text-m text-oslo-120 items-center select-none space-x-3"
              href="https://www.gosuslugi.ru/600302/1/form"
              rel="noreferrer noopener"
            >
              https://www.gosuslugi.ru/600302/1/form
            </a>
            <button
              className="flex items-center ml-auto space-x-2 font-500 cursor-pointer"
              type="button"
              onClick={() => {
                navigator.clipboard
                  .writeText('https://www.gosuslugi.ru/600302/1/form')
                  .then(() => toast.success('Скопировано в буфер обмена'))
                  .catch(() => toast.error('Ошибка копирования'));
              }}
            >
              <CopySVG width={24} height={24} />
              <div className="select-none">Скопировать ссылку</div>
            </button>
          </div>
          <div className="h-15 mt-4 bg-seattle-5 p-4 flex">
            <a
              className="flex text-oslo-120 items-center space-x-3"
              href="mailto:pfr@raiffeisen.ru"
              rel="noreferrer noopener"
            >
              <MailSVG width={24} height={24} />
              <div className="text-primary select-none">pfr@raiffeisen.ru</div>
            </a>
            <button
              className="flex items-center ml-auto space-x-2 font-500 cursor-pointer"
              type="button"
              onClick={() => {
                navigator.clipboard
                  .writeText('pfr@raiffeisen.ru')
                  .then(() => toast.success('Скопировано в буфер обмена'))
                  .catch(() => toast.error('Ошибка копирования'));
              }}
            >
              <CopySVG width={24} height={24} />
              <div className="select-none">Скопировать адрес</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
