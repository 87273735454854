import { selector } from 'recoil';
import { RadioElement } from '../../shared/components/Radio';
import {
  ALLOWED_ACTIONS_BY_ROLES,
  RAIFFEISEN_CLIENT_ID,
  RAIFFEISEN_REALTY_ADMIN_ROLES,
  RAIFFEISEN_REALTY_ALL_ROLES,
  RECOIL_KEYS,
} from '../../shared/const';
import { formatPhoneNumber } from '../../shared/utils';
import { KeyCloakStore } from './states';

type PartnerBonusTypes =
  | { code: 'COMMISSION'; name: 'Комиссионное вознаграждение' }
  | { code: 'PREFERENCE'; name: 'Преференция по ставке' };

interface ParsedTokenBody {
  preferred_username: string;
  email: string;
  given_name: string;
  family_name: string;
  middle_name: string;
  phone: string;
  attributes: {
    partner_bonus_types: PartnerBonusTypes[];
  };
}

export type ParsedToken = Partial<ParsedTokenBody>;

export const userProfileQuery = selector({
  key: RECOIL_KEYS.USER_PROFILE_QUERY,
  get: ({ get }) => {
    const keycloak = get(KeyCloakStore);

    type AdminRoles = [keyof typeof RAIFFEISEN_REALTY_ADMIN_ROLES, boolean][];

    const accountRole = Object.keys(RAIFFEISEN_REALTY_ALL_ROLES).find(role =>
      keycloak.hasResourceRole(role, RAIFFEISEN_CLIENT_ID),
    ) as keyof typeof RAIFFEISEN_REALTY_ALL_ROLES;

    const adminRoles: AdminRoles = Object.values(RAIFFEISEN_REALTY_ADMIN_ROLES).map(role => [
      role,
      keycloak.hasResourceRole(role, RAIFFEISEN_CLIENT_ID),
    ]);

    const isAdmin = adminRoles.some(([, isAdminRole]) => isAdminRole);

    if (!keycloak.authServerUrl || !keycloak.realm) {
      throw new Error('AuthServerURL or Realm is not defined');
    }

    // TODO: FIX THIS (WRONG URL)
    const getAdminURL = new URL(`${window.location.protocol}${keycloak.authServerUrl}/admin${keycloak.realm}/console`);

    if (!keycloak.tokenParsed) {
      throw new Error('Token is not defined');
    }

    const userProfile = keycloak.tokenParsed as ParsedToken;

    console.log(userProfile);

    const fullName = [userProfile.given_name, userProfile.middle_name, userProfile.family_name]
      .filter(Boolean)
      .join(' ');

    return {
      formattedFullName: fullName.length > 0 ? fullName : 'Мой профиль',
      formattedPhoneNumber: userProfile.phone ? formatPhoneNumber(userProfile.phone) : '-',
      formattedEmail: userProfile.email ? userProfile.email.toLowerCase() : '-',
      profile: {
        name: userProfile.given_name,
        middleName: userProfile.middle_name,
        lastName: userProfile.family_name,
        email: userProfile.email,
        phone: userProfile.phone,
      },
      isAdmin,
      getAdminURL: getAdminURL.href,
      roles: Object.fromEntries(adminRoles),
      accountRole,
      whatICanDoWith: (employeeRole: keyof typeof RAIFFEISEN_REALTY_ALL_ROLES) =>
        ALLOWED_ACTIONS_BY_ROLES[accountRole][employeeRole],
    };
  },
});

interface UserBonusTypeQueryReturn {
  radioElements: RadioElement[];
  partnerBonusTypes: PartnerBonusTypes[];
}

export const userBonusTypeQuery = selector<UserBonusTypeQueryReturn>({
  key: RECOIL_KEYS.USER_BONUS_TYPE_QUERY,
  get: ({ get }) => {
    const keycloak = get(KeyCloakStore);

    if (!keycloak.tokenParsed) {
      throw new Error('Token is not defined');
    }

    const userProfile = keycloak.tokenParsed as ParsedToken;

    if (!userProfile.attributes) {
      return {
        radioElements: [],
        partnerBonusTypes: [],
      };
    }

    return {
      partnerBonusTypes: userProfile.attributes.partner_bonus_types,
      radioElements: userProfile.attributes.partner_bonus_types.map(({ code, name }) => ({
        value: code,
        label: name,
      })),
    };
  },
});
