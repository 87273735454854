import { useRecoilCallback } from 'recoil';
import { fetcher } from '../../shared/utils';
import { getApplicationsBySelectedTabQuery } from '../ApplicationsEntity/queries';
import { applicationsToReassignStore, startReassignApplicationStore, successReassignCallbackStore } from './states';

export const useSendReassignApplications = () =>
  useRecoilCallback(({ snapshot, refresh, reset }) => async (assignToEmployeeID: string) => {
    const applicationsToReassign = await snapshot.getPromise(applicationsToReassignStore);
    const callbackIfSuccess = await snapshot.getPromise(successReassignCallbackStore);

    await fetcher(`/api/ml-application/partners-portal/loan-applications/assigned/${assignToEmployeeID}`, {
      method: 'POST',
      body: JSON.stringify(applicationsToReassign),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    reset(applicationsToReassignStore);
    reset(startReassignApplicationStore);
    refresh(getApplicationsBySelectedTabQuery);

    console.debug('hello?');

    await callbackIfSuccess();

    reset(successReassignCallbackStore);
  });
