import { SVGProps } from 'react';
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userProfileQuery } from '../../entities/ProfileEntity/queries';
import ChatAskIcon from '../icons/chat-ask.svg?->TSX';
import DocumentsIcon from '../icons/docs.svg?->TSX';
import DocumentIcon from '../icons/document.svg?->TSX';
import ExitIcon from '../icons/exit.svg?->TSX';
import LogotypeWhite from '../icons/logotype-white.svg?->TSX';
import ManIcon from '../icons/man.svg?->TSX';
import People1Icon from '../icons/people-1.svg?->TSX';

interface SideBarProps {
  BottomGroup?: React.ReactNode;
  HeadGroup?: React.ReactNode;
}

interface LinkProps extends RouterLinkProps {
  IconLeft?: React.FC<SVGProps<SVGSVGElement>>;
  IconRight?: React.FC<SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
}

// FIXME: переписать на отдельный компонент
const Link: React.FC<LinkProps> = ({ children, IconLeft, IconRight, ...props }) => (
  <RouterLink
    className={({ isActive }) =>
      `flex items-center text-m py-3.5 space-x-4 px-8 border-l-4 border-solid ${
        isActive ? 'border-accent' : 'border-transparent'
      }`
    }
    {...props}
  >
    {IconLeft && <IconLeft width={24} height={24} />}
    {children && <span>{children}</span>}
    {IconRight && <IconRight width={24} height={24} />}
  </RouterLink>
);

export const SideBar: React.FC<SideBarProps> = () => {
  const currentProfile = useRecoilValue(userProfileQuery);

  if (!currentProfile) {
    return null;
  }

  return (
    <div className="min-w-[252px] h-screen flex flex-col bg-primary pt-8 pb-4 shadow-md font-ALSHauss text-white">
      <div className="mb-12">
        <RouterLink className="flex items-center space-x-4 px-8" to="/applications">
          <LogotypeWhite width={144} height={40} />
        </RouterLink>
      </div>
      <div>
        <Link data-cypress="applications" to="/applications" IconLeft={DocumentsIcon}>
          Заявки
        </Link>

        {currentProfile.isAdmin && (
          <Link data-cypress="employees" to="/employees" IconLeft={People1Icon}>
            Сотрудники
          </Link>
        )}

        <Link data-cypress="materials" to="/materials" IconLeft={DocumentIcon}>
          Анкеты и справки
        </Link>
      </div>
      <div className="mt-auto">
        {/* <Link to="/" IconLeft={ChatAskIcon}>
          Служба поддержки
        </Link> */}
        <Link data-cypress="my-profile" to="/profile" IconLeft={ManIcon}>
          Мой профиль
        </Link>
        <Link data-cypress="logout" to="/logout" IconLeft={ExitIcon}>
          Выйти из профиля
        </Link>
      </div>
    </div>
  );
};
