import { selector, selectorFamily } from 'recoil';
import { FileType } from '../../features';
import {
  APPLICATION_DOCUMENT_TYPES,
  APPLICATION_IDENTIFY_DOCUMENT_TYPES,
  DOCUMENT_TYPE,
  RECOIL_KEYS,
} from '../../shared/const';
import { ResultValidationInformation } from '../../shared/types';
import { fetcher } from '../../shared/utils';
import { DocumentFormSchema, DocumentsForm } from '../../widgets/ApplicationDocumentsWidget';
import { ApplicationFormSchemaF, ApplicationFormSchemaRU } from '../../widgets/ApplicationFormWidget';
import {
  applicationCurrentSubjectIDState,
  applicationEmployeeBookTypeState,
  applicationIncomeCertificateTypeState,
  applicationPersonalDataState,
  applicationSubjectHistoryState,
  subjectFilesState,
} from './states';
import { CountryListResponse, RegionListResponse } from './types';

export const subjectFilesLoaderQuery = selectorFamily<FileType[], keyof typeof APPLICATION_DOCUMENT_TYPES>({
  key: RECOIL_KEYS.SUBJECT_FILES_QUERY,
  get:
    documentType =>
      ({ get }) => {
        const { subjectID } = get(applicationCurrentSubjectIDState);
        const files = get(subjectFilesState(subjectID));
        return files
        .filter(document => document.documentType === documentType)
        .map(file => ({
          id: file.id,
          name: file.fileName,
          size: file.size,
          lastModified: new Date(file.uploadTime).getTime(),
        }));
      },
});

export const countriesListQuery = selector({
  key: RECOIL_KEYS.COUNTRIES_LIST_QUERY,
  get: async () => {
    const { records } = await fetcher<CountryListResponse>(`/api/ml-application/dir/countries`);

    return records.map(({ code, nameRu }) => ({
      value: code,
      label: nameRu,
    }));
  },
});

export const regionsListQuery = selector({
  key: RECOIL_KEYS.REGIONS_LIST_QUERY,
  get: async () => {
    const { records } = await fetcher<RegionListResponse>(`/api/ml-application/dir/regions`);

    return records.map(({ id, pledgeLocation }) => ({
      value: id,
      label: pledgeLocation,
    }));
  },
});

export const subjectPassportTypeQuery = selector({
  key: RECOIL_KEYS.SUBJECT_PASSPORT_TYPE_QUERY,
  get: ({ get }) => {
    const { subjectID } = get(applicationCurrentSubjectIDState);
    const { identityDocument } = get(applicationPersonalDataState(subjectID));

    return identityDocument?.type;
  },
});

export const isApplicationValidQuery = selector<ResultValidationInformation>({
  key: RECOIL_KEYS.IS_APPLICATION_VALID_QUERY,
  // eslint-disable-next-line sonarjs/cognitive-complexity
  get: ({ get }) => {
    const subjectList = get(applicationSubjectHistoryState);
    const resultValidationInformation: ResultValidationInformation = {
      isValid: false,
      subjects: {},
    };

    for (const subject of subjectList) {
      const application = get(applicationPersonalDataState(subject.subjectID));
      const documents = get(subjectFilesState(subject.subjectID));
      const applicationEmployeeBookType = get(applicationEmployeeBookTypeState(subject.subjectID));
      const applicationIncomeCertificateType = get(applicationIncomeCertificateTypeState(subject.subjectID));
      const isRussianPassport = application.identityDocument?.type === APPLICATION_IDENTIFY_DOCUMENT_TYPES.RUPASSPORT;

      const applicationParseResult = isRussianPassport
        ? ApplicationFormSchemaRU.safeParse(application)
        : ApplicationFormSchemaF.safeParse(application);

      const result: DocumentsForm = {
        PASSPORT: [],
        EMPLOYMENT_RECORD_BOOK: [],
        INCOME_CERTIFICATE: [],
        APPLICATION_FORM: [],
        OTHER: [],
      };

      for (const document of documents) {
        switch (document.documentType) {
          case 'RU_PASSPORT':
          case 'F_PASSPORT': {
            result.PASSPORT = [...result.PASSPORT, document.id];

            break;
          }
          default: {
            result[document.documentType] = [...result[document.documentType], document.id];
          }
        }
      }

      if (result.EMPLOYMENT_RECORD_BOOK.length === 0) {
        result.EMPLOYMENT_RECORD_BOOK =
          applicationEmployeeBookType === DOCUMENT_TYPE.DIGITAL ? DOCUMENT_TYPE.DIGITAL : [];
      }
      if (result.INCOME_CERTIFICATE.length === 0) {
        result.INCOME_CERTIFICATE =
          applicationIncomeCertificateType === DOCUMENT_TYPE.DIGITAL ? DOCUMENT_TYPE.DIGITAL : [];
      }

      const documentsParseResult = DocumentFormSchema.safeParse(result);

      console.group(`${subject.subjectType} ${subject.subjectID}`);
      console.debug('subjectApplication', application);
      console.debug('isSubjectApplicationSubject', subject);
      console.debug('result', result);
      console.debug('applicationIncomeCertificateType', applicationIncomeCertificateType);
      console.debug('applicationEmployeeBookType', applicationEmployeeBookType);
      console.debug('applicationParseResult', applicationParseResult);
      console.debug('documentsParseResult', documentsParseResult);
      console.groupEnd();

      resultValidationInformation.subjects[subject.subjectID] = {
        application: {
          success: applicationParseResult.success,
        },
        documents: {
          success: documentsParseResult.success,
        },
      };
    }

    resultValidationInformation.isValid = Object.values(resultValidationInformation.subjects).every(
      subject => subject.application.success && subject.documents.success,
    );

    return resultValidationInformation;
  },
});
