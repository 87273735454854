import { selectorFamily } from 'recoil';
import { FileType } from '../../features';
import { APPLICATION_DOCUMENT_TYPES, RECOIL_KEYS } from '../../shared/const';
import { ApplicationStatus, DocumentFileResponseElaboration } from '../../shared/types';
import {
  fetcher,
  formatDateWithoutTime,
  formatDateWithPointToTime,
  formatPhoneNumber,
  prettyFullName,
  prettyFullNameWithInitials,
} from '../../shared/utils';
import { applicationFilesState } from './states';

interface ApplicationDetailsResponse {
  id: string;
  assignedToNameRu: {
    lastName?: string;
    firstName?: string;
    middleName?: string;
  };
  borrowerNameRu: {
    lastName?: string;
    firstName?: string;
    middleName?: string;
  };
  status: ApplicationStatus;
  statusHistory: ({
    // Date
    changedAt: string;
    changedByNameRu: {
      firstName?: string;
      lastName?: string;
      middleName?: string;
    } | null;
    comment?: string;
  } & ApplicationStatus)[];
  comment?: string;
  partnerBonusTypeName: string;
  // ISO Date
  borrowerBirthDate: string;
  borrowerId: string;
  borrowerMobilePhone: string;
}

interface ApplicationFilesLoaderQueryProps {
  applicationID: string;
  documentType: keyof typeof APPLICATION_DOCUMENT_TYPES;
}

export const applicationFilesLoaderQuery = selectorFamily<FileType[], string>({
  key: RECOIL_KEYS.APPLICATION_FILES_QUERY,
  get:
    applicationID =>
      ({ get }) => {
        const files = get(applicationFilesState(applicationID));
        return (
        files
          // .filter(document => document.documentType === APPLICATION_DOCUMENT_TYPES.OTHER)
          .map(file => ({
            id: file.id,
            name: file.fileName,
            size: file.size,
            lastModified: new Date(file.uploadTime).getTime(),
          }))
        );
      },
});

export const applicationInfoQuery = selectorFamily({
  key: RECOIL_KEYS.APPLICATION_INFO_QUERY,
  get: (applicationID: string) => async () => {
    const details = await fetcher<ApplicationDetailsResponse>(
      `/api/ml-application/partners-portal/loan-applications/${applicationID}/details`,
    );

    const files = await fetcher<DocumentFileResponseElaboration[]>(
      `/api/ml-document/application-files?appNumber=${applicationID}`,
    );

    const lastUpdate = Date.now();

    return {
      files,
      client: prettyFullName(details.borrowerNameRu),
      clientPhone: formatPhoneNumber(details.borrowerMobilePhone),
      birthDate: formatDateWithoutTime(new Date(details.borrowerBirthDate)),
      responsibleEmployee: prettyFullName(details.assignedToNameRu),
      lastUpdate,
      bonusType: details.partnerBonusTypeName,
      comment: details.comment,
      status: details.status,
      history: details.statusHistory.map(item => ({
        changedAt: formatDateWithPointToTime(item.changedAt),
        //FIXME:
        changedBy: prettyFullNameWithInitials(item.changedByNameRu ?? undefined),
        status: {
          nameEn: item.nameEn,
          nameRu: item.nameRu,
          code: item.code,
        },
        comment: item.comment,
      })),
    };
  },
});
