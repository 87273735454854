import { useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { getOCR } from '../entities/ApplicationOCREntity/controllers/OCR';
import { applicationOCRDataState, applicationOCRStatusState } from '../entities/ApplicationOCREntity/states';
import { OCRSubjectResource } from '../entities/ApplicationOCREntity/types';
import { useDeleteSubjectDocument, useUploadSubjectDocuments } from '../entities/CreateApplicationEntity/controllers';
import { subjectFilesLoaderQuery } from '../entities/CreateApplicationEntity/queries';
import {
  applicationCurrentSubjectIDState,
  applicationIDState,
  applicationPersonalDataState,
  subjectFilesState,
} from '../entities/CreateApplicationEntity/states';
import { FileLoader, Loading } from '../features';
import {
  ALLOWED_EXTENSIONS_OCR,
  ALLOWED_FILENAME_LENGTH,
  ALLOWED_FILESIZE,
  APPLICATION_DOCUMENT_TYPES,
  OCR_STATUSES,
  SUBJECT_TYPES,
} from '../shared/const';
import { handleFileUploadError, prettyFullName } from '../shared/utils';

const OCRResult: React.FC = () => {
  const { subjectID, subjectType } = useRecoilValue(applicationCurrentSubjectIDState);
  const status = useRecoilValue(applicationOCRStatusState(subjectID));
  const OCRValue = useRecoilValue(applicationOCRDataState(subjectID));
  const { nameRu: personalDataNameRu } = useRecoilValue(applicationPersonalDataState(subjectID));
  const deleteDocument = useDeleteSubjectDocument();
  const files = useRecoilValue(subjectFilesLoaderQuery(APPLICATION_DOCUMENT_TYPES.RU_PASSPORT));

  const prettiedFullName = useMemo(() => {
    if (OCRValue?.nameRu) {
      return prettyFullName(OCRValue.nameRu);
    }
    return prettyFullName(personalDataNameRu);
  }, [OCRValue, personalDataNameRu]);

  const handleOCRRetry = useRecoilCallback(({ set, reset }) => async () => {
    set(applicationOCRStatusState(subjectID), OCR_STATUSES.IN_RESET_PROCESS);
    reset(applicationOCRDataState(subjectID));

    for (const { id } of files) {
      await deleteDocument(id);
    }

    set(applicationOCRStatusState(subjectID), OCR_STATUSES.NOT_STARTED);
  });

  if (status === OCR_STATUSES.COMPLETED) {
    return (
      <div className="flex flex-col p-6 h-[198px] bg-white shadow rounded">
        <span className="text-m text-seattle-100">
          {subjectType === SUBJECT_TYPES.BORROWER ? 'Заемщик' : 'Созаемщик'}
        </span>
        <span data-cypress="borrower-full-fio" className="text-xxl mt-1 font-700">
          {prettiedFullName}
        </span>
        <span data-cypress="ocr-full" className="mt-2 text-m text-geneva-120">
          Паспорт успешно распознан, ничего заполнять не нужно
        </span>
        <div className="flex space-x-4 mt-6 children:(w-max flex items-center font-500 px-5 rounded text-m text-primary h-10)">
          <button
            data-cypress="load-again"
            className="border-2 border-seattle-30 hover:border-primary"
            type="button"
            onClick={handleOCRRetry}
          >
            Загрузить паспорт заново
          </button>
        </div>
      </div>
    );
  }

  //TODO: Talk with team about NOT_INFORMATION status (go to form from the draft)
  if (status === OCR_STATUSES.COMPLETED_WITH_WARNINGS || status === OCR_STATUSES.NOT_INFORMATION) {
    return (
      <div className="flex flex-col p-6 h-[198px] bg-white shadow rounded">
        <span className="text-m text-seattle-100">
          {subjectType === SUBJECT_TYPES.BORROWER ? 'Заемщик' : 'Созаемщик'}
        </span>
        <span data-cypress="borrower-full-fio" className="text-xxl mt-1 font-700">
          {prettiedFullName}
        </span>
        <span data-cypress="ocr-partial" className="mt-2 text-m text-geneva-120">
          Паспорт успешно распознан — проверьте и заполните недостающие данные
        </span>
        <div className="flex space-x-4 mt-6 children:(w-max flex items-center font-500 px-5 rounded text-m text-primary h-10)">
          <button
            data-cypress="load-again"
            className="border-2 border-seattle-30 hover:border-primary"
            type="button"
            onClick={handleOCRRetry}
          >
            Загрузить паспорт заново
          </button>
        </div>
      </div>
    );
  }

  if (status === OCR_STATUSES.COMPLETED_WITH_ERRORS) {
    return (
      <div className="flex flex-col p-6 bg-white shadow rounded">
        <span className="text-xxl mt-1 font-700">
          Паспорт {subjectType === SUBJECT_TYPES.BORROWER ? 'Заемщика' : 'Созаемщика'}
        </span>
        <span data-cypress="ocr-failed" className="mt-2 text-m text-osaka-120">
          Не получилось распознать паспорт. Укажите данные вручную или попробуйте еще раз
        </span>
        <div className="flex space-x-4 mt-6 children:(w-max flex items-center font-500 px-5 rounded text-m text-primary h-10)">
          <button
            data-cypress="load-again"
            className="border-2 border-seattle-30 hover:border-primary"
            type="button"
            onClick={handleOCRRetry}
          >
            Загрузить паспорт заново
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export const OCRWidget: React.FC = () => {
  const files = useRecoilValue(subjectFilesLoaderQuery(APPLICATION_DOCUMENT_TYPES.RU_PASSPORT));
  const uploadDocument = useUploadSubjectDocuments();
  const deleteDocument = useDeleteSubjectDocument();
  const { subjectID, subjectType } = useRecoilValue(applicationCurrentSubjectIDState);
  const applicationID = useRecoilValue(applicationIDState);
  const status = useRecoilValue(applicationOCRStatusState(subjectID));

  const handleOCRRequest = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const documents = await snapshot.getPromise(subjectFilesState(subjectID));
        const documentIds = documents
          .filter(document => document.documentType === APPLICATION_DOCUMENT_TYPES.RU_PASSPORT)
          .map(({ id }) => id);

        set(applicationOCRStatusState(subjectID), OCR_STATUSES.IN_PROGRESS);

        const { status: resultStatus, result } = await getOCR({
          borrowerId: subjectID,
          applicationId: applicationID,
          documentIds,
        });

        set(applicationOCRStatusState(subjectID), resultStatus);

        const formattedResultObject: OCRSubjectResource = {
          nameRu: {
            firstName: result?.firstName ?? '',
            lastName: result?.lastName ?? '',
            middleName: result?.middleName ?? '',
          },
          birthDate: result?.birthdate ?? '',
          birthPlace: result?.birthPlace ?? '',
          // Because only RU Passport could be checked by OCR
          citizenship: 'RU',
          gender: result?.gender ? (result.gender.toUpperCase().startsWith('М') ? 'M' : 'F') : null,
          // Because only RU Passport could be checked by OCR
          identityDocument: {
            type: 'RUPASSPORT',
            issueDate: result?.issueDate ?? '',
            issuedBy: result?.issuedBy ?? '',
            subunitCode: result?.subunitCode ?? '',
            number: result?.number ?? '',
            series: result?.series ?? '',
          },
        };

        set(applicationOCRDataState(subjectID), formattedResultObject);
      },
    [applicationID, subjectID],
  );

  //TODO: Talk with team about NOT_INFORMATION status (go to form from draft)
  if (status.startsWith('COMPLETED') || status === OCR_STATUSES.NOT_INFORMATION) {
    return <OCRResult />;
  }

  if (status === OCR_STATUSES.IN_RESET_PROCESS) {
    return (
      <div
        data-cypress="ocr-loading"
        className="flex flex-col py-12 bg-white items-center justify-center shadow rounded"
      >
        <Loading />
        <div className="mt-4 flex flex-col text-center text-l text-seattle-100">
          <span>Удаляем предыдущий результат распознавания, это займёт не больше минуты.</span>
          <span>Пока что можно заполнить контакты и адрес</span>
        </div>
      </div>
    );
  }

  if (status === OCR_STATUSES.IN_PROGRESS) {
    return (
      <div
        data-cypress="ocr-loading"
        className="flex flex-col py-12 bg-white items-center justify-center shadow rounded"
      >
        <Loading />
        <div className="mt-4 flex flex-col text-center text-l text-seattle-100">
          <span>Распознаём паспорт, это займёт не больше минуты.</span>
          <span>Пока что можно заполнить контакты и адрес</span>
        </div>
      </div>
    );
  }

  if (status === OCR_STATUSES.NOT_STARTED) {
    return (
      <div className="flex flex-col bg-white shadow rounded p-6">
        <h4 className="text-xxl font-700">
          Паспорт {subjectType === SUBJECT_TYPES.BORROWER ? 'Заемщика' : 'Созаемщика'}
        </h4>
        <span className="mt-2 text-l">
          Приложите развороты с фото, пропиской, семейным положением и все остальные заполненные страницы — распознаем и
          заполним данные автоматически
        </span>
        <FileLoader
          files={files}
          allowedExtensions={ALLOWED_EXTENSIONS_OCR}
          maxFileSize={ALLOWED_FILESIZE}
          allowedFileNameLength={ALLOWED_FILENAME_LENGTH}
          onFileLoaded={file => uploadDocument(file, APPLICATION_DOCUMENT_TYPES.RU_PASSPORT)}
          onFileRejected={handleFileUploadError}
          onFileDelete={async file => deleteDocument(file.id)}
        />
        {files.length > 0 && (
          <button
            data-cypress="ocr-passport"
            className="bg-accent w-full mt-6 flex items-center justify-center font-500 px-5 rounded text-m text-primary h-10 hover:bg-onAccent"
            type="button"
            onClick={handleOCRRequest}
          >
            Распознать данные
          </button>
        )}
      </div>
    );
  }

  return null;
};
