import React, { useCallback, useRef, useState } from 'react';
import { DadataSuggestion } from '../entities/CreateApplicationEntity/types';
import { Input, InputPropsWithAttributes } from '../shared/components/Input';
import { debounce } from '../shared/utils/debounce';

interface DadataSuggestionSelectProps extends InputPropsWithAttributes {
  acceptFiasLevels: string[];
  callForSuggestion: (query: string) => Promise<DadataSuggestion[]>;
  debounceDelay?: number;
  onSelectSuggestion: (suggestion: string, isValid: boolean) => void;
}

export const DadataSuggestionSelect: React.FC<DadataSuggestionSelectProps> = ({
  callForSuggestion,
  onSelectSuggestion,
  acceptFiasLevels,
  debounceDelay = 300,
  ...inputProperties
}) => {
  const inputReference = useRef<HTMLInputElement | null>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<DadataSuggestion[]>([]);
  const [lastRequest, setLastRequest] = useState<string>('');

  const debouncedGetSuggestions = useCallback(
    debounce(
      (query: string) =>
        callForSuggestion(query)
          .then(response => setSuggestions(response))
          .catch(error => console.warn(error)),
      debounceDelay,
    ),
    [callForSuggestion, debounceDelay],
  );

  return (
    <div className="relative">
      <Input
        {...inputProperties}
        ref={inputReference}
        onChange={event => {
          if (event.target.value.trim().length >= 3 && event.target.value.trim() !== lastRequest) {
            debouncedGetSuggestions(event.target.value.trim());
            setLastRequest(event.target.value.trim());
          }
        }}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      />
      {suggestions.length > 0 && inputFocused && (
        <ul className="absolute top-14 left-0 py-2 px-0 rounded shadow-xl overflow-auto max-h-75 z-100 list-none w-full">
          {suggestions.map(suggestion => (
            <li
              className="min-h-10 py-2 px-4 text-m cursor-pointer bg-white hover:(bg-grayWhite)"
              key={suggestion.full_address}
              onMouseDown={event => {
                event.stopPropagation();
                event.preventDefault();

                const isValid =
                  acceptFiasLevels.length === 0 || acceptFiasLevels.includes(suggestion.fias_level.toString());

                onSelectSuggestion(suggestion.full_address, isValid);

                setSuggestions([]);

                if (inputReference.current?.value) {
                  inputReference.current.value = suggestion.full_address;

                  if (!isValid) {
                    debouncedGetSuggestions(inputReference.current?.value.trim());
                    setLastRequest(inputReference.current?.value.trim());
                  }
                }
              }}
            >
              {suggestion.full_address}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
