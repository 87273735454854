export const dateISOStringTransformer = {
  input: (value?: string) => {
    if (!value || value.length === 0) return '';

    console.log('DEBUG', value);

    return value.replace(/\D/g, '').slice(0, 8);
  },
  output: (value?: string) => {
    if (!value || value.length === 0) return '';

    const isISODate = /^\d{4}-\d{2}-\d{2}$/.test(value);

    const clearValue = value.replace(/\D/g, '');

    console.log(value);

    if (isISODate) {
      const day = clearValue.slice(6, 8);
      const month = clearValue.slice(4, 6);
      const year = clearValue.slice(0, 4);

      return `${day}.${month}.${year}`;
    }

    const day = clearValue.slice(0, 2);
    const mouth = clearValue.slice(2, 4);
    const year = clearValue.slice(4, 8);
    const splitter = '.';

    console.log('debug', day, mouth, year);

    if (year) {
      return `${day}${splitter}${mouth}${splitter}${year}`;
    }

    if (mouth) {
      return `${day}${splitter}${mouth}`;
    }

    return day;
  },
};

export const subunitCodeTransformer = {
  input: (value?: string) => {
    if (!value || value.length === 0) return '';

    return value.replace(/\D/g, '');
  },
  output: (value?: string) => {
    if (!value || value.length === 0) return '';

    const clearValue = value.replace(/\D/g, '');

    const firstPart = clearValue.slice(0, 3);
    const secondPart = clearValue.slice(3, 6);
    const splitter = '-';

    if (secondPart) {
      return `${firstPart}${splitter}${secondPart}`;
    }
    return firstPart;
  },
};

export const firstLetterUppercaseTransformer = {
  input: (value?: string) => {
    if (!value || value.length === 0) return '';

    return value.charAt(0).toUpperCase() + value.slice(1);
  },
};

/*FIXME: 2 Бага:
  1) Если сделать ctrl+a в импуте и вставить туда 89951001338, или в целом любую другую цельную строку, то в ответ упадет не верная штука, если очень коротко
  2) Надо починить вставку частичную и полную, как это сделано в текущей реализации
*/
export const mobilePhoneTransformer = {
  input: (value = '') => value.replace(/\D/g, '').slice(0, 11),
  output: (value = '') => {
    let template = '+7 (***) ***-**-**';

    const clearValue = value.replace(/^(\+7|8|7)/, '');

    let lastInsertedElementIndex = template.indexOf('*') - 1;

    for (const element of clearValue) {
      lastInsertedElementIndex = template.indexOf('*');
      template = template.replace('*', element);
    }

    if (template.includes('*')) {
      template = template.slice(0, lastInsertedElementIndex + 1);
    }

    return template;
  },
};
