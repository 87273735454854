import { atom } from 'recoil';
import { RECOIL_KEYS } from '../../shared/const';

export const selectedEmployeeIDProfileStore = atom<string | undefined>({
  key: RECOIL_KEYS.SELECTED_EMPLOYEE_ID_PROFILE_STORE,
  default: undefined,
});

export const isProfileDeletedStore = atom<boolean>({
  key: RECOIL_KEYS.IS_PROFILE_DELETED_STORE,
  default: false,
});
