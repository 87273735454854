import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSendReassignApplications } from '../entities/ReassignApplications/controllers';
import { activeEmployeeKeys, getActiveUsersQuery } from '../entities/ReassignApplications/queries';
import { startReassignApplicationStore } from '../entities/ReassignApplications/states';
import { Input } from '../shared/components/Input';
import { Modal } from '../shared/components/Modal';
import { RadioCircle } from '../shared/components/RadioCircle';
import CloseCancelXSVG from '../shared/icons/close-cancel-x.svg?->TSX';
import SearchIcon from '../shared/icons/search.svg';
import NotFoundApplicationsBG from '../shared/images/NotFoundApplications-BG.svg';

export const ReassignApplicationsWidget: React.FC = () => {
  const [isOpen, setIsOpen] = useRecoilState(startReassignApplicationStore);
  const { activeEmployeeRows } = useRecoilValue(getActiveUsersQuery);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState<string>('');
  const [searchEmployee, setSearchEmployee] = useState('');
  const reassignApplications = useSendReassignApplications();
  const [loading, setLoading] = useState(false);

  const [tableRowWidth, setTableRowWidth] = useState<string[]>(['52px', 'minmax(250px, 1fr)', 'minmax(300px, 1fr)']);

  const tableRowTemplateColumns = useMemo(() => tableRowWidth.join(' '), [tableRowWidth]);

  const activeEmployeeRowsWithSearch = useMemo(() => {
    if (searchEmployee) {
      return activeEmployeeRows.filter(
        employee =>
          employee.Employee.fullName.toLowerCase().includes(searchEmployee.toLowerCase()) ||
          employee.Employee.phone.toLowerCase().includes(searchEmployee.toLowerCase()),
      );
    }

    return activeEmployeeRows;
  }, [activeEmployeeRows, searchEmployee]);

  return (
    <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
      <div className="relative bg-white w-[600px] p-8 shadow-xl rounded-xl space-y-6">
        <div className="flex flex-col">
          <div className="flex items-center text-primary">
            <div className="*h5">Переназначить заявки</div>
            <button className="ml-auto" onClick={() => setIsOpen(false)} type="button">
              <CloseCancelXSVG width={20} height={20} />
            </button>
          </div>
          <div className="text-m text-seattle-100 mt-3">
            Выберите сотрудника, на которого будут переназначены заявки
          </div>
        </div>

        <div>
          <Input
            containerClasses="*input-l"
            placeholder="Поиск"
            value={searchEmployee}
            onChange={event => setSearchEmployee(event.target.value)}
            prefixElement={
              <div className="w-5 h-5" style={{ background: `url(${SearchIcon}) no-repeat center center` }} />
            }
          />
        </div>

        {activeEmployeeRowsWithSearch.length > 0 ? (
          <div className="max-h-[350px] overflow-auto">
            <div
              style={{ gridTemplateColumns: tableRowTemplateColumns }}
              className="grid text-s text-seattle-60 items-center"
            >
              <div className="p-2 h-10 border-seattle-10 border-b-1" />
              {Object.entries(activeEmployeeKeys).map(([key, value]) => (
                <div className="flex items-center p-2 h-10 border-seattle-10 border-b-1" key={key}>
                  {value}
                </div>
              ))}
            </div>
            {activeEmployeeRowsWithSearch.map(({ id, AccessLevel, Employee }) => (
              <div
                style={{ gridTemplateColumns: tableRowTemplateColumns }}
                className="grid children:(h-20 w-full border-seattle-10 border-b-1 cursor-pointer hover:bg-seattle-10)
"
                onClick={() => setSelectedEmployeeID(id)}
                key={id}
              >
                <div className="flex p-2 items-center">
                  <RadioCircle
                    checked={id === selectedEmployeeID}
                    onChange={event => setSelectedEmployeeID(id)}
                    name="reassign-application-employee"
                  />
                </div>
                <div className="flex flex-col justify-center p-2">
                  <span className="font-500">{Employee.fullName}</span>
                  <span className="text-seattle-100">{Employee.phone}</span>
                </div>
                <div className="flex p-2 items-center">{AccessLevel}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col h-full mt-6 mb-10 items-center justify-center">
            <div
              style={{ background: `url(${NotFoundApplicationsBG}) no-repeat center top` }}
              className="text-xxl font-700 pt-[91px]"
            >
              Пользователи не найдены
            </div>
            <span className="*typo-sm text-seattle-100 mt-2">Попробуйте изменить критерии поиска</span>
          </div>
        )}
        <div className="flex flex-none w-36 flex space-x-4 children:(w-full flex items-center font-500 px-5 rounded text-m h-10)">
          <button
            className="bg-accent text-primary hover:bg-onAccent disabled:(bg-seattle-10 text-london-120 cursor-not-allowed)"
            type="button"
            onClick={async () => {
              try {
                setLoading(true);
                await reassignApplications(selectedEmployeeID);
                toast.success('Все заявки переназначены, кроме выданных и отказов');
                setIsOpen(false);
              } finally {
                setLoading(false);
              }
            }}
            disabled={loading}
          >
            Переназначить
          </button>
        </div>
      </div>
    </Modal>
  );
};
