import { useRecoilCallback } from 'recoil';
import { APPLICATIONS_LIST_TABS } from '../../shared/const';
import { applicationsPageState } from './states';

export const useResetPagination = () =>
  useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(applicationsPageState(APPLICATIONS_LIST_TABS.ALL));
        reset(applicationsPageState(APPLICATIONS_LIST_TABS.DRAFTS));
        reset(applicationsPageState(APPLICATIONS_LIST_TABS.NEED_REFINEMENT));
      },
    [],
  );
