import cx from 'clsx';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import GoLeftSVG from '../shared/icons/go-left.svg?->TSX';
import GoRightSVG from '../shared/icons/go-right.svg?->TSX';
import { useMeasure } from '../shared/utils/useMeasure';

//* Copy https://www.npmjs.com/package/rc-pagination

interface PaginationProps {
  current: number;
  total: number;
  pageSize: number;
  onChange: (requestedPage: number, currentPage: number, pageSize: number) => void;
  containerClasses?: string;
}

// Item Width 32px + 12px padding between items
const DEFAULT_ITEM_WIDTH = 32 + 12;

// Two arrows
const SYSTEM_ITEMS_COUNT = 2;

// Always show first and last page
const ALWAYS_FIRST_AND_LAST = 2;

export const Pagination: React.FC<PaginationProps> = ({ total, containerClasses, current, onChange, pageSize }) => {
  const [parentReference, { width }] = useMeasure<HTMLDivElement>();

  const allowedItemsCountToRender = useMemo(
    () => Math.max(Math.floor(width / DEFAULT_ITEM_WIDTH) - SYSTEM_ITEMS_COUNT - ALWAYS_FIRST_AND_LAST, 0),
    [width],
  );

  console.debug('allowedItemsCountToRender', allowedItemsCountToRender);

  const pagesCount = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);

  console.debug('pagesCount', pagesCount);

  const renderItems = useMemo(() => {
    const items = [];

    let availableToRenderToStart = Math.floor(allowedItemsCountToRender / 2);

    console.log(availableToRenderToStart, allowedItemsCountToRender / 2);

    for (let page = current; page > 0; page--) {
      if (availableToRenderToStart > 0) {
        items.unshift({ position: page, key: nanoid(6), isPortal: false });
        availableToRenderToStart -= 1;
      } else {
        const guessPage = Math.floor((1 + page) / 2);
        items.unshift(
          { position: 1, key: nanoid(6), isPortal: false },
          { position: guessPage, key: nanoid(6), isPortal: true },
        );
        break;
      }
      console.debug('items start', items);
    }

    let availableToRenderToEnd = Math.ceil(allowedItemsCountToRender / 2) - 1;

    console.debug(current, pagesCount);

    for (let page = current + 1; page <= pagesCount; page++) {
      if (availableToRenderToEnd > 0) {
        items.push({ position: page, key: nanoid(6), isPortal: false });
        availableToRenderToEnd -= 1;
      } else {
        const guessPage = Math.ceil((pagesCount + page) / 2);
        items.push(
          { position: guessPage, key: nanoid(6), isPortal: true },
          { position: pagesCount, key: nanoid(6), isPortal: false },
        );
        break;
      }
    }

    console.debug('items end', items);

    console.log(items);

    return items;
  }, [current, pagesCount, allowedItemsCountToRender]);

  return (
    <div ref={parentReference} className={cx('w-full h-8', containerClasses)}>
      <div className="flex h-full items-center space-x-3 text-l children:(font-500)">
        {current > 1 && (
          <button
            className="w-8 h-full flex items-center justify-center hover:(bg-grayWhite text-oslo-100)"
            type="button"
            onClick={() => onChange(current - 1, current, pageSize)}
          >
            <GoLeftSVG width={16} height={16} />
          </button>
        )}
        {renderItems.map(({ key, position, isPortal }) => (
          <button
            type="button"
            className={cx(
              'flex items-center justify-center w-8 h-full rounded-sm hover:(bg-grayWhite text-oslo-100)',
              position === current ? 'bg-grayWhite text-oslo-100' : 'bg-white text-primary',
            )}
            key={key}
            onClick={() => onChange(position, current, pageSize)}
          >
            {isPortal ? '...' : position}
          </button>
        ))}
        {current !== pagesCount && (
          <button
            className="w-8 h-full flex items-center justify-center hover:(bg-grayWhite text-oslo-100)"
            type="button"
            onClick={() => onChange(current + 1, current, pageSize)}
          >
            <GoRightSVG width={16} height={16} />
          </button>
        )}
      </div>
    </div>
  );
};
