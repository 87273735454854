import { useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useDeleteEmployeeProfile } from '../entities/EmployeeProfileEntity/controllers';
import { employeeInfoQuery, employeeProfileKeys } from '../entities/EmployeeProfileEntity/queries';
import { isProfileDeletedStore, selectedEmployeeIDProfileStore } from '../entities/EmployeeProfileEntity/states';
import { userProfileQuery } from '../entities/ProfileEntity/queries';
import BackSVG from '../shared/icons/back.svg?->TSX';
import { ApplicationListWidget } from '../widgets/ApplicationsListWidget';

export const EmployeeProfile: React.FC = () => {
  const { id: employeeID } = useParams();

  if (!employeeID) {
    throw new Error('Unexpected employeeID');
  }

  const navigate = useNavigate();

  const { lastNameWithInitials, state, employee, rawInfo, lastUpdate } = useRecoilValue(employeeInfoQuery(employeeID));
  const refresh = useRecoilRefresher_UNSTABLE(employeeInfoQuery(employeeID));

  const { whatICanDoWith } = useRecoilValue(userProfileQuery);

  const allowedActions = useMemo(() => whatICanDoWith(rawInfo.role), [whatICanDoWith]);

  const setCurrentEmployeeProfileID = useSetRecoilState(selectedEmployeeIDProfileStore);
  const resetCurrentEmployeeProfileID = useResetRecoilState(selectedEmployeeIDProfileStore);
  const isDeletedEmployee = useRecoilValue(isProfileDeletedStore);
  const resetIsDeletedEmployee = useResetRecoilState(isProfileDeletedStore);

  const deleteEmployeeProfile = useDeleteEmployeeProfile();

  useEffect(() => {
    if (isDeletedEmployee) {
      navigate('/employees');
    }

    return resetIsDeletedEmployee;
  }, [isDeletedEmployee, resetIsDeletedEmployee]);

  useEffect(() => {
    setCurrentEmployeeProfileID(employeeID);

    return resetCurrentEmployeeProfileID;
  }, [employeeID, setCurrentEmployeeProfileID]);

  useEffect(() => {
    if (Date.now() - lastUpdate > 500) {
      refresh();
    }
  }, []);

  /*FIXME: Understand why i need 2px margin here */
  return (
    <>
      <div className="pt-6 overflow-auto px-12 max-h-full h-full flex mb-4 flex-col">
        <div className="mb-2 flex min-h-9 items-center">
          <Link className="flex h-full items-center space-x-2 text-primary" to="/employees">
            <BackSVG className="mb-0.5" width={20} height={20} />
            <span data-cypress="all-employees" className="font-500 text-m">
              Все сотрудники
            </span>
          </Link>
          {allowedActions.delete && (
            <button
              className="w-max text-primary text-m rounded px-5 h-full font-500 ml-auto border-2 border-seattle-30 flex items-center hover:border-primary"
              type="button"
              onClick={deleteEmployeeProfile}
            >
              Удалить
            </button>
          )}
        </div>
        <div className="flex items-center space-x-4">
          <h1 className="*h1">{lastNameWithInitials}</h1>
          {state === 'Активен' && <span className="*badge-l bg-miami-30">{state}</span>}
          {state === 'На подтверждении' && <span className="*badge-l bg-dubai-30">{state}</span>}
          {state === 'Неактивен' && <span className="*badge-l bg-seattle-10">{state}</span>}
        </div>
        <div className="flex flex-col bg-white shadow mt-6 rounded p-6">
          <div className="flex flex-col space-y-6">
            <table className="overflow-auto">
              <thead className="block text-s text-seattle-100">
                <tr className="grid grid-cols-test2 items-center">
                  {Object.entries(employeeProfileKeys).map(([key, value]) => (
                    <th className="flex font-400" key={key}>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="block text-m">
                {employee.map(({ id, AccessLevel, Email, FullName, Phone }) => (
                  <tr className="grid grid-cols-test2 mt-1 items-center" key={id}>
                    <td className="flex">{FullName}</td>
                    <td className="flex">{Phone}</td>
                    <td className="flex">{Email}</td>
                    <td className="flex">{AccessLevel}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {allowedActions.edit && (
              <button
                className="w-max h-10 text-primary text-m rounded px-5 font-500 border-2 border-seattle-30 flex items-center hover:border-primary"
                type="button"
                onClick={() => navigate(`/employees/edit/${employeeID}`)}
              >
                Редактировать
              </button>
            )}
          </div>
        </div>

        <ApplicationListWidget />
      </div>
    </>
  );
};
